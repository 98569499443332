import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { colors } from '../../../config';
import { Divider, ListItemIcon, Grid } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../services/store/hooks';
import { logoutUser, selectAuth } from '../../../services/store/auth/auth.slice';
import { useNavigate } from 'react-router-dom';
import { CreateFileModal } from '../../modal';
import Loading from '../../loading';
import SnackbarAlert, { PropsSnack } from '../../snackbarAlert';
import { Btn } from '../../button';
import jwtDecode from 'jwt-decode';
import MenuIcon from '@mui/icons-material/Menu';

interface Props {
  toggleDrawer: () => void
}

function Header(props: Props) {
  const { toggleDrawer } = props;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const drawerWidth = 240;
  const Auth = useAppSelector(selectAuth);
  const user: any = jwtDecode(Auth.access_token)
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    let isMuted = true
    if (Auth?.isAuthenticated === false) {
      Navigate('/');
    } else {
      if (isMuted) {
        console.log(Auth);
      }
    }
    return () => {
      isMuted = false
    }
  }, []);

  const onLogout = () => {
    try {
      dispatch(logoutUser())
      Navigate('/');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <AppBar
      position="static"
      color='transparent'
      sx={{
        marginLeft: { md: 40, xs: 0 },
        marginRight: {md: 2},
        // marginTop: {md: 1},
        width: {
          md: "auto",
          boxShadow: 'none',
          xs: '100%'
        },
        background: colors.blueApp,
        borderRadius: 2
      }}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={3} md={0} sm={3} display={{md: "none",sm:'flex', xs:'flex'}} justifyContent={'left'}>
            <Box sx={{display: { xs: 'flex', md: 'none' } }}>
               <IconButton
                 size="large"
                 aria-label="account of current user"
                 aria-controls="menu-appbar"
                 aria-haspopup="true"
                 onClick={() => toggleDrawer()}
               >
                 <MenuIcon htmlColor={'#fff'} />
               </IconButton>
            </Box>
          </Grid>
          <Grid item xs={9} md={12} display={'flex'} justifyContent={"right"}>
            <Toolbar>
              <Box sx={{ display: 'flex', alignItems:"center", justifyContent:"right" }}>

                <Tooltip title="Open settings">
                  <>
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{
                        p: 0,
                        // background: colors.white,
                        fontSize: "10px",
                        textDecoration: "none",
                        color: colors.black,
                        mr: 1,
                        ml: 1
                      }}
                    >
                      <Avatar alt="Img Profil" src="/images/avatars/9.jpg" />
                      <Typography sx={{ color: colors.griseApp, pt: 1, pr: 1, fontSize: '16px' }}>
                        <b> {user?.nom} {user?.prenom}</b>
                      </Typography>
                    </IconButton>
                  </>
                </Tooltip>
                <Menu
                  anchorEl={anchorElUser}
                  id="account-menu"
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  onClick={handleCloseUserMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 14,
                        width: 10,
                        height: 10,
                        background: '#fff',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                      background: '#fff',
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={() => Navigate("/ProfilChurcheView")}>
                    <Avatar /> Profil
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => onLogout()}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Déconnexion
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Grid>
        </Grid>
        <CreateFileModal open={openModal} handleClose={toggleModal} />
        <Loading open={loading} />
        <SnackbarAlert
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          type={snackState.type}
          message={snackState.message}
        />
      </Container>
    </AppBar>
  );
}
export default Header;
