import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DescriptionIcon from '@mui/icons-material/Description';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { colors, PrivilegesEnum } from '../../../config';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { storeRoutes, StoreRoutesType } from '../../../router/store.routes';
import { useAppSelector } from '../../../services/store/hooks';
import { selectAuth } from '../../../services/store/auth/auth.slice';
import jwtDecode from 'jwt-decode';

const drawerWidth = 300;
interface Props {
  window?: () => Window;
  mobileOpen: boolean,
  handleDrawerToggle: () => void
}

const MDrawer = (props: Props) => {

  const { window, mobileOpen, handleDrawerToggle } = props;
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  const Auth = useAppSelector(selectAuth);
  const user: any = jwtDecode(Auth.access_token)
  const [open, setOpen] = React.useState("");
  const [focus, setFocus] = React.useState("");
  const Navigation = useNavigate();
  const location = useLocation();

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  const handleClick = (module: string) => {
    setOpen(module === open ? "" : module);
  };

  const handelActivetedItemInDrawer = React.useCallback(() => {
    const { state } = location;
    if (state) {
      setOpen(state.drawer ? state.drawer : "");
      setFocus(state.currentItem ? state.currentItem : "");
    }
  }, [location]);

  React.useEffect(() => {
    let isMount = true;
    if (isMount) {
      handelActivetedItemInDrawer()

    }
    return () => {
      isMount = false;
    }
  }, [handelActivetedItemInDrawer])

  const drawer = (
    <div>
      <Toolbar>
        <img src="./ecclessia.png" alt="logo ecclessiabook" style={{ width: 50, height: 50, borderRadius: '50px' }} />
        <Typography variant='h6' style={{ color: colors.white, marginLeft: 5 }}>
          EcclesiaBook
        </Typography>
      </Toolbar>
      <Divider />
      <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
        {storeRoutes.map((item: StoreRoutesType, i: number) => {
          if (user?.privilege_user === PrivilegesEnum.ADMIN) {
            if (item.drawer && item.admin) {
              return (
                <div key={`${Math.round(Math.random() * 1000) + i}${item.namePath}`}>
                  <ListItemButton onClick={() => item.submodule ? handleClick(item.namePath) : Navigation(item.path, { state: { drawer: item.namePath, currentItem: item.namePath } })}
                    sx={{
                      background: focus === item.namePath ? colors.griseApp2 : '',
                      '&:hover': {
                        background: colors.griseApp,
                        "& $ListItemText": {
                          color: "black"
                        }
                      },
                    }} key={`${Math.round(Math.random() * 1000) + i}${item.namePath}`}>
                    <ListItemIcon sx={{ color: colors.white }}>
                      {item?.Icon ? <item.Icon /> : <DescriptionIcon />}
                    </ListItemIcon>
                    <ListItemText sx={{ color: colors.white }} primary={item.drawerLabel} />
                    {item.submodule ? open === item.namePath ? <ExpandLess sx={{ color: colors.white }} /> : <ExpandMore sx={{ color: colors.white }} /> : null}
                  </ListItemButton>
                  {item.submodule ?
                    <Collapse in={open === item.namePath ? true : false} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.submodule.map(({ path, namePath, drawer, drawerLabel, Icon }: StoreRoutesType, e: number) => {
                          if (drawer) {
                            return (
                              <ListItemButton sx={{
                                pl: 5,
                                background: focus === namePath ? colors.griseApp2 : '',
                                '&:hover': {
                                  background: colors.griseApp,
                                  "& $ListItemText": {
                                    color: colors.move
                                  }
                                },
                              }} onClick={() => Navigation(path, {
                                state: {
                                  drawer: item.namePath,
                                  currentItem: namePath
                                }
                              })} key={`${Math.round(Math.random() * 1000) + e}${namePath}`} >
                                <ListItemIcon sx={{ color: colors.white }}>
                                  {Icon ? <Icon /> : <RadioButtonCheckedIcon />}
                                </ListItemIcon>
                                <ListItemText sx={{ color: colors.white, fontSize: "8px" }} primary={drawerLabel} />
                              </ListItemButton>
                            )
                          } else {
                            return (<div key={`${Math.round(Math.random() * 1000) + i}${namePath}`}></div>)
                          }
                        })}
                      </List>
                    </Collapse>
                    : null}
                </div>
              )
            } else {
              return (<div key={`${Math.round(Math.random() * 1000) + i}${item.namePath}`}></div>)
            }
          } else {
            if (item.drawer && (item.admin === false || item.admin === undefined) ) {
              return (
                <div key={`${Math.round(Math.random() * 1000) + i}${item.namePath}`}>
                  <ListItemButton onClick={() => item.submodule ? handleClick(item.namePath) : Navigation(item.path, { state: { drawer: item.namePath, currentItem: item.namePath } })}
                    sx={{
                      background: focus === item.namePath ? colors.griseApp2 : '',
                      '&:hover': {
                        background: colors.griseApp,
                        "& $ListItemText": {
                          color: "black"
                        }
                      },
                    }} key={`${Math.round(Math.random() * 1000) + i}${item.namePath}`}>
                    <ListItemIcon sx={{ color: colors.white }}>
                      {item?.Icon ? <item.Icon /> : <DescriptionIcon />}
                    </ListItemIcon>
                    <ListItemText sx={{ color: colors.white }} primary={item.drawerLabel} />
                    {item.submodule ? open === item.namePath ? <ExpandLess sx={{ color: colors.white }} /> : <ExpandMore sx={{ color: colors.white }} /> : null}
                  </ListItemButton>
                  {item.submodule ?
                    <Collapse in={open === item.namePath ? true : false} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.submodule.map(({ path, namePath, drawer, drawerLabel, Icon }: StoreRoutesType, e: number) => {
                          if (drawer) {
                            return (
                              <ListItemButton sx={{
                                pl: 5,
                                background: focus === namePath ? colors.griseApp2 : '',
                                '&:hover': {
                                  background: colors.griseApp,
                                  "& $ListItemText": {
                                    color: colors.move
                                  }
                                },
                              }} onClick={() => Navigation(path, {
                                state: {
                                  drawer: item.namePath,
                                  currentItem: namePath
                                }
                              })} key={`${Math.round(Math.random() * 1000) + e}${namePath}`} >
                                <ListItemIcon sx={{ color: colors.white }}>
                                  {Icon ? <Icon /> : <RadioButtonCheckedIcon />}
                                </ListItemIcon>
                                <ListItemText sx={{ color: colors.white, fontSize: "8px" }} primary={drawerLabel} />
                              </ListItemButton>
                            )
                          } else {
                            return (<div key={`${Math.round(Math.random() * 1000) + i}${namePath}`}></div>)
                          }
                        })}
                      </List>
                    </Collapse>
                    : null}
                </div>
              )
            } else {
              return (<div key={`${Math.round(Math.random() * 1000) + i}${item.namePath}`}></div>)
            }
          }
        })}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, 
             background: colors.blueApp 
            },

          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, 
            background: colors.blueApp 
          },

          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />

      </Box>
    </Box>
  );
}

export default MDrawer;
