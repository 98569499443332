import { useCallback, useEffect, useState } from "react";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import Layout from "../../components/layout";
import DataTable from "../../components/table/data.table";
import { BloquerEglise, DeleteEglise, findAllEglisse } from "../../services/api/adminApi";
import Loading from "../../components/loading";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import moment from "moment";
import { Btn } from "../../components/button";
import { colors, PrivilegesEnum } from "../../config";
import { url } from "../../services/api";
import { CreateChurchesModal, UpdateChurcheModal } from "../../components/modal/adminModal";
import AlertDialogSlide from "../../components/AlertDialogSlide";
import { useNavigate, Link } from "react-router-dom";

const columns = [
  {
    name: "n",
    label: "#",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "nom_eglise",
    label: "Nom",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "sigle_eglise",
    label: "Sigle",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "photo_eglise",
    label: "Logo",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "action",
    label: "Action",
    options: {
      sort: true,
    }
  },

]

const AdminChurcheView = () => {
  const [churches, setChurches] = useState<any[]>([]);
  const [churchesOriginal, setChurchesOriginal] = useState<any[]>([]);
  const [alertState, setAlertState] = useState({ open: false, msg: <></> });
  const [loading, setLoading] = useState<boolean>(false);
  const [snackState, setSnackState] = useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModal1, setOpenModal1] = useState<boolean>(false);

  const [idEglise, setIdEglise] = useState<number>(0)
  const [nomEglise, setNomEglise] = useState<string>("");
  const [sigle, setSigle] = useState<string>("");
  const [photo, setPhoto] = useState<any>("");
  const [image, setImage] = useState<string>("./ecclessia.png");
  const [photoCouverture, setPhotoCouverture] = useState<string>("");
  const [couverture, setCouverture] = useState<string>("./ecclessia.png");
  const [adresse, setAdresse] = useState<string>("");
  const [ville, setVille] = useState<string>("");
  const [pays, setPays] = useState<string>("");

  const [idAdmin, setIdAdmin] = useState<number>(0);
  const [nomAdmin, setNomAdmin] = useState<string>("");
  const [prenom, setPrenom] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telephone, setTel] = useState<string>("");
  const [password, setPwd] = useState<string>("");
  const [villeAdmin, setVilleAdmin] = useState<string>("");
  const [adresseAdmin, setAdresseAdmin] = useState<string>("");
  const [paysAdmin, setPaysAdmin] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [typeAction, setTypeAction] = useState<string>("bloque");
  const [blocked, setBlocked] = useState<string>("blocked");

  const Navigation = useNavigate();
  const handleOpenStack = useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleOpenAlert = (type: string = "") => {
    setTypeAction(type);
    if (type === "delete") {
      setAlertState({
        open: true,
        msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer l'eglise?</Typography>
      })
    } else {
      setAlertState({
        open: true,
        msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment {type === "blocked" ? "debloquer" : " bloquer"} l'église?</Typography>
      });

      setBlocked(type === "blocked" ? "no-blocked" : "blocked")
    }
  };

  const handleCloseAlert = () => {

    setAlertState({
      open: false,
      msg: <></>
    });
  };

  const handleConfirmAction = () => {
    if (typeAction === "delete") {
      handleDelete()
    } else {
      handleBloque()
    }
    handleCloseAlert()
  }

  const getChurche = async () => {
    setLoading(true);
    const getAll = await findAllEglisse();
    if (getAll?.status === 200) {
      let newData: any[] = [];
      getAll?.data?.map(async (item: any, i: number) => {
        const admin = item?.fideles.find((element: any) => element?.privilege === PrivilegesEnum.ADMIN_EGLISE);
        newData.push({
          id: item?.id_eglise,
          n: i + 1,
          nom_eglise: item?.nom_eglise,
          sigle_eglise: item?.sigle_eglise,
          photo_eglise: <Avatar src={`${url}files/photo/${item?.photo_eglise}`} />,
          adresse_eglise: item?.adresse_eglise,
          ville_eglise: item?.ville_eglise,
          pays_eglise: item?.pays_eglise,
          createdAt: moment(item?.createdAt).calendar(),
          action: <Box display={"flex"} justifyContent={"space-between"}>
            <Link style={{
              textDecoration: 'none',
              all: "unset",
              cursor: 'pointer',
              alignItems: "center",
              fontSize: 15,
              fontWeight: 'bold',
              color: colors.white,
              background: colors.primary,
              borderRadius: 5,
              width: "70px",
              display: 'flex',
              justifyContent:'center',
              textAlign:'center',

            }} to={"/ProfilChurcheView"} state={{
              eglise: {
                id_eglise: item?.id_eglise,
                nom_eglise: item?.nom_eglise,
                sigle_eglise: item?.sigle_eglise,
                photo_eglise: item?.photo_eglise,
                couverture_eglise: item?.couverture_eglise,
                adresse_eglise: item?.adresse_eglise,
                ville_eglise: item?.ville_eglise,
                pays_eglise: item?.pays_eglise,
                admin,
              }
            }}>Voir</Link>
            <Btn action={() => {
              setIdEglise(item?.id_eglise)
              setNomEglise(item?.nom_eglise);
              setSigle(item?.sigle_eglise);
              setPhoto("");
              { item?.photo_eglise ? setImage(`${url}files/photo/${item?.photo_eglise}`) : setImage(`./ecclessia.png`) }
              { item?.couverture_eglise ? setCouverture(`${url}files/photo/${item?.couverture_eglise}`) : setCouverture(`./ecclessia.png`) }
              setPhotoCouverture("");

              setAdresse(item?.adresse_eglise);
              setVille(item?.ville_eglise);
              setPays(item?.pays_eglise);
              if (admin) {
                setIdAdmin(admin?.id)
                setNomAdmin(admin?.nom)
                setPrenom(admin?.prenom)
                setEmail(admin?.email)
                setTel(admin?.telephone)
                setVilleAdmin(admin?.ville);
                setAdresseAdmin(admin?.adresse);
                setPaysAdmin(admin?.pays);
              } else {
                setIdAdmin(0)
                setNomAdmin("")
                setPrenom("")
                setEmail("")
                setTel("")
                setVilleAdmin("");
                setAdresseAdmin("");
                setPaysAdmin("");
              }
              setOpenModal1(true);
            }} width={"auto"} color={colors.blueApp}><b>Modifier</b></Btn>
            <Btn action={() => {
              setIdEglise(item?.id_eglise)
              handleOpenAlert(item?.status_eglise)

            }} width={"auto"} color={colors.griseApp2}><b>{item?.status_eglise === "blocked" ? "Debloquer" : "Bloquer"}</b></Btn>
            <Btn action={() => { setIdEglise(item?.id_eglise); handleOpenAlert("delete") }} width={"auto"} color={colors.redApp}><b>Supprimer</b></Btn>
          </Box>
        });
      });
      setChurchesOriginal(getAll?.data)
      setChurches(newData)
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
    setLoading(false);
  }

  const handleBloque = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("status_eglise", blocked);
    const bloquer = await BloquerEglise(idEglise, formData)
    setLoading(false);
    if (bloquer?.status === 200) {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `L'église est bloquer!`
      });
      await getChurche()
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
  }

  const handleDelete = async () => {
    setLoading(true);
    const bloquer = await DeleteEglise(idEglise)
    setLoading(false);
    if (bloquer?.status === 200) {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `L'église est supprimer!`
      });
      await getChurche()
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
  }

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      getChurche();
    }
    return () => {
      isMount = false;
    }
  }, []);

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Btn action={() => { setOpenModal(true) }} width={"auto"} color={colors.primary}>Ajouter une église</Btn>
        </Grid>
        <Grid item md={12}>
          <DataTable title={"Eglises"} data={churches} columns={columns} />
        </Grid>
        <Loading open={loading} />
        <SnackbarAlert
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          type={snackState.type}
          message={snackState.message}
        />
        <CreateChurchesModal open={openModal} handleClose={() => { setOpenModal(false) }} handlCallbackReload={getChurche} />
        <UpdateChurcheModal
          open={openModal1}
          handleClose={() => { setOpenModal1(false) }}
          handlCallbackReload={getChurche}
          data={{
            idEglise, setIdEglise,
            nomEglise, setNomEglise,
            sigle, setSigle,
            photo, setPhoto,
            image, setImage,
            adresse, setAdresse,
            ville, setVille,
            pays, setPays,
            photoCouverture, setPhotoCouverture,
            couverture, setCouverture,

            idAdmin, setIdAdmin,
            nomAdmin, setNomAdmin,
            prenom, setPrenom,
            email, setEmail,
            telephone, setTel,
            villeAdmin, setVilleAdmin,
            adresseAdmin, setAdresseAdmin,
            paysAdmin, setPaysAdmin,
            password, setPwd,
            confirmPassword, setConfirmPassword
          }}
        />
        <AlertDialogSlide
          open={alertState.open}
          Message={() => alertState.msg}
          handleClose={handleCloseAlert}
          handleConfirmAction={() => handleConfirmAction()}
        />
      </Grid>
    </Layout>
  )
}

export default AdminChurcheView;