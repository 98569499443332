import { Grid, Typography } from "@mui/material"

const Notice = () => {

    return <Grid item container spacing={2}>
    <Grid item md={12}>
      <Typography variant="subtitle1" fontWeight={"bold"} >EcclesiaBook est un réseau social évangélique qui a pour but de parler de Jésus-Christ a toute le monde. De ce fait voici ce en qui nous croyons :</Typography>
      <Typography mb={2} >- À l'Écriture Sainte, telle qu'elle a été donnée par Dieu à son origine, divinement inspirée, infaillible, entièrement digne de confiance, autorité souveraine en matière de foi et de vie ;</Typography>
      <Typography mb={2} >- En un seul Dieu manifesté en trois personnes de toute éternité, Père, Fils, et Saint-Esprit ;</Typography>
      <Typography mb={2} >- En Jésus-Christ, notre Seigneur, Dieu fait homme, né de la vierge Marie, à son humanité exempte de péché, ses miracles divins, sa mort expiatoire et substitutive, sa résurrection corporelle, son ascension, son œuvre médiatrice et son retour personnel dans la puissance et la gloire ;</Typography>
      <Typography mb={2} >- Au salut de l'homme perdu et pécheur, grâce au sang versé par Jésus-Christ notre Seigneur. Ce salut est obtenu non par les œuvres, mais seulement par la foi. À la régénération par le Saint-Esprit ;</Typography>
      <Typography mb={2} >- Au Saint-Esprit, qui habite le croyant, le rendant capable de vivre dans la sainteté, de témoigner et de travailler pour Jésus-Christ. Nous croyons que le baptême dans l'Esprit-Saint est une promesse pour les chrétiens de tous les siècles ; il est donné par le Père et le Fils, et il est manifesté par le parler en langues comme au jour de la Pentecôte, selon le récit du Nouveau Testament. Nous croyons aux dons spirituels (ou charismes) cités dans les Écritures, et que le Saint-Esprit accorde à l'Église, pour secourir et construire sa piété ;</Typography>
      <Typography mb={2} >- À l'unité de l'esprit de tous les croyants, l'Église, le corps de Christ. Nous croyons que l'église locale est l'expression visible de l'Église universelle qui est le corps de Christ, et qui ne peut être délimitée par les dénominations religieuses de la chrétienté. Nous croyons que la prière pour la guérison des malades est une mission de l'Église qui est associée à la prédication de l'évangile. Nous croyons que c'est la volonté de Dieu de sauver, guérir et délivrer tous les hommes ;</Typography>
      <Typography mb={2} >- À la résurrection des sauvés et des perdus ; les sauvés pour la vie éternelle, les perdus pour la damnation éternelle.</Typography>
    </Grid>
  </Grid>
}

export default Notice