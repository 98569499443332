import * as React from 'react';
import { Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../config';

interface Props {
  link: string
}

interface BtnProps {
  children: any,
  action: () => any,
  type?: "button"| "submit" | "reset",
  color: string,
  width?: string,
  margin?: string,
}

export const GoBackBtn = ({ link }: Props) => {
  const Navigate = useNavigate()

  return <IconButton onClick={() => Navigate(`/${link}`)}>
    <ArrowBackIcon fontSize="large" />
  </IconButton>
}

export const Btn = (props: BtnProps) => {
  return <Button
    type={props.type}
    onClick={() =>{props.action()}}
    style={{
      background: props.color,
      color: colors.white,
      // borderRadius: "68px",
      width: props.width ? props?.width : "200px",
      margin: props.margin ? props?.margin : 0,
      textTransform: "none"
    }}
  >{props.children}</Button>

}