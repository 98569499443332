import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { colors } from '../../config';
import moment from 'moment';

interface Props {
  commentaires: any[]
}

export default function CommentList(props: Props) {
  return (
    <Card>
      <List sx={{
        width: '100%',
        bgcolor: 'background.paper',
        overflow: 'auto',
        height: 385,
      }}>

        {props.commentaires.length > 0 ? props.commentaires?.map((item: any, i: number) => {
          return (<>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt={`${item?.users?.nom} ${item?.users?.id} ${item?.users?.prenom}`} src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={`${item?.users?.nom} ${item?.users?.prenom}`}
                secondary={
                  <React.Fragment>
                    
                    {item?.commentaire}
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {moment(item?.createdAt).fromNow()}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </>)
        }) : <Typography color={colors.griseApp2} textAlign={"center"} justifySelf={"center"} alignSelf={"center"}>Pas de commentaire pour le moment!</Typography>}
      </List>
    </Card>
  );
}