import React, { useState } from 'react';
import { Box, Checkbox, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { colors } from '../../config';
import { Btn } from '../../components/button';
import SnackbarAlert, { PropsSnack } from '../../components/snackbarAlert';
import Loading from '../../components/loading';
import { InputApp } from '../../components/input';
import { useNavigate } from 'react-router-dom';
import { createAdmin } from '../../services/api';
import { useAppDispatch } from '../../services/store/hooks';
import { loginUser } from '../../services/store/auth/auth.slice';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Notice from '../../components/notice';
import AlertDialogSlide from '../../components/AlertDialogSlide';


const AuthCreateAdminView = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch()
  const [nom, setNom] = useState<string>("");
  const [prenom, setPrenom] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telephone, setTel] = useState<string>("");
  const [password, setPwd] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [checked, setChecked] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfPassword, setShowConfPassword] = React.useState(false);
  const [alertState, setAlertState] = React.useState({ open: false, msg: <></> });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfPassword = () => setShowConfPassword((value) => !value);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 10000)
  };

  const handleOpenApprouved = () => {
    setAlertState({
      open: true,
      msg: <Notice />,
    });
  };


  const handleClose = () => {
    setAlertState({
      open: false,
      msg: <></>
    })
  };
  const handleConfirmAction = async () => {
    handleClose();
    await handleSubmit();
  }
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  }

  const handleSubmit = async () => {
    if (nom !== "" && prenom !== "" && email !== "" && telephone !== "" && password !== "") {
      if(checked){
        if (password === confirmPassword) {
          setLoading(true);
          const create = await createAdmin({
            nom,
            prenom,
            telephone,
            email,
            password,
            privilege: "administrateur_eglise"
          });
          setLoading(false);
          console.log(create);
          if (create?.status === 200 && create?.statusText === "OK") {
            dispatch(loginUser({
              access_token: create.data?.access_token,
              refresh_token: create.data?.refresh_token,
              isAuthenticated: true
            }));
            handleOpenStack({
              open: true,
              vertical: 'top',
              horizontal: 'center',
              type: 'success',
              message: `l'enregistrement se bien passé!`
            });
            navigation('/AuthCreateChurchesView')
  
          } else {
            handleOpenStack({
              open: true,
              vertical: 'top',
              horizontal: 'center',
              type: 'error',
              message: `${create?.data.message}`
            })
          }
        } else {
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'error',
            message: 'Les deux mots de passe doivent ếtre indetique!'
          })
        }
      }else{
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: "Veuillez accepter les termes et conditions."
        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Les champs sont obligatoire!'
      });
    }

  }

  return (
    <Grid container style={{ position: 'absolute', top: 0, bottom: 0, height: "100%", }} className='gradient_background'>
      <Grid item md={6} xs={12}>
        <Stack
          sx={{ height: "100%", }}
          alignItems={"center"}
          justifyContent={'center'}
          alignContent={'center'}>
          <Stack
            sx={{ display: { xs: "flex", md: 'none' } }}
            alignItems={"center"}
            justifyContent={'center'}
            alignContent={'center'}>
            <img
              src='./ecclessia.png'
              alt="ecclessia"
              width={80}
            />
            <Typography fontWeight={"bold"} color={colors.white}>EcclesiaBook</Typography>
          </Stack>
          <Typography mt={2} fontWeight={"bold"} variant='h4' textAlign={"center"} color={colors.white}>Enregistrement de l'administrateur</Typography>
          <Grid container rowSpacing={2} mb={2} mt={2}>
            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp2 }
                }}
                fullWidth
                type="text"
                id="nom"
                name="nom"
                label="Nom"
                variant="outlined"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>

            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp2 }
                }}
                fullWidth
                type="text"
                id="prenom"
                name="prenom"
                label="Prénom"
                variant="outlined"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>

            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp2 }
                }}
                fullWidth
                type="email"
                id="email"
                name="email"
                label="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>

            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp2 }
                }}
                fullWidth
                type="tel"
                id="tel"
                name="tel"
                label="Téléphone"
                variant="outlined"
                value={telephone}
                placeholder="ex: +243......"
                onChange={(e) => setTel(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>

            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp2 }
                }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff htmlColor='#ffffff' /> : <Visibility htmlColor='#ffffff'/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                id="pwsd"
                name="pwsd"
                label="Mot de passe"
                variant="outlined"
                value={password}
                onChange={(e) => setPwd(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>

            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp2 }
                }}
                type={showConfPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfPassword ? <VisibilityOff htmlColor='#ffffff' /> : <Visibility htmlColor='#ffffff'/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                id="confpwsd"
                name="confpwsd"
                label="Confirmer le Mot de passe"
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>

            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8} display={"flex"} sx={{justifyContent:"center", alignItems:"center"}}>
              <Checkbox
                checked={checked}
                onChange={handleChangeChecked}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  color:"white",
                }}
                color="primary"
              />
              <Typography color={"#FFFFFF"}>
                J'ai lu et j'accepte <a style={{ color: "#2E75B5", textDecoration: 'none', textTransform: "none", textAlign: "center" }} href='#' onClick={() => { navigation('/HelpView') }}>les termes et condition</a>
              </Typography>
            </Grid>
             <Grid item md={3} xs={2}></Grid>
          </Grid>
          <Btn action={() => handleOpenApprouved()} color={colors.redApp}>Créez votre compte</Btn>
          <Grid mt={2}>
            <Btn action={() => navigation('/AuthConnexionView')} color={colors.griseApp2}>Connectez-vous</Btn>
          </Grid>
          <Box display={"flex"} sx={{ }} position={"relative"} mt={{ xs: 3, md: 1 }}>
            <Grid container item md={12}>
              <Grid item md={12} xs={12}>
                <Typography style={{ color: colors.griseApp2, textTransform: "none", textAlign: "center" }}>
                  <a style={{ color: colors.white, textDecoration: 'none', textTransform: "none", textAlign: "center" }} href='#' onClick={() => { navigation('/HelpView') }}>
                    Mentions légales | Politique de confidentialité | Termes et Conditions d'utilisation.
                  </a>
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography sx={{ textAlign: "center" }} color={colors.white}>Copyright &#169; 2023 Tous droits réservés.</Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>
      <Grid item md={6} display={{ xs: 'none', md: "flex" }}>
        <Stack
          sx={{ height: "100%" }}
          alignItems={"center"}
          justifyContent={'center'}
          alignContent={'center'}>
          <img
            src='./ecclessia.png'
            alt="ecclessia"
            width={200}
          />
          <Typography fontWeight={"bold"} color={colors.white}>EcclesiaBook</Typography>
          <Typography fontWeight={"100"} sx={{ m: 3, textAlign: "center", color: colors.white }}>
            Plateforme d'évangelisation permettant la diffusion de la parole de Dieu sur l'étendue de la terre et une très bonne structure des églises.
          </Typography>
        </Stack>
      </Grid>
      <Loading open={loading} />
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
      <AlertDialogSlide
        open={alertState.open}
        handleClose={handleClose} 
        Message={() => alertState.msg} 
        handleConfirmAction={handleConfirmAction}
      />
    </Grid>
  )
}

export default AuthCreateAdminView;
