import { Box, Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { fileUrl, url } from '../../services/api';

interface Props {
  audios: number,
  allAudios: any[]
}

export const CustomizeAudiosPlayer = (props: Props) => {
  const audioRef = useRef(null);
  console.log(props);

  return (<Box>
    {props.allAudios.length > 0 ? props.allAudios?.map((item: any, i: number) => {
      if (item?.id === props.audios) {
        return (
          <audio key={i} controls>
            <source src={item?.lien !== null ?`${fileUrl}${item?.lien}` : "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"} type="audio/mpeg" />
          </audio>
        )
      }else{
        return null
      }
    }) : <></>}
  </Box>)
}