import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Paper, Grid, Card, CardActionArea, CardContent } from "@mui/material";
import Layout from '../../components/layout';
import { useAppDispatch, useAppSelector } from '../../services/store/hooks';
import { selectAuth } from '../../services/store/auth/auth.slice';
import jwtDecode from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import { fileUrl, url } from "../../services/api";
import { PrivilegesEnum, colors } from "../../config";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import Loading from "../../components/loading";
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CampaignIcon from '@mui/icons-material/Campaign';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { FindAllAnnonce, FindAnnonce, FindMembersByChurche, FindProgramme, FindStatistique } from "../../services/api/churcheApi";
import Skeleton from '@mui/material/Skeleton';
import { CardHome } from "../../components/card/card.home";
import { getFile, getLive } from "../../services/api/fileApi";
import moment from "moment";



const HomeDashBoardView = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const user: any = jwtDecode(auth?.access_token);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [statistique, setStatistique] = useState({
    audios: 0,
    videos: 0,
    livres: 0,
    lives: 0,
    members: 0,
    pragrammes: 0,
    annonces: 0
  })
  const [audios, setAudios] = React.useState([]);
  const [livres, setlivres] = React.useState([]);
  const [videos, setVideos] = React.useState([]);
  const [membres, setMembres] = React.useState([]);
  const [programme, setProgramme] = React.useState<any[]>([]);
  const [lives, setLives] = useState([]);
  const [annonces, setAnnonce] = useState<any[]>([]);
  const [snackState, setSnackState] = useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });

  const getStatisque = async () => {
    setLoading(true);
    const findStatisque = await FindStatistique(user?.eglise?.id_eglise);
    setLoading(false);
    if (findStatisque?.status === 200) {
      setStatistique(findStatisque?.data)
    }
  }

  const findAudios = React.useCallback(async () => {
    const get = await getFile(user?.eglise?.id_eglise, 'audios');
    setLoading(false);
    if (get?.status === 200 && get?.data !== undefined) {
      setAudios(get?.data);
    } 
  }, [user]);
  const findlivres = React.useCallback(async () => {
    setLoading(true);
    const get = await getFile(user?.eglise?.id_eglise, 'livres');
    setLoading(false);
    if (get?.status === 200 && get?.data !== undefined) {
      setlivres(get?.data);
    }
  }, [user]);
  const findVideos = React.useCallback(async () => {
    setLoading(true);
    const get = await getFile(user?.eglise?.id_eglise, 'videos');
    setLoading(false);
    if (get?.status === 200 && get?.data !== undefined) {
      setVideos(get?.data);
    }
  }, [user]);
  const getMembersByChurche = React.useCallback(async () => {
    setLoading(true);
    const get = await FindMembersByChurche(user?.eglise?.id_eglise);
    setLoading(false);
    if (get?.data !== undefined && get?.status === 200) {
      setMembres(get?.data);
    }
  }, []);
  const getProgramme = React.useCallback(async () => {
    setLoading(true);
    const get = await FindProgramme();
    setLoading(false);
    if (get?.data !== undefined && get?.status === 200) {
      let newData: any[] = [];
      get?.data?.map((item: any, i: number) => {
        newData.push({
          id: item?.id,
          n: i + 1,
          titre: item?.titre,
          createdAt: moment(item?.createdAt).calendar(),
          eglise: item?.eglise,
          sousProgramme: item?.sousProgramme
        });
      });
      setProgramme(newData);
    } 
  }, []);
  const findLives = React.useCallback(async () => {
    setLoading(true);
    const get = await getLive(user?.eglise?.id_eglise);
    setLoading(false);
    if (get?.status === 200 && get?.data !== undefined) {
      setLives(get?.data);
    } 
  }, [user]);
  const getAnnonce = useCallback(async () => {
    setLoading(true);
    const get = user?.privilege_user === PrivilegesEnum.ADMIN ? await FindAllAnnonce() : await FindAnnonce(user?.eglise?.id_eglise);
    setLoading(false);
    if (get?.data !== undefined && get?.status === 200) {
      let newData: any[] = [];
      get?.data?.map((item: any, i: number) => {
        newData.push({
          id: item?.id,
          n: i + 1,
          contente: item?.contente,
          createdAt: moment(item?.createdAt).calendar(),
          eglise: item?.eglise,
          nom_eglise: item?.eglise?.nom_eglise

        });
      })
      setAnnonce(newData);
    }
  }, []);


  useEffect(() => {
    let isMount = true;
    if (isMount) {
      getStatisque();
      findAudios();
      findlivres();
      findVideos();
      getMembersByChurche();
      getProgramme();
      getAnnonce();
    }
    return () => {
      isMount = false;
    }
  }, [])

  return <Layout>
    <>
      <Box>
        <Paper
          sx={{
            height: 400,
            background: `url(${fileUrl}${user?.eglise?.couverture_eglise})`,
            backgroundSize: "cover",
            backgroundAttachment: 'fixed',
            backgroundRepeat: "no-repeat",
            maxWidth: "100%"
          }}>
          <Box
            display={"flex"}
            position={"absolute"}
            alignItems={"bottom"}
            sx={{ pl: 5, alignItems: "center", top: 200 }}
          >
            <img
              src={user?.eglise?.photo_eglise ? `${fileUrl}${user?.eglise?.photo_eglise}` : './ecclessia.png'}
              alt="logo ecclessiabook"
              style={{
                width: 150,
                height: 150,
                borderRadius: 100,
                objectFit: "contain"
              }}
            />
            <Grid item md={6} xs={12}>
              <Typography variant="h2" textAlign={"center"} sx={{ p: 1, background: colors.blueAppRgba, ml: 2, borderRadius: 2 }}>
                {user?.eglise?.nom_eglise}
              </Typography>
            </Grid>
          </Box>
        </Paper>
        <Box mt={11}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <CardHome Icon={LibraryMusicIcon} label={statistique.audios.toString()} path="/AudiosScreenView" file={audios} />
            </Grid>
            <Grid item md={3} xs={12}>
              <CardHome Icon={VideoLibraryIcon} label={statistique.videos.toString()} path="/VideosScreenView" file={videos} />
            </Grid>
            <Grid item md={3} xs={12}>
              <CardHome Icon={LibraryBooksIcon} label={statistique.livres.toString()} path="/LivresScreenView" file={livres} />
            </Grid>
            <Grid item md={3} xs={12}>
              <CardHome Icon={LiveTvIcon} label={statistique.lives.toString()} path="/LivesScreenView" file={lives} />
            </Grid>
            <Grid item md={3} xs={12}>
              <CardHome Icon={PeopleAltIcon} label={statistique.members.toString()} path="/Membres" file={membres} />
            </Grid>
            <Grid item md={3} xs={12}>
              <CardHome Icon={CampaignIcon} label={statistique.annonces.toString()} path="/Annonces" file={annonces}/>
            </Grid>
            <Grid item md={3} xs={12}>
              <CardHome Icon={AppRegistrationIcon} label={statistique.pragrammes.toString()} path="/Programmes" file={programme} />
            </Grid>
          </Grid>
        </Box>
        <Loading open={loading} />
        <SnackbarAlert
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          type={snackState.type}
          message={snackState.message}
        />
      </Box>
    </>
  </Layout>
}

export default HomeDashBoardView
