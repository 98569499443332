import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { colors } from '../../config';
import { Btn } from '../button';
import { Typography } from '@mui/material';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface Props {
  open: boolean;
  Message: () => JSX.Element;
  handleClose: () => void;
  handleConfirmAction: () => void
  user: any
}
export default function AlertDialogFormAbonnementSlide(props: Props) {
  const { open, Message, handleClose, handleConfirmAction, user } = props

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{
          borderRadius: '50px'
        }}
      >

        <DialogContent sx={{ p: 5 }}>
          <Message />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
          {/* https://api.maxicashme.com/PayEntryPost */}
          {/* MerchantID 47fb1c1fcf734ae99c3c41cb902e8604 */}
          {/* MerchantPassword 12b3ad17b499462292d064ef310ee178 */}
          <form method="post" action="https://api-testbed.maxicashapp.com/PayEntryPost">
            <input type="hidden" name="PayType" value="MaxiCash" />
            <input type="hidden" name="Amount" value="3499" />
            <input type="hidden" name="Currency" value="USD" />
            <input type="hidden" name="Telephone" value={user?.telephone} />
            <input type="hidden" name="Email" value={user?.email} />
            <input type="hidden" name="MerchantID" value="81a1c6e9175943d19a72250354871790" />
            
            <input type="hidden" name="MerchantPassword" value="d8938074afca416398e5daca220e57d1" />
            <input type="hidden" name="Language" value="fr" />
            <input type="hidden" name="Reference" value={moment().unix()} />
            <input type="hidden" name="notifyurl" value="http://localhost:3000/AuthPayementSuccessView" />
            <input type="hidden" name="accepturl" value="http://localhost:3000/AuthPayementSuccessView" />
            <input type="hidden" name="cancelurl" value="http://localhost:3000/AuthPayementSuccessView" />
            <input type="hidden" name="declineurl" value="http://localhost:3000/AuthPayementSuccessView" />
            <Button
              type="submit"
              size='medium'
              security='error'
              style={{
                background: colors.blueApp, color: colors.white, borderRadius: "68px", width: "200px",
                padding: '10px',
                textTransform: "none",
                marginLeft: 10
              }}
              onClick={handleConfirmAction}>Confirmer</Button>
          </form>
          <Button
            type="button"
            size='medium'
            security='error'
            style={{
              background: "#343537", color: colors.white, borderRadius: "68px", width: "200px",
              padding: '10px',
              textTransform: "none",
              marginLeft: 10
            }}
            onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}