export const colors = {
  blueApp: "#0F172A",
  blueAppRgba: "rgba(217, 217, 217, 0.8)",
  griseApp: "#D9D9D9",
  griseApp2: "#6F6969",
  redApp: "#F10A0A",
  
  white: "#ffffff",
  move: "#2D033B",
  black: "#333333",
  blackOpacity: "rgba(22, 22, 22, 0.60)",
  
  primary: "#007EFE"
};

export enum EnumContractStatus {
  PUBLISHED = "contract_published",
  APPROUVED = "contract_approuved",
  WORKED = "contract_worked",
  NOT_WORKED = "contract_not_worked",
  TIME_SENT = "contract_time_sent",
  TIME_APPROUVED = "contract_time_approuved",
  TIME_AWAITING = "time_waiting",
  TIME_VALIDATED = "time_validated",
}

export enum EnumActionOfUser {
  S = "supprimer",
  B = "bloquer",
  M = "modifier",
  V = "valider",
}

export enum PrivilegesEnum {
  ADMIN = "administrateur",
  FIDELE = "fidele_eglise",
  ADMIN_EGLISE = "administrateur_eglise"
}

export function getSize() {
  const isClient = typeof window === "object";

  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  };
}