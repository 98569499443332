import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "../../components/layout";
import { colors } from "../../config";
import { selectAuth } from "../../services/store/auth/auth.slice";
import { useAppSelector } from "../../services/store/hooks";
import jwtDecode from "jwt-decode";
import { DeleteFile, getLive } from "../../services/api/fileApi";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import Loading from "../../components/loading";
import { url } from "../../services/api";
import { CardDisk } from "../../components/card/card.disk";
import { IframeLive } from "../../components/live";
import { CreateFileModal, UpdateFileModal } from "../../components/modal";
import AlertDialogSlide from "../../components/AlertDialogSlide";
import { Btn } from "../../components/button";
import { Moment } from "moment";

const LiveScreenView = () => {
  const [lives, setLives] = useState([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const auth = useAppSelector(selectAuth);
  const [currentLives, setCurrentLives] = React.useState<any>({});
  const user: any = jwtDecode(auth.access_token)
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [alertState, setAlertState] = useState({ open: false, msg: <></> });
  const [openModalUpdateFIle, setOpenModalUpdateFile] = React.useState<boolean>(false);

  const [dateLive, setDateLive] = React.useState<Moment | null>(null);
  const [iframe, setIframe] = React.useState<string>("");
  const [titre, setTitre] = React.useState<string>("");
  const [photo, setPhoto] = React.useState<any>("");

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const handleOpenStack = React.useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  }

  const handleOpenAlert = () => {
    setAlertState({
      open: true,
      msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer le live?</Typography>
    })
  };

  const handleCloseAlert = () => {
    setAlertState({
      open: false,
      msg: <></>
    });
  };

  const handleConfirmAction = () => {
    handleDelete()
    handleCloseAlert()
  }

  const handleDelete = async () => {
    setLoading(true);
    const deleted = await DeleteFile(currentLives?.id, 'lives')
    setLoading(false);
    if (deleted?.status === 200) {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `L'audios est supprimer du système!`
      });
      await findLives()
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
  }

  const findLives = React.useCallback(async () => {
    setLoading(true);
    const get = await getLive(user?.eglise?.id_eglise);
    setLoading(false);
    if (get?.status === 200 && get?.data !== undefined) {
      setLives(get?.data);
      setCurrentLives(get?.data[0])
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Vous avez aucun live dans la bibliothéque'
      });
    }
  }, [handleOpenStack, user]);

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      findLives();
    }
    return () => {
      isMount = false
    }
  }, []);

  return <Layout>
    <Box>
      <Grid container spacing={2}>
        {currentLives?.lien ? <Grid item md={12}>
          <IframeLive iframe={`${currentLives?.lien}`} />
          <Grid container>
            <Grid item md={10} display={"flex"} alignItems={"center"}>
              <Typography variant="h6" color={colors.blueApp}>{currentLives?.titre}</Typography>
              <Btn action={() => {
                setTitre(currentLives?.titre);
                setDateLive(currentLives?.date);
                setIframe(currentLives?.lien)
                setOpenModalUpdateFile(true)
              }} margin={"2px"} width={"auto"} color={colors.blueApp}>Modifier</Btn>
              <Btn action={() => {handleOpenAlert()}} width={"auto"} color={colors.redApp}>Supprimer</Btn>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </Grid> : null}
        <Grid item md={12}>
         <Btn action={toggleModal} color={colors.blueApp}>Ajouter votre live</Btn>
        </Grid>
        {lives.length > 0 ? lives?.map((item: any, i: number) => {
          return (
            <Grid key={`${i}${item?.auteur} ${item?.titre}`} item md={4} xs={6}>
              <CardDisk action={() => { setCurrentLives(item) }} webp={item?.webp ? `${url}files/webp/${item?.webp}` : "./ecclessia.png"} titre={item?.titre} auteur={""} />
            </Grid>
          )
        }) : <Grid item md={12} xs={12}>
          <Typography variant="h6" textAlign={"center"} color={colors.griseApp2}>Vous avez aucun live dans la bibliothéque</Typography>
        </Grid>
        }
      </Grid>
      <CreateFileModal open={openModal} handleClose={toggleModal} handlCallbackReload={findLives}  />
      <UpdateFileModal open={openModalUpdateFIle} handleClose={() => {setOpenModalUpdateFile(false)}} handlCallbackReload={findLives}
        data={{
          photo, setPhoto,
          file: "",
          setFile: () => {},
          dateLive, setDateLive,
          titre, setTitre,
          auteur: "",
          setAuteur: () => {},
          iframe,
          setIframe,
          acceptFile: "live",
          idFile: currentLives?.id
        }}
      />

      <Loading open={loading} />
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
      <AlertDialogSlide
        open={alertState.open}
        Message={() => alertState.msg}
        handleClose={handleCloseAlert}
        handleConfirmAction={() => handleConfirmAction()}
      />
    </Box>
  </Layout>
}

export default LiveScreenView;
