import React from "react";
import { Box, CardMedia } from "@mui/material";
import { fileUrl, url } from "../../services/api";

interface Props {
  allVideos: any[],
  videos: number
}

export const VideoPlayer = (props: Props) => {
console.log(props);

  return <Box>
    {props.allVideos.length > 0 ? props.allVideos?.map((item: any, i: number) => {
      if (item?.id === props.videos) {
        return (
          <video key={i} controls style={{ width: "100%" }}>
            <source src={item?.lien !== null ? `${fileUrl}${item?.lien}` : "./athoms_nadege.mp4"} type="video/mp4" />
          </video>
        )
      } else {
        return <></>
      }
    }) : <></>}
  </Box>
}

export const VideoAnnoncePlayer = (props: Props) => {
  return <Box>
    {props.allVideos.length > 0 ? props.allVideos?.map((item: any, i: number) => {
      if (item?.id === props.videos) {
        const ext = item?.contente.substring(item?.contente?.length - 3)
        if (ext === "mp4") {
          return (
            <video key={i} controls style={{ width: "100%" }}>
              <source src={item?.contente !== null ? `${fileUrl}${item?.contente}` : "./athoms_nadege.mp4"} type="video/mp4" />
            </video>
          )
        } else if (ext === 'jpg' || ext === "peg" || ext === "JPG" || ext === 'PEG' || ext === 'png' || ext === "PNG") {
          return (
            <CardMedia
              key={i}
              component="img"
              alt={`annonce img ${item?.id}`}
              height="300"
              image={item?.contente !== null ? `${fileUrl}${item?.contente}` : "./ecclessia.png"}
            />
          )
        }
      } else {
        return null
      }
    }) : <></>}
  </Box>
}
