import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { StoreRoutesType, storeRoutes } from "./store.routes";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {storeRoutes.map(({ path, Component, submodule }: StoreRoutesType) => {
          if (submodule) {
            return <Route key={Math.round(Math.random() * 1000)}>
              {
                submodule.map((item: StoreRoutesType, i: number) => {
                  return (
                    <Route
                      index={i === 0 ? true : false}
                      path={item.path}
                      element={<item.Component />}
                      key={Math.round(Math.random() * 1000)} />
                  )
                })
              }
            </Route>
          } else {
            return (
              <Route
                path={path}
                element={<Component />}
                key={Math.round(Math.random() * 1000)} />
            )
          }
        }

        )}
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
