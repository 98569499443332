import * as React from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { Alert, AlertColor, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
export interface PropsSnack extends SnackbarOrigin {
  open: boolean,
  message: string,
  type: AlertColor,
  handleClose?: () => void 
}

export default function SnackbarAlert(props: PropsSnack) {
  const { vertical, horizontal, open, message ,type, handleClose } = props;
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        key={vertical + horizontal}
        action={action}
      >
        <Alert severity={type} sx={{ width: '100%' }}>
          {message}
          {action}
        </Alert>
      </Snackbar>
    </div>
  );
}