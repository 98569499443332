import React from "react"
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import createCache from "@emotion/cache";
import { createTheme } from "@mui/material/styles";
import { CacheProvider, ThemeProvider } from "@emotion/react";

const muiCache = createCache({
  key: "mui-datatablesdf",
  prepend: true
});

interface Props {
  title: React.ReactNode,
  columns: MUIDataTableColumnDef[],
  data: (object | number[] | string[])[],
  handleClick?: (item: any) => void
}

const DataTable = ({ title, columns, data, handleClick }: Props) => {

  const options: MUIDataTableOptions = {
    selectableRowsHideCheckboxes: true,
    filterType: 'dropdown',
    onCellClick: (colData: any, cellMeta: { colIndex: number, rowIndex: number, dataIndex: number }) => {
      const membres = data.find((item: any, i: number) => i === cellMeta.rowIndex)
      if (membres) {
        if (handleClick) handleClick(membres)
      }

    }
  };

  const getMuiTheme = () => createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
          },
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              // backgroundColor: '#00AEEF !important'
              cursor: "pointer"

            }
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: '#fff',
          },

        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
          },
        },
      },
    },
  });

  return <CacheProvider value={muiCache}>
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
      </ThemeProvider>
    </CacheProvider>

}

      export default DataTable;
