import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import Layout from "../../components/layout";
import DataTable from "../../components/table/data.table";
import { BloquerEglise, DeleteAdmin, DeleteEglise, findAllAdmin, findAllEglisse } from "../../services/api/adminApi";
import Loading from "../../components/loading";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import moment from "moment";
import { Btn } from "../../components/button";
import { colors, PrivilegesEnum } from "../../config";
import { updateAdmin, url } from "../../services/api";
import { CreateAdminModal, CreateChurchesModal, UpdateAdminModal, UpdateChurcheModal } from "../../components/modal/adminModal";
import AlertDialogSlide from "../../components/AlertDialogSlide";

const columns = [
  {
    name: "n",
    label: "#",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "nom",
    label: "Nom",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "prenom",
    label: "Prenom",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "email",
    label: "Email",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "telephone",
    label: "Téléphone",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "action",
    label: "Action",
    options: {
      sort: true,
    }
  },

]

const AdministrateurView = () => {
  const [administrateurs, setAdministrateurs] = useState<any[]>([])
  const [currentChurches, setCurrentChurches] = useState<any>({});
  const [alertState, setAlertState] = useState({ open: false, msg: <></> });
  const [loading, setLoading] = useState<boolean>(false);
  const [snackState, setSnackState] = useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModal1, setOpenModal1] = useState<boolean>(false);

  const [idAdmin, setIdAdmin] = useState<number>(0);
  const [nomAdmin, setNomAdmin] = useState<string>("");
  const [prenom, setPrenom] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telephone, setTel] = useState<string>("");
  const [password, setPwd] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [typeAction, setTypeAction] = useState<string>("bloque")
  const [blocked, setBlocked] = useState<string>("actif")
  
  const handleOpenStack = useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleOpenAlert = (type: string = "") => {
    if (type === "delete") {
      setAlertState({
        open: true,
        msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer l'administrateur?</Typography>
      })
     
    } else {
      setAlertState({
        open: true,
        msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment {type === "actif" ? "bloquer" : " debloquer"} l'administrateur?</Typography>
      });
      setBlocked(type === "actif" ? "inactif" : "actif")
    }
    setTypeAction(type)
  };

  const handleCloseAlert = () => {
    setAlertState({
      open: false,
      msg: <></>
    });
  };

  const handleConfirmAction = () => {
    if (typeAction === "delete") {
      handleDelete()
    } else {
      handleBloque()
    }
    handleCloseAlert()
  }

  const getAdministrateur = async () => {
    setLoading(true);
    const getAll = await findAllAdmin();
    if (getAll?.status === 200) {
      let newData: any[] = [];
      getAll?.data?.map(async (item: any, i: number) => {
        newData.push({
          id: item?.id,
          n: i + 1,
          nom: item?.nom,
          prenom: item?.prenom,
          email: item?.email,
          telephone: item?.telephone,
          adresse: item?.adresse,
          ville: item?.ville,
          pays: item?.pays,
          createdAt: moment(item?.createdAt).calendar(),
          action: <Box display={"flex"} justifyContent={"space-between"} >
            <Btn action={() => {
              setIdAdmin(item?.id)
              setNomAdmin(item?.nom)
              setPrenom(item?.prenom)
              setEmail(item?.email)
              setTel(item?.telephone)
              setOpenModal1(true);
            }} width={"auto"} color={colors.blueApp}><b>Modifier</b></Btn>
            <Btn action={() => {
              setIdAdmin(item?.id)
              handleOpenAlert(item?.status)
            }} width={"auto"} color={colors.griseApp2}><b>{item?.status === "actif" ? "Bloquer" : "Debloquer"}</b></Btn>
            <Btn action={() => { setIdAdmin(item?.id); handleOpenAlert("delete") }} width={"auto"} color={colors.redApp}><b>supprimer</b></Btn>
          </Box>
        });
      });
      setAdministrateurs(newData)
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
    setLoading(false);
  }

  const handleBloque = async () => {
    setLoading(true);
    const bloquer = await updateAdmin({
      status: blocked
    }, idAdmin);
    setLoading(false);
    if (bloquer?.status === 200) {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `L'administrateur est bloquer!`
      });
      await getAdministrateur()
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
  }

  const handleDelete = async () => {
    setLoading(true);
    const deleted = await DeleteAdmin(idAdmin)
    setLoading(false);
    if (deleted?.status === 200) {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `L'administrateur est supprimer du système!`
      });
      await getAdministrateur()
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
  }

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      getAdministrateur();
    }
    return () => {
      isMount = false;
    }
  }, []);

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Btn action={() => { setOpenModal(true) }} width={"auto"} color={colors.blueApp}>Ajouter un admin</Btn>
        </Grid>
        <Grid item md={12}>
          <DataTable handleClick={setCurrentChurches} title={"Administrateurs"} data={administrateurs} columns={columns} />
        </Grid>
        <Loading open={loading} />
        <SnackbarAlert
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          type={snackState.type}
          message={snackState.message}
        />
        <CreateAdminModal open={openModal} handleClose={() => { setOpenModal(false) }} handlCallbackReload={getAdministrateur} />
        <UpdateAdminModal
          open={openModal1}
          handleClose={() => { setOpenModal1(false) }}
          handlCallbackReload={getAdministrateur}
          data={{
            idAdmin, setIdAdmin,
            nomAdmin, setNomAdmin,
            prenom, setPrenom,
            email, setEmail,
            telephone, setTel,
            password, setPwd,
            confirmPassword, setConfirmPassword
          }}
        />
        <AlertDialogSlide
          open={alertState.open}
          Message={() => alertState.msg}
          handleClose={handleCloseAlert}
          handleConfirmAction={() => handleConfirmAction()}
        />
      </Grid>
    </Layout>
  )
}

export default AdministrateurView;