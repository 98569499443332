import * as React from 'react';
import {
  Experimental_CssVarsProvider as CssVarsProvider,
} from '@mui/material/styles';
import Router from './router';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { appPersist, store } from './services/store';
import "./css/index.css"

function App() {
  return <Provider store={store}>
  <PersistGate loading={null} persistor={ appPersist }> 
    <CssVarsProvider>
      <Router/>
    </CssVarsProvider>
  </PersistGate> 
</Provider>
}

export default App;
