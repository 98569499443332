//Store Import
import { url } from ".";
import axios, { AxiosError } from "axios";
import useAxios from "./useAxios"

export async function HttpRequest(
  path: string ,
  method: string,
  data?: any,
  params?: any,
) {
  const api = useAxios()
  console.log( `${url}${path}`);
  
  const request = {
    url:`${path}`,
    method,
    params,
    data
  }      
  return await api(request).then(r => {
    console.log("then", r);
    return r;
  }).catch((error: AxiosError) => {
    console.log('catch', error);
    return error.response
  })
}

export async function AuthHttpRequest(
  path: string ,
  method: string,
  data?: any,
  params?: any,
) {
  const request = {
    url:`${url}${path}`,
    method,
    params,
    data
  }      
  return await axios(request).then(r => {
    console.log("then", r);
    return r;
  }).catch((error: AxiosError) => {
    console.log('catch', error);
    return error.response
  })
}

export async function RequestApi(
  path: string ,
  method: string,
  data?: any,
  params?: any,
) {
  const request = {
    url:`${path}`,
    method,
    params,
    data
  }      
  return await axios(request).then(r => {
    console.log("then", r);
    return r;
  }).catch((error: AxiosError) => {
    console.log('catch', error);
    return error.response
  })
}

