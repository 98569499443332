import react, { useCallback, useEffect, useState } from "react";
import { Grid, Card, CardMedia, CardContent, Typography, CardActions, Button } from "@mui/material";
import Layout from "../../components/layout";
import { Btn } from "../../components/button";
import { colors } from "../../config";
import { selectAuth } from "../../services/store/auth/auth.slice";
import { useAppSelector } from "../../services/store/hooks";
import jwtDecode from "jwt-decode";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import Loading from "../../components/loading";
import { updateAdmin, url } from "../../services/api";
import DataTable from "../../components/table/data.table";
import { FindMembersByChurche } from "../../services/api/churcheApi";
import { CreateUserModale } from "../../components/modal";
import AlertDialogSlide from "../../components/AlertDialogSlide";

const columns = [
  {
    name: "nom",
    label: "Nom",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "prenom",
    label: "Prenom",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "telephone",
    label: "Telephone",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "email",
    label: "Email",
    options: {
      filter: true,
      sort: false,
    }
  },
];

const MembresScreenView = () => {
  const [membres, setMembres] = useState([]);
  const auth = useAppSelector(selectAuth);
  const [currentMembre, setCurrentMembre] = useState<any>({});
  const user: any = jwtDecode(auth.access_token)
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [snackState, setSnackState] = useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [blocked, setBlocked] = useState<string>("actif")
  const [alertState, setAlertState] = useState({ open: false, msg: <></> });

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const handleOpenStack = useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  }

  const handleOpenAlert = (type: string) => {
    setAlertState({
      open: true,
      msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment {type === "actif" ? "bloquer" : " debloquer"} le fidele?</Typography>
    });
    setBlocked(type === "actif" ? "inactif" : "actif")
  };

  const handleCloseAlert = () => {
    setAlertState({
      open: false,
      msg: <></>
    });
  };

  const handleConfirmAction = () => {
    handleBloque()
    handleCloseAlert()
  }
  
  const handleBloque = async () => {
    setLoading(true);
    const bloquer = await updateAdmin({
      status: blocked
    }, currentMembre?.id);
    setLoading(false);
    if (bloquer?.status === 200) {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `L'administrateur est bloquer!`
      });
      await getMembersByChurche()
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
  }

  const getMembersByChurche = useCallback(async () => {
    setLoading(true);
    const get = await FindMembersByChurche(user?.eglise?.id_eglise);
    setLoading(false);
    if (get?.data !== undefined && get?.status === 200) {
      setMembres(get?.data);
      setCurrentMembre(get?.data[0])
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Une erreur est survenue lors de la récupération des membres'
      });
    }
  }, []);

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      getMembersByChurche();
    }
    return () => {
      isMount = false;
    }
  }, [])

  return <Layout>
   <>
   <Grid container spacing={0.5}>
      <Grid item md={12}>
        <Btn action={() => { toggleModal() }} color={colors.white}><Typography color={colors.blueApp}>Ajouter un membre</Typography></Btn>
      </Grid>
      <Grid item md={8}>
        <DataTable handleClick={setCurrentMembre} title={"Membres"} data={membres} columns={columns} />
      </Grid>
      <Grid item md={4}>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image="/7.png"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {currentMembre?.nom} {currentMembre?.prenom}
            </Typography>
            <Typography variant="body2" color="text.secondary">Téléphone: {currentMembre?.telephone}</Typography>
            <Typography variant="body2" color="text.secondary">Adresse: {currentMembre?.adresse}</Typography>
            <Typography variant="body2" color="text.secondary">Ville: {currentMembre?.ville}</Typography>
            <Typography variant="body2" color="text.secondary">Pays: {currentMembre?.pays}</Typography>
          </CardContent>
          <CardActions>
            <Button onClick={() => { handleOpenAlert(currentMembre?.status) }} size="small">{currentMembre?.status === "actif" ? "bloquer" : "debloquer"}</Button>
          </CardActions>
        </Card>
      </Grid>
      <CreateUserModale open={openModal} handleClose={toggleModal} handlCallbackReload={getMembersByChurche} />
      <Loading open={loading} />
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
      <AlertDialogSlide
        open={alertState.open}
        Message={() => alertState.msg}
        handleClose={handleCloseAlert}
        handleConfirmAction={() => handleConfirmAction()}
      />
    </Grid>
   </>
  </Layout>
}

export default MembresScreenView;