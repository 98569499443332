import AuthConnexionView from '../module/auth/auth.connexion.view';
import AuthCreateAdminView from '../module/auth/auth.create.admin.view';
import AuthCreateChurchesView from '../module/auth/auth.create.churches.view';
import HomeDashBoardView from '../module/home/home.dashboard.view';
import VideoScreenView from '../module/videos/video.screen.view';
import MembresScreenView from '../module/membre/membre.screen.view';
import AudiosScreenView from '../module/audios/audios.screen.view';
import LivresScreenView from '../module/pdf/livre.screen.view';
import LiveScreenView from '../module/videos/live.screen.view';

import HomeIcon from '@mui/icons-material/Home';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CampaignIcon from '@mui/icons-material/Campaign';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AnnonceScreenView from '../module/annonce/annonce.screen.view';
import ProgrammeScreenView from '../module/programme/programme.screen.view';
import AppointmentScreenView from '../module/rdv/appointment.screen,view';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AdminChurcheView from '../module/admin/admin.churche.view';
import AdminEmailView from '../module/admin/admin.email.view';
import AdministrateurView from '../module/admin/admin.adminisatrateur.view';
import ProfilChurcheView from '../module/profile/profil.churche.view';
import AuthPayementView from '../module/auth/auth.payement.view';
import AuthPayementSuccessView from '../module/auth/auth.payement.success.view';
import HelpView from '../module/help/help.view';
import CommuniqueScreenView from '../module/communique/CommuniqueScreenView';

export type RolesType = {}

export type StoreRoutesType = {
  namePath: string,
  path: string,
  Component: () => JSX.Element,
  Icon?: any
  drawer?: boolean,
  drawerLabel?: string,
  submodule?: StoreRoutesType[],
  admin?: boolean
};

export const storeRoutes: StoreRoutesType[] = [
  // Module Auth
  {
    'namePath': '/',
    'path': '/',
    'Component': AuthConnexionView
  },
  {
    'namePath': 'AuthConnexionView',
    'path': '/AuthConnexionView',
    'Component': AuthConnexionView
  },
  {
    'namePath': 'AuthCreateAdminView',
    'path': '/AuthCreateAdminView',
    'Component': AuthCreateAdminView
  },
  {
    'namePath': 'AuthCreateChurchesView',
    'path': '/AuthCreateChurchesView',
    'Component': AuthCreateChurchesView
  },
  {
    'namePath': 'AuthPayementView',
    'path': '/AuthPayementView',
    'Component': AuthPayementView
  },
  {
    'namePath': 'AuthPayementSuccessView',
    'path': '/AuthPayementSuccessView',
    'Component': AuthPayementSuccessView
  },
  {
    'namePath': 'HelpView',
    'path': '/HelpView',
    'Component': HelpView
  },

  // Module Home
  {
    namePath: 'HomeDashBoardView',
    path: '/HomeDashBoardView',
    Component: HomeDashBoardView,
    drawer: true,
    drawerLabel: "Accueil",
    Icon: HomeIcon
  },
  // Bibliotheque
  {
    namePath: "Bibliotheque",
    path: "/Bibliotheque",
    Component: VideoScreenView,
    drawer: true,
    drawerLabel: "Bibliothèque",
    Icon: AutoAwesomeMotionIcon,
    submodule: [
      {
        'namePath': 'AudiosScreenView',
        'path': '/AudiosScreenView',
        "drawerLabel": "Audios",
        "drawer": true,
        "Icon": LibraryMusicIcon,
        'Component': AudiosScreenView
      },
      {
        'namePath': 'VideosScreenView',
        'path': '/VideosScreenView',
        "drawerLabel": "Vidéos",
        "drawer": true,
        "Icon": VideoLibraryIcon,
        'Component': VideoScreenView
      },
      {
        'namePath': 'LivresScreenView',
        'path': '/LivresScreenView',
        "drawerLabel": "Livres",
        "drawer": true,
        "Icon": LibraryBooksIcon,
        'Component': LivresScreenView
      },
      {
        'namePath': 'LiveScreenView',
        'path': '/LivesScreenView',
        "drawerLabel": "Lives",
        "drawer": true,
        "Icon": LiveTvIcon,
        'Component': LiveScreenView
      },
    ]
  },
  // Module Profil
  {
    'namePath': 'ProfilChurcheView',
    'path': '/ProfilChurcheView',
    'Component': ProfilChurcheView
  },
  // Membres Module
  {
    namePath: "Membres",
    path: "/Membres",
    Component: MembresScreenView,
    drawer: true,
    drawerLabel: "Membres",
    Icon: PeopleAltIcon,
  },
  // Annonces Module
  {
    namePath: "Annonces",
    path: "/Annonces",
    Component: AnnonceScreenView,
    drawer: true,
    drawerLabel: "Annonces",
    Icon: CampaignIcon,
  },
  // // RDV Module
  {
    namePath: "Rdv",
    path: "/Rdv",
    Component: AppointmentScreenView,
    drawer: true,
    drawerLabel: "Rendez-vous",
    Icon: CalendarMonthIcon,
  },
  {
    namePath: "Communique",
    path: "/Communique",
    Component: CommuniqueScreenView,
    drawer: true,
    drawerLabel: "Communique",
    Icon: NewspaperIcon,
  },
  // Programme Module
  {
    namePath: "Programmes",
    path: "/Programmes",
    Component: ProgrammeScreenView,
    drawer: true,
    drawerLabel: "Programmes",
    Icon: AppRegistrationIcon,
  },

  // Eglise Module Admin
  {
    namePath: "EgliseView",
    path: "/EgliseView",
    Component: AdminChurcheView,
    drawer: true,
    drawerLabel: "Eglise",
    Icon: AccountBalanceIcon,
    admin: true
  },

  //Administrateur Module Admin
  {
    namePath: 'AdministrateurView',
    path: '/AdministrateurView',
    Component: AdministrateurView,
    drawer: true,
    drawerLabel: "Administrateur",
    Icon: HomeIcon,
    admin: true
  },

  //Email Module Admin
  {
    namePath: 'MessagerieView',
    path: '/MessagerieView',
    Component: AdminEmailView,
    drawer: true,
    drawerLabel: "Messagerie",
    Icon: AlternateEmailIcon,
    admin: true

  },
  // Annonces Module
  {
    namePath: "Annonces",
    path: "/Annonces",
    Component: AnnonceScreenView,
    drawer: true,
    drawerLabel: "Annonces",
    Icon: CampaignIcon,
    admin: true
  },


];
