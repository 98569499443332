import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { colors } from '../../config';

export const InputApp = styled(TextField)({
  '& label.Mui-focused': {
    color: colors.primary,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.primary,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: colors.white,
    },
    '&:hover fieldset': {
      borderColor: colors.white,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.primary,
    },
  },
});
