import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { colors, getSize } from '../../config';
import { Btn } from '../../components/button';
import SnackbarAlert, { PropsSnack } from '../../components/snackbarAlert';
import Loading from '../../components/loading';
import { InputApp } from '../../components/input';
import { useNavigate } from 'react-router-dom';
import { CreateChurche } from '../../services/api/churcheApi';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import { useAppDispatch, useAppSelector } from '../../services/store/hooks';
import { loginUser, selectAuth } from '../../services/store/auth/auth.slice';
import jwtDecode from 'jwt-decode';
import { updateAdmin } from '../../services/api';
import Button from '@mui/material/Button';

const AuthCreateChurchesView = () => {
  const screen = getSize();
  const [nom, setNom] = useState<string>("");
  const [sigle, setSigle] = useState<string>("");
  const [photo, setPhoto] = useState<any>("");
  const [image, setImage] = useState<string>("./ecclessia.png");
  const [photoCouverture, setPhotoCouverture] = useState<any>("");
  const [imageCouverture, setImageCouverture] = useState<string>("./ecclessia.png");
  const [adresse, setAdresse] = useState<string>("");
  const [ville, setVille] = useState<string>("");
  const [pays, setPays] = useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleChange = (event: React.ChangeEvent, type = "") => {
    const reader = new FileReader()
    const { files } = event.target as HTMLInputElement;
    if (files && files.length !== 0) {
      reader.onload = () => {
        if (type === "couverture") {
          setImageCouverture(reader.result as string)
        } else {
          setImage(reader.result as string)
        }
      }
      reader.readAsDataURL(files[0]);
      if (type === "couverture") {
        setPhotoCouverture(files[0])
      } else {
        setPhoto(files[0])
      }
    }
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  }

  const handleSubmit = async () => {
    if (nom !== "") {
      const formData = new FormData();
      formData.append('nom_eglise', nom);
      formData.append('photo', photo);
      formData.append('couverture', photoCouverture);
      formData.append('sigle_eglise', sigle);
      formData.append('adresse_eglise', adresse);
      formData.append('ville_eglise', ville);
      formData.append('pays_eglise', pays);
      setLoading(true);
      const createChurche = await CreateChurche(formData);
      console.log(formData);

      if (createChurche?.status === 201 && createChurche?.statusText === "Created") {
        const access_token: any = jwtDecode(auth?.access_token);
        if (access_token?.sub) {
          const update = await updateAdmin({
            fk_eglise: createChurche.data.id_eglise
          }, access_token?.sub);
          if (update?.status === 200 && update?.statusText === "OK") {
            dispatch(loginUser({
              access_token: update.data?.access_token,
              refresh_token: update.data?.refresh_token,
              isAuthenticated: true
            }));
            navigation("/AuthPayementView");
          } else {
            handleOpenStack({
              open: true,
              vertical: 'top',
              horizontal: 'center',
              type: 'error',
              message: `Une erreur est survenue lors de la liaison avec l'église!`
            });
          }
        }
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: `Une erreur est survenue lors du traitement de la requête `
        });
      }
      setLoading(false);
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Les champs sont obligatoires.'
      });
    }

  }

  useEffect(() => {
    let isMount = true
    if (isMount) {
      if (!auth.isAuthenticated) navigation('/AuthConnexionView')
    }
    return () => {
      isMount = false;
    }
  }, [])

  return (
    <Grid container style={{ position: 'absolute', top: 0, bottom: 0, height: "100%", }} className='gradient_background'>
      <Grid item md={6} xs={12}>
        <Stack
          sx={{ height: "100%", }}
          alignItems={"center"}
          justifyContent={'center'}
          alignContent={'center'}>
          <Stack
            sx={{ display: { xs: "flex", md: 'none' } }}
            alignItems={"center"}
            justifyContent={'center'}
            alignContent={'center'}>
            <img
              src='./ecclessia.png'
              alt="ecclessia"
              width={80}
            />
            <Typography fontWeight={"bold"} color={colors.white}>EcclesiaBook</Typography>
          </Stack>
          <Typography fontWeight={"bold"} variant='h3' mt={2} color={colors.white}>Créer votre église</Typography>
          <Grid container rowSpacing={2} mb={3}>
            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.white }
                }}
                fullWidth
                type="text"
                id="nom"
                name="nom"
                label="Nom de l'église"
                variant="outlined"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>

            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp2 }
                }}
                fullWidth
                type="text"
                id="sigle"
                name="sigle"
                label="Sigle de l'église"
                variant="outlined"
                value={sigle}
                onChange={(e) => setSigle(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>

            <Grid item md={3} xs={1}></Grid>
            <Grid item md={6} xs={10} container>
              <Grid item md={6} xs={6} container>
                <Grid item md={12}><Typography color={colors.griseApp2}>logo de l'église</Typography></Grid>
                <Box display={"flex"} justifyContent={"space-betwen"} alignItems={"center"}>
                  <img src={image} alt="churche Image" width={100} height={100} />
                  <IconButton style={{ color: colors.white, background: photo !== "" ? colors.blueApp : colors.griseApp2, marginRight: 15 }} aria-label="upload picture" component="label">
                    <input hidden accept="image/*" type="file" onChange={(e) => handleChange(e)} />
                    <InstallDesktopIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item md={6} xs={6} container>
                <Grid item md={12}><Typography color={colors.griseApp2}> photo de couverture</Typography></Grid>
                <Box display={"flex"} justifyContent={"space-betwen"} alignItems={"center"}>
                  <img src={imageCouverture} alt="churche Image" width={100} height={100} />
                  <IconButton style={{ color: colors.white, background: photoCouverture !== "" ? colors.blueApp : colors.griseApp2, marginRight: 15 }} aria-label="upload picture" component="label">
                    <input hidden accept="image/*" type="file" onChange={(e) => handleChange(e, "couverture")} />
                    <InstallDesktopIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            <Grid item md={3} xs={1}></Grid>


            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp2 }
                }}
                fullWidth
                type="text"
                id="adresse"
                name="adresse"
                label="Adresse de l'église"
                variant="outlined"
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>

            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp2 }
                }}
                fullWidth
                type="text"
                id="ville"
                name="ville"
                label="Ville de l'église"
                variant="outlined"
                value={ville}
                onChange={(e) => setVille(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>

            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp2 }
                }}
                fullWidth
                type="text"
                id="pays"
                name="pays"
                label="pays de l'église"
                variant="outlined"
                value={pays}
                onChange={(e) => setPays(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>
          </Grid>
          <Btn action={() => handleSubmit()} color={colors.redApp}>
          <Typography color={colors.white} width={400}>
            Confirmer votre adhésion
          </Typography>
          </Btn>
          <Btn action={() => navigation('/AuthConnexionView')} color={colors.white} margin={'20px'}>
            <Typography color={colors.blueApp} width={400}>
              Avez-vous déjà un compte?
            </Typography>
          </Btn>
          <Box display={"flex"} sx={{}} position={"relative"} mt={{ xs: 3, md: 1 }}>
            <Grid container item md={12}>
              <Grid item md={12} xs={12}>
                <Typography style={{ color: colors.griseApp2, textTransform: "none", textAlign: "center" }}>
                  <a style={{ color: colors.white, textDecoration: 'none', textTransform: "none", textAlign: "center" }} href='#' onClick={() => { navigation('/HelpView') }}>
                    Mentions légales | Politique de confidentialité | Termes et Conditions d'utilisation.
                  </a>
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography sx={{ textAlign: "center" }} color={colors.white}>Copyright &#169; 2023 Tous droits réservés.</Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>
      <Grid item md={6} display={{ xs: 'none', md: "flex" }}>
        <Stack
          sx={{ height: "100%" }}
          alignItems={"center"}
          justifyContent={'center'}
          alignContent={'center'}>
          <img
            src='./ecclessia.png'
            alt="ecclessia"
            width={200}
          />
          <Typography fontWeight={"bold"} color={colors.white}>EcclesiaBook</Typography>
          <Typography fontWeight={"100"} sx={{ m: 3, textAlign: "center", color: colors.white }}>
            Plateforme d'évangelisation permettant la diffusion de la parole de Dieu sur l'étendue de la terre et une très bonne structure des églises.
          </Typography>
        </Stack>
      </Grid>
      <Loading open={loading} />
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </Grid>
  )
}

export default AuthCreateChurchesView;
