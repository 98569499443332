import React, { useEffect } from "react";
import { Box, Typography, Grid, IconButton, Paper, CardActionArea } from "@mui/material";
import Layout from '../../components/layout';
import { CardLivre } from "../../components/card/card.disk";
import { colors } from "../../config";
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentList from "../../components/comment";
import { selectAuth } from "../../services/store/auth/auth.slice";
import { useAppSelector } from "../../services/store/hooks";
import jwtDecode from "jwt-decode";
import { DeleteFile, getFile } from "../../services/api/fileApi";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import Loading from "../../components/loading";
import { url } from "../../services/api";
import { CreateFileModal, UpdateFileModal } from "../../components/modal";
import { Moment } from "moment";
import { Btn } from "../../components/button";
import AlertDialogSlide from "../../components/AlertDialogSlide";

const LivresScreenView = () => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalUpdateFIle, setOpenModalUpdateFile] = React.useState<boolean>(false);
  const [livres, setlivres] = React.useState([])
  const auth = useAppSelector(selectAuth);
  const [currentLivres, setCurrentLivres] = React.useState<any>({});
  const user: any = jwtDecode(auth.access_token)
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [alertState, setAlertState] = React.useState({ open: false, msg: <></> });
  const [dateLive, setDateLive] = React.useState<Moment | null>(null);
  const [titre, setTitre] = React.useState<string>("");
  const [auteur, setAuteur] = React.useState<string>("");
  const [photo, setPhoto] = React.useState<any>("");
  const [file, setFile] = React.useState<any>("");

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const handleOpenStack = React.useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  }

  const handleOpenAlert = () => {
    setAlertState({
      open: true,
      msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer le livre?</Typography>
    })
  };

  const handleCloseAlert = () => {
    setAlertState({
      open: false,
      msg: <></>
    });
  };

  const handleConfirmAction = () => {
    handleDelete()
    handleCloseAlert()
  }

  const findlivres = React.useCallback(async () => {
    setLoading(true);
    const get = await getFile(user?.eglise?.id_eglise, 'livres');
    setLoading(false);
    if (get?.status === 200 && get?.data !== undefined) {
      setlivres(get?.data);
      setCurrentLivres(get?.data[0])
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Vous avez aucun livre dans la bibliothéque'
      });
    }
  }, [handleOpenStack, user]);

  const handleDelete = async () => {
    setLoading(true);
    const deleted = await DeleteFile(currentLivres?.id, 'livres')
    setLoading(false);
    if (deleted?.status === 200) {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `L'audios est supprimer du système!`
      });
      await findlivres()
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
  }

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      findlivres();
    }
    return () => {
      isMount = false
    }
  }, [])

  return <Layout>
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <CardActionArea onClick={() => { window.open(`${url}files/livres/${currentLivres?.lien}`, '_blank'); }}>
              <Box>
                {currentLivres?.photo ? <Paper
                  style={{
                    height: 500,
                    background: `url(${url}files/livres/${currentLivres?.photo})`,
                    backgroundSize: "500px"
                  }}></Paper> :
                  currentLivres?.lien ?
                    <Typography variant="h5" color={colors.blueApp} textAlign={"center"} fontWeight={"bold"}>Ouvrir</Typography> : null}
              </Box>
            </CardActionArea>
          </Grid>
          <Grid item md={6}>
            <CommentList commentaires={currentLivres?.commentaire ? currentLivres?.commentaire : []} />
          </Grid>
          <Grid item md={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography variant="h6" color={colors.blueApp} fontWeight={"bold"} mr={2}>{currentLivres?.titre}</Typography>
            {currentLivres?.commentaire ? <IconButton>
              <CommentIcon htmlColor={colors.blueApp} />
              <Typography color={colors.blueApp} fontWeight={"bold"} mr={2}> {currentLivres?.commentaire?.length}</Typography>
            </IconButton> : null}
            {currentLivres?.favoris ? <IconButton>
              <FavoriteIcon htmlColor={colors.redApp} />
              <Typography color={colors.blueApp} fontWeight={"bold"}> {currentLivres?.favoris?.length}</Typography>
            </IconButton> : null}
            {currentLivres?.id ?
              <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Box>
                  <Btn action={() => {
                    setTitre(currentLivres?.titre);
                    setAuteur(currentLivres?.auteur);
                    setOpenModalUpdateFile(true)
                  }} width={"auto"} margin={"5px"} color={colors.griseApp2}>Modifier</Btn></Box>
                <Btn action={() => { handleOpenAlert() }} width={"auto"} color={colors.redApp}>Supprimer</Btn>
              </Box>
              : null}
          </Grid>
          <Grid item md={6}></Grid>
        </Grid>
        <Grid item md={12}>
          <Btn action={() => toggleModal()} color={colors.blueApp}>Ajouter un livre</Btn>
        </Grid>
        <Box mt={1}>
          <Typography variant="h6" textAlign={"center"}><b>Livres</b></Typography>
          <Grid container spacing={2}>
            {livres.length > 0 ? livres?.map((item: any, i: number) => {
              return (
                <Grid key={`${i}${item?.auteur} ${item?.titre}`} item md={3} xs={6}>
                  <CardLivre action={() => {
                    setCurrentLivres(item)
                  }} webp={item?.webp ? `${url}files/webp/${item?.webp}` : "./ecclessia.png"} titre={item?.titre} auteur={item?.auteur} />
                </Grid>
              )
            }) : <Grid item md={12} xs={12}>
              <Typography variant="h6" textAlign={"center"} color={colors.griseApp}>Vous avez aucun livres dans la bibliothéque</Typography>
            </Grid>
            }
          </Grid>
        </Box>
        <CreateFileModal open={openModal} handleClose={toggleModal} handlCallbackReload={findlivres} />
        <UpdateFileModal open={openModalUpdateFIle} handleClose={() => { setOpenModalUpdateFile(false) }} handlCallbackReload={findlivres}
          data={{
            photo,
            setPhoto,
            file, setFile,
            dateLive,
            setDateLive,
            titre, setTitre,
            auteur, setAuteur,
            iframe: "",
            setIframe: () => { },
            acceptFile: ".pdf",
            idFile: currentLivres?.id
          }}
        />
        <Loading open={loading} />
        <SnackbarAlert
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          type={snackState.type}
          message={snackState.message}
        />
        <AlertDialogSlide
          open={alertState.open}
          Message={() => alertState.msg}
          handleClose={handleCloseAlert}
          handleConfirmAction={() => handleConfirmAction()}
        />
      </Box>
    </>
  </Layout>
}

export default LivresScreenView
