import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import Layout from "../../components/layout";
import { Btn } from "../../components/button";
import { colors } from "../../config";
import DeleteIcon from '@mui/icons-material/Delete';
import DataTable from "../../components/table/data.table";
import { CreateProgrammeModale, CreateSousProgrammeModale, UpdateSousProgrammeModale } from "../../components/modal";
import { useAppSelector } from "../../services/store/hooks";
import jwtDecode from "jwt-decode";
import { selectAuth } from "../../services/store/auth/auth.slice";
import { DeleteProgramme, DeleteSousProgramme, FindProgramme, UpdateProgramme } from "../../services/api/churcheApi";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import Loading from "../../components/loading";
import moment, { Moment } from "moment";
import 'moment/locale/fr';
import AlertDialogSlide from "../../components/AlertDialogSlide";
import BorderColorIcon from '@mui/icons-material/BorderColor';

const columns = [
  {
    name: "n",
    label: "#",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "createdAt",
    label: "Date de création",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "titre",
    label: "Programme",
    options: {
      filter: true,
      sort: true,
    }
  },
];

const ProgrammeScreenView = () => {
  const [programme, setProgramme] = useState<any[]>([]);
  const [currentProgramme, setCurrentProgramme] = useState<any>({});
  const [currentSousProgramme, setCurrentSousProgramme] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [opensp, setOpensp] = useState<boolean>(false);
  const [openUpdateSousProgramme, setOpenUpdateSousProgramme] = useState<boolean>(false);
  const auth = useAppSelector(selectAuth);
  const user: any = jwtDecode(auth.access_token)
  const [loading, setLoading] = useState<boolean>(false);
  const [alertState, setAlertState] = useState({ open: false, msg: <></> });
  const [alertSousProgrammeState, setAlertSousProgrammeState] = useState({ open: false, msg: <></> });
  const [snackState, setSnackState] = useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });

  const [libele, setLibele] = React.useState<string>("");
  const [startTime, setStartTime] = React.useState<Moment | null>(null);
  const [endTime, setEndTime] = React.useState<Moment | null>(null);

  const toggleModal = () => {
    setOpen(!open)
  }

  const toggleModalSp = () => {
    setOpensp(!opensp);
  };

  const handleOpenAlert = (type: string = "") => {
    if (type === "sous") {
      setAlertSousProgrammeState({
        open: true,
        msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer le sous-programme?</Typography>
      });
    } else {
      setAlertState({
        open: true,
        msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer le programme?</Typography>
      });
    }

  };

  const handleCloseAlert = () => {
    setAlertState({
      open: false,
      msg: <></>
    });
    setAlertSousProgrammeState({
      open: false,
      msg: <></>
    });
  };

  const handleOpenStack = useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleConfirmAction = (type: string = "") => {
    if (type === "sous") {
      handleDeleteSousPrograme();
    } else {
      handleDelete();
    }
  }

  const handleDelete = async () => {
    setLoading(true);
    const deleteProgramme = await DeleteProgramme(currentProgramme?.id);
    setLoading(false);
    if (deleteProgramme?.status === 200) {
      handleCloseAlert()
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `Le programme est supprimer correctement!`
      });
      await getProgramme();
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur est survenue lors de la suppresion du programent`
      });
    }
  }

  const getProgramme = useCallback(async () => {
    setLoading(true);
    const get = await FindProgramme();
    setLoading(false);
    if (get?.data !== undefined && get?.status === 200) {
      let newData: any[] = [];
      get?.data?.map((item: any, i: number) => {
        newData.push({
          id: item?.id,
          n: i + 1,
          titre: item?.titre,
          createdAt: moment(item?.createdAt).calendar(),
          eglise: item?.eglise,
          sousProgramme: item?.sousProgramme
        });
      });
      setProgramme(newData);
      setCurrentProgramme(newData[0])
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Une erreur est survenue lors de la récupération des membres'
      });
    }
  }, []);

  const reloadGetProgramme = useCallback(async () => {
    setLoading(true);
    const get = await FindProgramme();
    setLoading(false);
    if (get?.data !== undefined && get?.status === 200) {
      let newData: any[] = [];
      get?.data?.map((item: any, i: number) => {
        newData.push({
          id: item?.id,
          n: i + 1,
          titre: item?.titre,
          createdAt: moment(item?.createdAt).calendar(),
          eglise: item?.eglise,
          sousProgramme: item?.sousProgramme
        });
      })
      setProgramme(newData);
      const curent = newData.find((item: any) => item?.id === currentProgramme?.id)
      if (curent)
        setCurrentProgramme(curent)
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Une erreur est survenue lors de la récupération des membres'
      });
    }
  }, []);

  const handleDeleteSousPrograme = async () => {
    setLoading(true);
    const deletesousProgramme = await DeleteSousProgramme(currentSousProgramme?.id);
    setLoading(false);
    if (deletesousProgramme?.status === 200) {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `L'évenement est supprimer correctement!`
      });
      await getProgramme();
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `Une erreur survenue lors traitement!`
      });
    }
  }


  useEffect(() => {
    let isMount = true;
    if (isMount) {
      getProgramme();
    }
    return () => {
      isMount = false;
    }
  }, []);

  return (
    <Layout>
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Btn color={colors.blueApp} action={() => { toggleModal() }}>Ajouter un programme</Btn>
        </Grid>
        <Grid item md={8}>
          <DataTable handleClick={setCurrentProgramme} title={"Programme"} data={programme} columns={columns} />
        </Grid>
        <Grid item md={4}>
          <Card sx={{ maxWidth: 345 }}>
            <CardHeader title={<Typography variant="h5" ><b>{currentProgramme?.titre}</b></Typography>} />
            <CardContent>
              <Grid container>
                {currentProgramme?.sousProgramme?.length > 0 ? currentProgramme?.sousProgramme?.map((item: any) => {
                  return (
                    <Grid item container md={12}>
                      <Grid item md={8}>
                        <Typography variant="body2" color="text.secondary">Évenement : {item?.libelle}</Typography>
                        <Typography variant="body2" color="text.secondary">De : {moment(item?.debut).format("LT")}</Typography>
                        <Typography variant="body2" color="text.secondary">À : {moment(item?.fin).format("LT")}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <IconButton onClick={() => {
                          handleOpenAlert("sous")
                          setCurrentSousProgramme(item)
                        }}><DeleteIcon /></IconButton>
                        <IconButton
                          onClick={() => {
                            setCurrentSousProgramme(item);
                            setOpenUpdateSousProgramme(true);
                            setStartTime(item?.debut);
                            setEndTime(item?.fin)
                            setLibele(item?.libelle)
                          }}><BorderColorIcon /></IconButton>
                      </Grid>
                    </Grid>
                  )
                }) : <Typography variant="body2" color="text.secondary">Aucun programme calé pour cette journée</Typography>}
              </Grid>
            </CardContent>
            <CardActions>
              <Button onClick={() => { handleOpenAlert() }} size="small">supprimer</Button>
              <Button onClick={toggleModalSp} size="small">horaire</Button>
            </CardActions>
          </Card>
        </Grid>
        <CreateProgrammeModale open={open} handleClose={toggleModal} handlCallbackReload={getProgramme} />
        <CreateSousProgrammeModale open={opensp} handleClose={toggleModalSp} handlCallbackReload={reloadGetProgramme} data={currentProgramme?.id} />
        <UpdateSousProgrammeModale
          open={openUpdateSousProgramme}
          handleClose={() => { setOpenUpdateSousProgramme(false) }}
          handlCallbackReload={reloadGetProgramme}
          data={{currentSousProgramme,libele,startTime,endTime, setStartTime, setEndTime, setLibele }} />
        <Loading open={loading} />
        <SnackbarAlert
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          type={snackState.type}
          message={snackState.message}
        />
        <AlertDialogSlide
          open={alertState.open}
          Message={() => alertState.msg}
          handleClose={handleCloseAlert}
          handleConfirmAction={() => handleConfirmAction()}
        />
        <AlertDialogSlide
          open={alertSousProgrammeState.open}
          Message={() => alertSousProgrammeState.msg}
          handleClose={handleCloseAlert}
          handleConfirmAction={() => handleConfirmAction("sous")}
        />
      </Grid>
    </Layout>
  )
}

export default ProgrammeScreenView;