import * as React from 'react';
import { Box } from '@mui/material';
import Drawer from './drawer';
import Footer from './footer';
import Header from './header';

interface Props {
    children : JSX.Element
}
const Layout = ({children} : Props) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
    return <Box sx={{pt: 0.5}} 
    // className='gradient_background' 
    >
        <Header toggleDrawer={handleDrawerToggle}/>
        <Drawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}/>
        <Box sx={{ml: {md:40, xs:1}, mt: -10, mr:{md:2,xs:1}}}>
        {children}
        </Box>
        {/* <Footer/> */}
    </Box>
}

export default Layout;
