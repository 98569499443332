import React, { useState } from "react";
import { Box, CardActionArea, Typography } from "@mui/material";
import { colors } from "../../config";
import { VideoPlayerModal } from "../modal/adminModal";

export interface PropsCardDisk {
  webp: string,
  titre: string,
  auteur: string,
  action?: () => void,
  data?: any
}

export const CardDisk = (props: PropsCardDisk) => {
  const myAction = () => {
    if (props?.action !== undefined) {
      props?.action()
    }
  }
  return <CardActionArea onClick={() => { myAction() }}>
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        background: colors.blackOpacity,
        // opacity: 0.5,
        border: '0.5px solid #000',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        "&:hover": {
          cursor: "pointer",
          boxShadow: "5px 5px 5px 5px rgba(0, 0, 0, 0.2)",
          transition: "1s"
        }
      }}
      pt={2}
      pb={2}
    >
      <img
        src={props.webp}
        alt="image disk"
        style={{ width: 150, height: 150, borderRadius: '100px' }} />
      <Typography color={colors.griseApp} fontWeight={"bold"}>{props.auteur}</Typography>
      <Typography color={colors.griseApp}>{props.titre}</Typography>
    </Box>
  </CardActionArea>
}


export const CardLivre = (props: PropsCardDisk) => {

  const myAction = () => {
    if (props?.action !== undefined) {
      props?.action()
    }
  }
  return <CardActionArea onClick={() => { myAction() }}>
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        "&:hover": {
          cursor: "pointer",
          boxShadow: "5px 5px 5px 5px rgba(0, 0, 0, 0.2)",
          transition: "1s"
        }
      }}
      pt={2}
      pb={2}
    >
      <img
        src={props.webp}
        alt={props.webp + props.auteur}
        style={{ width: 150, height: 150 }} 
      />
      <Typography fontWeight={"bold"}>{props.auteur}</Typography>
      <Typography color={colors.griseApp2}>{props.titre}</Typography>
    </Box>
  </CardActionArea>
}

export const CardDiskAdmin = (props: PropsCardDisk) => {
  const {data} = props;
  const [openModal, setOpenModal] = useState<boolean>(true);

  const myAction = () => {
    if (props?.action !== undefined) {
      props?.action()
      setOpenModal(true);
    }
  }

  return <Box>
    <CardActionArea onClick={() => { myAction() }}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        sx={{
          "&:hover": {
            cursor: "pointer",
            boxShadow: "5px 5px 5px 5px rgba(0, 0, 0, 0.2)",
            transition: "1s"
          }
        }}
        pt={2}
        pb={2}
      >
        <img
          src={props.webp}
          alt="image disk"
          style={{ width: 150, height: 150, borderRadius: '100px' }} />
        <Typography fontWeight={"bold"}>{props.auteur}</Typography>
        <Typography color={colors.griseApp2}>{props.titre}</Typography>
      </Box>
    </CardActionArea>
    <VideoPlayerModal open={openModal} handleClose={() => {setOpenModal(false)}} data={{
      vides: data?.videos,
      currentVideos: data?.currentVideos
    }} />
  </Box>
}