import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import Layout from "../../components/layout";
import { Btn } from "../../components/button";
import { colors, PrivilegesEnum } from "../../config";
import { VideoAnnoncePlayer } from "../../components/videosPlayer";
import DataTable from "../../components/table/data.table";
import { CreateAnnonceModale } from "../../components/modal";
import { useAppSelector } from "../../services/store/hooks";
import jwtDecode from "jwt-decode";
import { selectAuth } from "../../services/store/auth/auth.slice";
import { DeleteAnnonce, FindAllAnnonce, FindAnnonce } from "../../services/api/churcheApi";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import Loading from "../../components/loading";
import moment from "moment";
import AlertDialogSlide from "../../components/AlertDialogSlide";
import axios from "axios";
import { SendFileInFacebook } from "../../config/facebook.sdk";
import { findAllEglisse } from "../../services/api/adminApi";




const AnnonceScreenView = () => {
  const [annonces, setAnnonce] = useState<any[]>([]);
  const [currentAnnonce, setCurrentAnnonce] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const auth = useAppSelector(selectAuth);
  const user: any = jwtDecode(auth.access_token)
  const [loading, setLoading] = useState<boolean>(false);
  const [alertState, setAlertState] = useState({ open: false, msg: <></> });
  const [snackState, setSnackState] = useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [churches, setChurches] = useState<any[]>([]);
  const columns = [
    {
      name: "n",
      label: "#",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "createdAt",
      label: "Date de création",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "contente",
      label: "Contenu",
      options: {
        filter: true,
        sort: false,
      }
    },
  ];
  if (user?.privilege_user === PrivilegesEnum.ADMIN) {
    columns.push({
      name: "nom_eglise",
      label: "Eglise",
      options: {
        filter: true,
        sort: false,
      }
    })
  }
  const toggleModal = () => {
    setOpen(!open)
  }

  const handleOpenAlert = () => {
    setAlertState({
      open: true,
      msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer l'annonce?</Typography>
    });
  }

  const handleCloseAlert = () => {
    setAlertState({
      open: false,
      msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer l'annonce?</Typography>
    });
  }

  const handleOpenStack = useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleConfirmAction = () => {
    handleDelete();
  }

  const handleDelete = async () => {
    setLoading(true);
    const deleteAnnonce = await DeleteAnnonce(currentAnnonce?.id);
    setLoading(false);
    if (deleteAnnonce?.status === 200) {
      handleCloseAlert()
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `L'annonce est supprimer correctement!`
      });
      await getAnnonce();
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur est survenue lors de la suppresion de l'annonces`
      });
    }
  }

  const getAnnonce = useCallback(async () => {
    setLoading(true);
    const get = user?.privilege_user === PrivilegesEnum.ADMIN ? await FindAllAnnonce() : await FindAnnonce(user?.eglise?.id_eglise);
    setLoading(false);
    if (get?.data !== undefined && get?.status === 200) {
      let newData: any[] = [];
      get?.data?.map((item: any, i: number) => {
        newData.push({
          id: item?.id,
          n: i + 1,
          contente: item?.contente,
          createdAt: moment(item?.createdAt).calendar(),
          eglise: item?.eglise,
          nom_eglise: item?.eglise?.nom_eglise

        });
      })
      setAnnonce(newData);
      setCurrentAnnonce(newData[0])
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Une erreur est survenue lors de la récupération des membres'
      });
    }
  }, []);

  const handlePostInFacebook = async (annonce: string) => {
    SendFileInFacebook(annonce)
  }

  const getChurche = async () => {
    const getAll = await findAllEglisse();
    if (getAll?.status === 200) {
      setChurches(getAll?.data)
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
  }

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      getAnnonce();
      getChurche();
    }
    return () => {
      isMount = false;
    }
  }, []);


  return (
    <Layout>
      <Grid container spacing={1}>
        {/* {user?.privilege_user === PrivilegesEnum.ADMIN ?  */}
        <Grid item md={12}>
          <Btn color={colors.blueApp} action={() => { toggleModal() }}>Publier une annonces</Btn>
        </Grid>
        {/* : null} */}

        <Grid item md={8}>
          <DataTable handleClick={setCurrentAnnonce} title={"Annonces"} data={annonces} columns={columns} />
        </Grid>
        <Grid item md={4}>
          <Card>
            <CardContent>
              <VideoAnnoncePlayer allVideos={annonces} videos={currentAnnonce?.id} />
            </CardContent>
            <CardActions>
              <Button onClick={handleOpenAlert} size="small">supprimer</Button>
              <Button onClick={() => { handlePostInFacebook(currentAnnonce?.contente) }} size="small">Publier sur facebook</Button>
            </CardActions>
          </Card>
        </Grid>
        <CreateAnnonceModale open={open} handleClose={toggleModal} handlCallbackReload={getAnnonce} data={churches} />
        <Loading open={loading} />
        <SnackbarAlert
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          type={snackState.type}
          message={snackState.message}
        />
        <AlertDialogSlide
          open={alertState.open}
          Message={() => alertState.msg}
          handleClose={handleCloseAlert}
          handleConfirmAction={() => handleConfirmAction()}
        />
      </Grid>
    </Layout>
  )
}

export default AnnonceScreenView;