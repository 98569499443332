import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { colors, PrivilegesEnum } from '../../config';
import { Btn } from '../../components/button';
import SnackbarAlert, { PropsSnack } from '../../components/snackbarAlert';
import Loading from '../../components/loading';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}





const HelpView = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const navigation = useNavigate();




  return (
    <Grid container style={{ position: 'absolute', top: 0, bottom: 0, height: "100%", background: colors.blueApp }}>
      <Grid item md={4} xs={12}>
        <Stack
          sx={{ height: "100%", background: colors.blueApp, justifyContent: { md: "center", xs: "none" } }}
          alignItems={"center"}
          // justifyContent={'center'}
          alignContent={'center'}>
          <Stack
            sx={{ display: { xs: "flex", md: 'none' } }}
            alignItems={"center"}
            justifyContent={'center'}
            alignContent={'center'}>
            <img
              src='./ecclessia.png'
              alt="ecclessia"
              width={80}
            />
            <Typography fontWeight={"bold"} color={colors.white}>EcclesiaBook</Typography>
          </Stack>
          <Grid container item mb={2}>
            <Grid item md={1}></Grid>
            <Grid item md={10} xs={0} display={{ xs: 'none', md: "flex" }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                <Tab sx={{ color: "white" }} label="Mentions légales" {...a11yProps(0)} />
                <Tab sx={{ color: "white" }} label="Politique de confidentialité" {...a11yProps(1)} />
                <Tab sx={{ color: "white" }} label="Termes et Conditions d'utilisation." {...a11yProps(2)} />
              </Tabs>
            </Grid>
            <Grid item md={10} xs={12} display={{ xs: 'flex', md: "none" }}>
              <Tabs
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider', }}
              >
                <Tab wrapped sx={{ color: "white" }} label="Mentions légales" {...a11yProps(0)} />
                <Tab wrapped sx={{ color: "white" }} label="Politique de confidentialité" {...a11yProps(1)} />
                <Tab wrapped sx={{ color: "white" }} label="Termes et Conditions d'utilisation." {...a11yProps(2)} />
              </Tabs>
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>

          <Grid container item mb={2} display={{ md: "none" }}>
            <Grid item xs={12}>
              <Card sx={{ height: 450, overflowY: "scroll" }}>
                <CardContent>
                  <TabPanel value={value} index={2}>
                    <Typography fontWeight={"bold"} color={colors.griseApp2} variant='h5' textAlign="center" sx={{ paddingY: 1, background: colors.griseApp }}>Termes et Conditions</Typography>
                    <Typography fontWeight={"bold"} variant={"h6"} sx={{ mt: 2, background: 'yellow' }}>Dernière mise à jour : 18 Juin 2023</Typography>

                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>1. Conditions générales d’utilisation de la plateforme et des services proposés.</Typography>
                    <p>L’utilisation de la plateforme <span style={{ color: "#2E75B5", textDecoration: "underline" }}>EcclesiaBooK</span> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs de la plateforme <span style={{ color: "#2E75B5", textDecoration: "underline" }}>EcclesiaBooK</span> sont donc invités à les consulter de manière régulière. Cette plateforme est normalement accessible à tout moment aux utilisateurs. Elle est constituée de deux parties, dont une partie “<b>web</b>”, destinée aux responsables des “<b>églises ou groupes des prières</b>” et une “<b>application mobile</b>”, destinée aux fidèles des églises ou des groupes des prières.</p>
                    <p>Une interruption pour raison de maintenance technique peut être toutefois décidée par l’équipe technique de <b>LinkEd Solution DRC</b>, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heurees de l’intervention.</p>
                    <p>Le réseau social évangélique EcclesiaBooK est mise à jour régulièrement par LinkEd Solution DRC. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>

                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>2. Description des services fournis.</Typography>
                    <p>Le réseau social évangélique EcclesiaBooK permet aux églises chrétiennes de publier leurs contenus afin de créer un lien assez étroit avec leurs fidèles. Par contenu, il faut entendre les enseignements, les prédications, les informations relatives à leurs activités (annonces, communiqués, programme, …) et tout ce qui s’y rapporte, avec pour but principal, l’édification du corps du Christ. En bref, le réseau social évangélique EcclesiaBooK rapproche les églises à leur fidèle quelle que soit leur localisation sur le globe. LinkEd Solution DRC se réserve le droit d’ajouter dans le réseau social évangélique (partie web et/ou application mobile), des services, cela dans le but d’accompagner les utilisateurs dans leurs besoins relatifs à l’évangélisation et organisation des activités des églises.</p>
                    <p>Chaque église et/ou fidèle qui s’enregistre sur le réseau social évangélique EcclesiaBooK s’efforce d’y fournir des informations aussi précises que possible. </p>
                    <p>Toutes les informations indiquées sur le réseau social évangélique EcclesiaBooK  sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le réseau social évangélique EcclesiaBooK  ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>3. Limitations contractuelles sur les données techniques.</Typography>
                    <p>Le réseau social évangélique  EcclesiaBooK ne pourra être tenue responsable de dommages matériels liés à son utilisation. De plus, l’utilisateur du réseau social évangélique  s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis à jour. </p>

                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>4. Propriété intellectuelle et contrefaçons.</Typography>
                    <p>LinkEd Solution DRC est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le réseau social évangélique , notamment les textes, images, graphismes, logo, icônes, sons, logiciels.</p>
                    <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du réseau social évangélique , quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de LinkEd Solution DRC. </p>
                    <p>Toute exploitation non autorisée du réseau social évangélique  ou d’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions légales en vigueur en République Démocratique du Congo. </p>

                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>5. Limitations de responsabilité.</Typography>
                    <p>LinkEd Solution DRC ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès le réseau social évangélique EcclesiaBooK, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 3, soit de l’apparition d’un bug ou d’une incompatibilité. LinkEd Solution DRC se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…) et contraire à notre confession de foi. </p>
                    <p>En cas de publication par une église de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…) et contraire à notre confession de foi, LinkEd Solution DRC se réserve également la possibilité de suspendre le compte de l’utilisateur.</p>
                    <p>LinkEd Solution DRC donne la possibilité aux différentes églises enregistrées de publier du contenu sur le réseau social évangélique  EcclesiaBooK. Le contenu publié sur le réseau social évangélique  EcclesiaBooK venant des utilisateurs du réseau social évangélique  (églises ou groupes des prières et fidèles), LinkEd Solution DRC ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour desdites informations. Dans le même sens, LinkEd Solution DRC ne pourra être tenue responsable des messages à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…) et contraire à notre confession de foi, provenant du compter d’une église ou d’un utilisateur de l’application mobile.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>6. Gestion des données personnelles.</Typography>
                    <p>A l'occasion de l'utilisation du réseau social évangélique  EcclesiaBooK, peuvent être recueillies :l'URL des liens par l'intermédiaire desquels l'utilisateur a accédé au réseau social évangélique  EcclesiaBooK, le fournisseur d'accès de l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur. En tout état de cause LinkEd Solution DRC ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le réseau social évangélique  EcclesiaBooK. Lorsque l’utilisateur s’enregistre au réseau social évangélique EcclesiaBooK, LinkEd Solution DRC réunis les informations de ce dernier dans une base des données pour le compte de l’église dans lequel l’utilisateur s’est enregistré. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du réseau social évangélique  EcclesiaBooK  l’obligation ou non de fournir ces informations.</p>
                    <p>Aucune information personnelle de l'utilisateur du réseau social évangélique  EcclesiaBooK  n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de LinkEd Solution DRC et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du réseau social évangélique  EcclesiaBooK.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>7. Liens hypertextes et cookies.</Typography>
                    <p>Le réseau social évangélique  EcclesiaBooK étant mise à la disposition des églises, ces dernières peuvent y publier un lien hypertexte vers leur site internet avec l’autorisation de LinkEd Solution DRC. Cependant, LinkEd Solution DRC n’a pas la possibilité de vérifier le contenu des sites publiés par les églises, et n’assumera en conséquence aucune responsabilité de ce fait. La navigation sur le réseau social évangélique  EcclesiaBooK  est susceptible de provoquer l’installation de cookie(s) sur l’équipement (ordinateur, téléphone, tablette) de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site.  Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le réseau social évangélique, et ont également vocation à permettre diverses mesures de fréquentation. </p>
                    <p>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. </p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>8. Droit applicable et attribution de juridiction.</Typography>
                    <p>Tout litige en relation avec l’utilisation du réseau social évangélique  EcclesiaBooK est soumis au droit Congolais. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Kinshasa en République Démocratique du Congo. </p>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Typography fontWeight={"bold"} color={colors.griseApp2} variant='h5' textAlign="center" sx={{ paddingY: 1, background: colors.griseApp }}>POLITIQUE DE CONFIDENTIALITÉ</Typography>
                    <Typography fontWeight={"bold"} variant={"h6"} sx={{ mt: 2, background: 'yellow' }}>Dernière mise à jour : 18 Juin 2023</Typography>
                    <p>Cette politique de confidentialité vous informe de nos politiques en matière de collecte, d'utilisation et de divulgation des informations personnelles lorsque vous utilisez notre réseau social évangélique (EcclesiaBooK). EcclesiaBooK est constituée de deux parties, dont une partie “web”, destinée aux responsables des “églises ou groupes des prières” et une “application mobile”, destinée aux fidèles des églises ou des groupes des prières. Nous n'utilisons ni ne partageons vos informations avec qui que ce soit, sauf dans les cas décrits dans la présente politique de confidentialité.</p>
                    <p>Nous utilisons vos informations personnelles pour fournir et améliorer les services fournis dans le réseau social évangélique. En utilisant le réseau social évangélique, vous acceptez la collecte et l'utilisation d'informations conformément à cette politique. Sauf indication contraire dans la présente politique de confidentialité, les termes utilisés dans cette politique de confidentialité ont la même signification que dans nos Termes et Conditions, accessibles sur le réseau social évangélique EcclesiaBooK.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>1. Collecte d'informations et utilisation</Typography>
                    <p>Lors de l'utilisation de notre réseau social évangélique, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent être utilisées pour vous contacter ou vous identifier. Les informations personnellement identifiables ("Informations personnelles") peuvent inclure, sans toutefois s'y limiter : </p>
                    <ul>
                      <li><b>Nom(s) et prénom (s) </b></li>
                      <li><b>Adresse email.</b></li>
                      <li><b>Numéro de téléphone </b></li>
                      <li><b>Votre adresse personnelle</b></li>
                      <li><b>Adresse de votre église</b></li>
                      <li><b>Votre Sexe</b></li>
                    </ul>
                    <p>Lorsque l’utilisateur s’enregistre au réseau social évangélique EcclesiaBooK, LinkEd Solution DRC réunis les informations de ce dernier dans une base des données pour le compte de l’église dans lequel l’utilisateur s’est enregistré. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du réseau social évangélique  EcclesiaBooK  l’obligation ou non de fournir ces informations.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>2. Données de journal</Typography>
                    <p>Nous collectons les informations que votre navigateur envoie à chaque fois que vous visitez notre réseau social évangélique ("données de journal"). Ces données de journal peuvent inclure des informations telles que l'adresse IP (Internet Protocol) de votre ordinateur, le type de navigateur, la version du navigateur, les pages de notre service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, etc. </p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>3. Cookies</Typography>
                    <p>Les cookies sont des fichiers contenant une petite quantité de données, pouvant inclure un identifiant unique et anonyme. Les cookies sont envoyés à votre navigateur à partir d'un site web et stockés sur le disque dur de votre ordinateur. </p>
                    <p>Nous utilisons des "cookies" pour collecter des informations. Vous pouvez demander à votre navigateur de refuser tous les cookies ou d'indiquer quand un cookie est envoyé. Toutefois, si vous n'acceptez pas les cookies, vous ne pourrez peut-être pas utiliser certaines parties ou services de notre réseau social évangélique.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>4. Service Providers</Typography>
                    <p>Nous pouvons faire appel à des sociétés tierces et à des particuliers pour faciliter notre service, pour fournir le service en notre nom, pour fournir des services liés au service ou pour nous aider à analyser l'utilisation de notre service. </p>
                    <p>Ces tiers n'ont accès à vos informations personnelles que pour effectuer ces tâches pour notre compte et sont tenus de ne pas les divulguer ni les utiliser à d'autres fins. </p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>5. Sécurité</Typography>
                    <p>La sécurité de vos informations personnelles est importante pour nous, mais rappelez-vous qu'aucune méthode de transmission sur Internet, ni aucune méthode de stockage électronique n'est sécurisée à 100%. Bien que nous nous efforçons d'utiliser des moyens commercialement acceptables pour protéger vos informations personnelles, nous ne pouvons pas garantir leur sécurité absolue. </p>
                    <p>Pour ce qui est de la sécurité de vos informations personnelles de paiement, nous travaillons avec MaxiCash (https://maxicash.co/fr), qui est une passerelle de paiement qui propose des services de transfert d'argent et des cartes prépayées Visa. Lors de chaque transaction de paiement, LinkEd Solution DRC ne recueille aucune information de l’utilisateur, c’est-à-dire LinkEd Solution DRC ne recueille pas le numéro de carte, la date de validité et tout autre information de paiement liées de l’utilisateur.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>6. Liens vers d'autres sites</Typography>
                    <p>Notre réseau social évangélique peut contenir des liens vers d'autres sites que nous n'exploitons pas. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site que vous visitez. Nous n'avons aucun contrôle sur, et n'assumons aucune responsabilité pour le contenu, les politiques de confidentialité ou les pratiques de tout site ou service tiers. </p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>7. Changements à cette politique de confidentialité</Typography>
                    <p>Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de tout changement en affichant la nouvelle politique de confidentialité sur cette page. Nous vous conseillons de consulter périodiquement cette politique de confidentialité pour vous tenir informé de tout changement. Les modifications apportées à cette politique de confidentialité entrent en vigueur lorsqu'elles sont publiées sur cette page. </p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>8. Contactez-nous</Typography>
                    <p>Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter.</p>
                    <ul>
                      <li>Email: <b> <a href="mailto:info@linked-solution.com ">info@linked-solution.com </a> </b></li>
                      <li>Téléphone: <b><a href="tel:+243851945396">+243851945396</a></b></li>
                      <li>Site web: <b><a href="https://www.linked-solution.com ">www.linked-solution.com</a></b> </li>
                    </ul>

                  </TabPanel>
                  <TabPanel value={value} index={0}>
                    <Typography fontWeight={"bold"} color={colors.griseApp2} variant='h5' textAlign="center" sx={{ paddingY: 1, background: colors.griseApp }}>Mentions légales</Typography>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5 }}>1. Éditeur</Typography>
                    <p>LinkEd Solution DRC SARL</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5 }}>2. Direction et publication de la rédaction</Typography>
                    <p>Email: <a href="mailto:contact@linked-solution.com">contact@linked-solution.com</a></p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5 }}>2. Hébergement</Typography>
                    <p>Le site www.ecclesiabook.org  est hébergé par la société : Hostinger International Ltd. <br />
                      61 Lordou Vironos, Larnaca, CY, 6023 <br />
                      Tél. +62 81533832199 <br />
                    </p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5 }}>3. Propriété intellectuelle</Typography>
                    <p><b>LinkEd Solution DRC</b> est propriétaire de la plateforme “EcclesiaBooK”.Dans ce sens, il en détient des droits de propriété intellectuelle. A ce titre, <b>LinkEd Solution DRC</b> est propriétaire du nom de domaine <a href="https://ecclesiabook.org">www.ecclesiabook.org</a>. L'accès à la plateforme ainsi qu’à tous les services qu’elle fournit est exclusivement limité à l'usage de l'Utilisateur dans les conditions et limites définies dans les Termes et consultables dans la plateforme EcclesiaBooK.</p>
                  </TabPanel>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container item mt={2} spacing={1}>
            <Grid item md={6} xs={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <Btn action={() => { navigation('/') }} color={colors.redApp}>Connectez-vous</Btn>
            </Grid>

            <Grid item md={6} xs={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <Btn action={() => { navigation('/AuthCreateAdminView') }} color={colors.griseApp2}>Inscrivez-vous</Btn>
            </Grid>
          </Grid>

          <Box display={"flex"} sx={{ background: colors.blueApp }} position={{ md: "absolute", xs: "relative" }} bottom={0} mt={{ xs: 3, md: 1 }}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Typography style={{ color: colors.griseApp2, textTransform: "none", textAlign: "center" }}> <a style={{ color: colors.griseApp2, textDecoration: 'none', textTransform: "none", textAlign: "center" }} href='#' onClick={() => { navigation('/HelpView') }}> Mentions légales | Politique de confidentialité | Termes et Conditions d'utilisation. </a></Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography sx={{ textAlign: "center" }} color={colors.griseApp2}>Copyright &#169; 2023 Tous droits réservés.</Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>

      <Grid item md={8} display={{ xs: 'none', md: "flex" }}>
        <Stack
          sx={{ height: "100%", width: "100%", background: colors.griseApp }}
          alignItems={"center"}
          // justifyContent={'center'}
          alignContent={'center'}
        >
          <img
            src='./ecclessia.png'
            alt="ecclessia"
            width={80}
          />
          <Typography fontWeight={"bold"}>EcclesiaBook</Typography>
          <Grid container item mb={2}>
            <Grid item md={1}></Grid>
            <Grid item md={10}>
              <Card sx={{ height: 450, overflowY: "scroll" }}>
                <CardContent>
                  <TabPanel value={value} index={2}>
                    <Typography fontWeight={"bold"} color={colors.griseApp2} variant='h3' textAlign="center" sx={{ paddingY: 1, background: colors.griseApp }}>Termes et Conditions</Typography>
                    <Typography fontWeight={"bold"} variant={"h5"} sx={{ mt: 2, background: 'yellow', width: 400 }}>Dernière mise à jour : 18 Juin 2023</Typography>

                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>1. Conditions générales d’utilisation de la plateforme et des services proposés.</Typography>
                    <p>L’utilisation de la plateforme <span style={{ color: "#2E75B5", textDecoration: "underline" }}>EcclesiaBooK</span> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs de la plateforme <span style={{ color: "#2E75B5", textDecoration: "underline" }}>EcclesiaBooK</span> sont donc invités à les consulter de manière régulière. Cette plateforme est normalement accessible à tout moment aux utilisateurs. Elle est constituée de deux parties, dont une partie “<b>web</b>”, destinée aux responsables des “<b>églises ou groupes des prières</b>” et une “<b>application mobile</b>”, destinée aux fidèles des églises ou des groupes des prières.</p>
                    <p>Une interruption pour raison de maintenance technique peut être toutefois décidée par l’équipe technique de <b>LinkEd Solution DRC</b>, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heurees de l’intervention.</p>
                    <p>Le réseau social évangélique EcclesiaBooK est mise à jour régulièrement par LinkEd Solution DRC. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>

                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>2. Description des services fournis.</Typography>
                    <p>Le réseau social évangélique EcclesiaBooK permet aux églises chrétiennes de publier leurs contenus afin de créer un lien assez étroit avec leurs fidèles. Par contenu, il faut entendre les enseignements, les prédications, les informations relatives à leurs activités (annonces, communiqués, programme, …) et tout ce qui s’y rapporte, avec pour but principal, l’édification du corps du Christ. En bref, le réseau social évangélique EcclesiaBooK rapproche les églises à leur fidèle quelle que soit leur localisation sur le globe. LinkEd Solution DRC se réserve le droit d’ajouter dans le réseau social évangélique (partie web et/ou application mobile), des services, cela dans le but d’accompagner les utilisateurs dans leurs besoins relatifs à l’évangélisation et organisation des activités des églises.</p>
                    <p>Chaque église et/ou fidèle qui s’enregistre sur le réseau social évangélique EcclesiaBooK s’efforce d’y fournir des informations aussi précises que possible. </p>
                    <p>Toutes les informations indiquées sur le réseau social évangélique EcclesiaBooK  sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le réseau social évangélique EcclesiaBooK  ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>3. Limitations contractuelles sur les données techniques.</Typography>
                    <p>Le réseau social évangélique  EcclesiaBooK ne pourra être tenue responsable de dommages matériels liés à son utilisation. De plus, l’utilisateur du réseau social évangélique  s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis à jour. </p>

                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>4. Propriété intellectuelle et contrefaçons.</Typography>
                    <p>LinkEd Solution DRC est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le réseau social évangélique , notamment les textes, images, graphismes, logo, icônes, sons, logiciels.</p>
                    <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du réseau social évangélique , quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de LinkEd Solution DRC. </p>
                    <p>Toute exploitation non autorisée du réseau social évangélique  ou d’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions légales en vigueur en République Démocratique du Congo. </p>

                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>5. Limitations de responsabilité.</Typography>
                    <p>LinkEd Solution DRC ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès le réseau social évangélique EcclesiaBooK, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 3, soit de l’apparition d’un bug ou d’une incompatibilité. LinkEd Solution DRC se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…) et contraire à notre confession de foi. </p>
                    <p>En cas de publication par une église de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…) et contraire à notre confession de foi, LinkEd Solution DRC se réserve également la possibilité de suspendre le compte de l’utilisateur.</p>
                    <p>LinkEd Solution DRC donne la possibilité aux différentes églises enregistrées de publier du contenu sur le réseau social évangélique  EcclesiaBooK. Le contenu publié sur le réseau social évangélique  EcclesiaBooK venant des utilisateurs du réseau social évangélique  (églises ou groupes des prières et fidèles), LinkEd Solution DRC ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour desdites informations. Dans le même sens, LinkEd Solution DRC ne pourra être tenue responsable des messages à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…) et contraire à notre confession de foi, provenant du compter d’une église ou d’un utilisateur de l’application mobile.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>6. Gestion des données personnelles.</Typography>
                    <p>A l'occasion de l'utilisation du réseau social évangélique  EcclesiaBooK, peuvent être recueillies :l'URL des liens par l'intermédiaire desquels l'utilisateur a accédé au réseau social évangélique  EcclesiaBooK, le fournisseur d'accès de l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur. En tout état de cause LinkEd Solution DRC ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le réseau social évangélique  EcclesiaBooK. Lorsque l’utilisateur s’enregistre au réseau social évangélique EcclesiaBooK, LinkEd Solution DRC réunis les informations de ce dernier dans une base des données pour le compte de l’église dans lequel l’utilisateur s’est enregistré. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du réseau social évangélique  EcclesiaBooK  l’obligation ou non de fournir ces informations.</p>
                    <p>Aucune information personnelle de l'utilisateur du réseau social évangélique  EcclesiaBooK  n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de LinkEd Solution DRC et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du réseau social évangélique  EcclesiaBooK.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>7. Liens hypertextes et cookies.</Typography>
                    <p>Le réseau social évangélique  EcclesiaBooK étant mise à la disposition des églises, ces dernières peuvent y publier un lien hypertexte vers leur site internet avec l’autorisation de LinkEd Solution DRC. Cependant, LinkEd Solution DRC n’a pas la possibilité de vérifier le contenu des sites publiés par les églises, et n’assumera en conséquence aucune responsabilité de ce fait. La navigation sur le réseau social évangélique  EcclesiaBooK  est susceptible de provoquer l’installation de cookie(s) sur l’équipement (ordinateur, téléphone, tablette) de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site.  Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le réseau social évangélique, et ont également vocation à permettre diverses mesures de fréquentation. </p>
                    <p>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. </p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>8. Droit applicable et attribution de juridiction.</Typography>
                    <p>Tout litige en relation avec l’utilisation du réseau social évangélique  EcclesiaBooK est soumis au droit Congolais. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Kinshasa en République Démocratique du Congo. </p>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Typography fontWeight={"bold"} color={colors.griseApp2} variant='h3' textAlign="center" sx={{ paddingY: 1, background: colors.griseApp }}>POLITIQUE DE CONFIDENTIALITÉ</Typography>
                    <Typography fontWeight={"bold"} variant={"h5"} sx={{ mt: 2, background: 'yellow', width: 400 }}>Dernière mise à jour : 18 Juin 2023</Typography>
                    
                    <p>Cette politique de confidentialité vous informe de nos politiques en matière de collecte, d'utilisation et de divulgation des informations personnelles lorsque vous utilisez notre réseau social évangélique (EcclesiaBooK). EcclesiaBooK est constituée de deux parties, dont une partie “web”, destinée aux responsables des “églises ou groupes des prières” et une “application mobile”, destinée aux fidèles des églises ou des groupes des prières. Nous n'utilisons ni ne partageons vos informations avec qui que ce soit, sauf dans les cas décrits dans la présente politique de confidentialité.</p>
                    <p>Nous utilisons vos informations personnelles pour fournir et améliorer les services fournis dans le réseau social évangélique. En utilisant le réseau social évangélique, vous acceptez la collecte et l'utilisation d'informations conformément à cette politique. Sauf indication contraire dans la présente politique de confidentialité, les termes utilisés dans cette politique de confidentialité ont la même signification que dans nos Termes et Conditions, accessibles sur le réseau social évangélique EcclesiaBooK.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>1. Collecte d'informations et utilisation</Typography>
                    <p>Lors de l'utilisation de notre réseau social évangélique, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent être utilisées pour vous contacter ou vous identifier. Les informations personnellement identifiables ("Informations personnelles") peuvent inclure, sans toutefois s'y limiter : </p>
                    <ul>
                      <li><b>Nom(s) et prénom (s) </b></li>
                      <li><b>Adresse email.</b></li>
                      <li><b>Numéro de téléphone </b></li>
                      <li><b>Votre adresse personnelle</b></li>
                      <li><b>Adresse de votre église</b></li>
                      <li><b>Votre Sexe</b></li>
                    </ul>
                    <p>Lorsque l’utilisateur s’enregistre au réseau social évangélique EcclesiaBooK, LinkEd Solution DRC réunis les informations de ce dernier dans une base des données pour le compte de l’église dans lequel l’utilisateur s’est enregistré. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du réseau social évangélique  EcclesiaBooK  l’obligation ou non de fournir ces informations.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>2. Données de journal</Typography>
                    <p>Nous collectons les informations que votre navigateur envoie à chaque fois que vous visitez notre réseau social évangélique ("données de journal"). Ces données de journal peuvent inclure des informations telles que l'adresse IP (Internet Protocol) de votre ordinateur, le type de navigateur, la version du navigateur, les pages de notre service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, etc. </p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>3. Cookies</Typography>
                    <p>Les cookies sont des fichiers contenant une petite quantité de données, pouvant inclure un identifiant unique et anonyme. Les cookies sont envoyés à votre navigateur à partir d'un site web et stockés sur le disque dur de votre ordinateur. </p>
                    <p>Nous utilisons des "cookies" pour collecter des informations. Vous pouvez demander à votre navigateur de refuser tous les cookies ou d'indiquer quand un cookie est envoyé. Toutefois, si vous n'acceptez pas les cookies, vous ne pourrez peut-être pas utiliser certaines parties ou services de notre réseau social évangélique.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>4. Service Providers</Typography>
                    <p>Nous pouvons faire appel à des sociétés tierces et à des particuliers pour faciliter notre service, pour fournir le service en notre nom, pour fournir des services liés au service ou pour nous aider à analyser l'utilisation de notre service. </p>
                    <p>Ces tiers n'ont accès à vos informations personnelles que pour effectuer ces tâches pour notre compte et sont tenus de ne pas les divulguer ni les utiliser à d'autres fins. </p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>5. Sécurité</Typography>
                    <p>La sécurité de vos informations personnelles est importante pour nous, mais rappelez-vous qu'aucune méthode de transmission sur Internet, ni aucune méthode de stockage électronique n'est sécurisée à 100%. Bien que nous nous efforçons d'utiliser des moyens commercialement acceptables pour protéger vos informations personnelles, nous ne pouvons pas garantir leur sécurité absolue. </p>
                    <p>Pour ce qui est de la sécurité de vos informations personnelles de paiement, nous travaillons avec MaxiCash (https://maxicash.co/fr), qui est une passerelle de paiement qui propose des services de transfert d'argent et des cartes prépayées Visa. Lors de chaque transaction de paiement, LinkEd Solution DRC ne recueille aucune information de l’utilisateur, c’est-à-dire LinkEd Solution DRC ne recueille pas le numéro de carte, la date de validité et tout autre information de paiement liées de l’utilisateur.</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>6. Liens vers d'autres sites</Typography>
                    <p>Notre réseau social évangélique peut contenir des liens vers d'autres sites que nous n'exploitons pas. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site que vous visitez. Nous n'avons aucun contrôle sur, et n'assumons aucune responsabilité pour le contenu, les politiques de confidentialité ou les pratiques de tout site ou service tiers. </p>
                    <p></p>

                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>7. Changements à cette politique de confidentialité</Typography>
                    <p>Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de tout changement en affichant la nouvelle politique de confidentialité sur cette page. Nous vous conseillons de consulter périodiquement cette politique de confidentialité pour vous tenir informé de tout changement. Les modifications apportées à cette politique de confidentialité entrent en vigueur lorsqu'elles sont publiées sur cette page. </p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5, }}>8. Contactez-nous</Typography>
                    <p>Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter.</p>

                    <ul>
                      <li>Email: <b> <a href="mailto:info@linked-solution.com ">info@linked-solution.com </a> </b></li>
                      <li>Téléphone: <b><a href="tel:+243851945396">+243851945396</a></b></li>
                      <li>Site web: <b><a href="https://www.linked-solution.com ">www.linked-solution.com</a></b> </li>
                    </ul>

                  </TabPanel>
                  <TabPanel value={value} index={0}>
                    <Typography fontWeight={"bold"} color={colors.griseApp2} variant='h3' textAlign="center" sx={{ paddingY: 1, background: colors.griseApp }}>Mentions légales</Typography>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5 }}>1. Éditeur</Typography>
                    <p>LinkEd Solution DRC SARL</p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5 }}>2. Direction et publication de la rédaction</Typography>
                    <p>Email: <a href="mailto:contact@linked-solution.com">contact@linked-solution.com</a></p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5 }}>2. Hébergement</Typography>
                    <p>Le site www.ecclesiabook.org  est hébergé par la société : Hostinger International Ltd. <br />
                      61 Lordou Vironos, Larnaca, CY, 6023 <br />
                      Tél. +62 81533832199 <br />
                    </p>
                    <Typography fontWeight={"bold"} color={"#2E75B5"} sx={{ mt: 5 }}>3. Propriété intellectuelle</Typography>
                    <p><b>LinkEd Solution DRC</b> est propriétaire de la plateforme “EcclesiaBooK”.Dans ce sens, il en détient des droits de propriété intellectuelle. A ce titre, <b>LinkEd Solution DRC</b> est propriétaire du nom de domaine <a href="https://ecclesiabook.org">www.ecclesiabook.org</a>. L'accès à la plateforme ainsi qu’à tous les services qu’elle fournit est exclusivement limité à l'usage de l'Utilisateur dans les conditions et limites définies dans les Termes et consultables dans la plateforme EcclesiaBooK.</p>
                  </TabPanel>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Loading open={loading} />
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </Grid>
  )
}

export default HelpView;
