import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { colors } from '../../config';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface Props {
  open: boolean;
  Message: () => JSX.Element;
  handleClose: () => void;
  handleConfirmAction: () => void
}
export default function AlertDialogSlide(props: Props) {
  const {open, Message, handleClose, handleConfirmAction } = props

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{
          borderRadius: '50px'
        }}
      >
       
        <DialogContent sx={{p:5}}>
            <Message/>
        </DialogContent>
        <DialogActions sx={{justifyContent:'center', pb:2}}>
          <Button
           type="button"
           size='medium'
           security='error'
           style={{
             background: colors.blueApp, color: colors.white, borderRadius: "68px", width: "200px",
             padding:'10px',
             textTransform: "none",
             marginLeft: 10
           }}
          onClick={handleConfirmAction}>Confirmer</Button>
          <Button 
           type="button"
           size='medium'
           security='error'
           style={{
             background: "#343537", color: colors.white, borderRadius: "68px", width: "200px",
             padding:'10px',
             textTransform: "none",
             marginLeft: 10
           }}
          onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}