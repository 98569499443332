import React, { useEffect, useState } from "react";
import { Box, Card, Typography, Grid, List, ListItemAvatar, ListItemText } from "@mui/material";
import Layout from '../../components/layout';
import ListItemButton from '@mui/material/ListItemButton';
import { CardDisk } from "../../components/card/card.disk";
import { colors } from "../../config";
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Btn } from '../../components/button';
import { CustomizeAudiosPlayer } from "../../components/audiosPlayer";
import { CreateFileModal, UpdateFileModal } from "../../components/modal";
import { selectAuth } from "../../services/store/auth/auth.slice";
import { useAppSelector } from "../../services/store/hooks";
import jwtDecode from "jwt-decode";
import { DeleteFile, getFile } from "../../services/api/fileApi";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import Loading from "../../components/loading";
import { fileUrl, url } from "../../services/api";
import AlertDialogSlide from "../../components/AlertDialogSlide";
import { Moment } from "moment";
import CommentList from "../../components/comment";

const AudiosScreenView = () => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalUpdateFIle, setOpenModalUpdateFile] = React.useState<boolean>(false);
  const [audios, setAudios] = React.useState([])
  const auth = useAppSelector(selectAuth);
  const user: any = jwtDecode(auth.access_token)
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alertState, setAlertState] = useState({ open: false, msg: <></> });
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [currentAudios, setCurrentAudios] = React.useState<any>({});

  const [dateLive, setDateLive] = React.useState<Moment | null>(null);
  const [titre, setTitre] = React.useState<string>("");
  const [auteur, setAuteur] = React.useState<string>("");
  const [photo, setPhoto] = React.useState<any>("");
  const [file, setFile] = React.useState<any>("");
  
  const toggleModal = () => {
    setOpenModal(!openModal)
  }
  console.log(currentAudios?.webp);

  const handleOpenStack = React.useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  }

  const handleOpenAlert = () => {
    setAlertState({
      open: true,
      msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer l'audios?</Typography>
    })
  };

  const handleCloseAlert = () => {
    setAlertState({
      open: false,
      msg: <></>
    });
  };

  const handleConfirmAction = () => {
    handleDelete()
    handleCloseAlert()
  }

  const findAudios = React.useCallback(async () => {
    setLoading(true);
    const get = await getFile(user?.eglise?.id_eglise, 'audios');
    setLoading(false);
    if (get?.status === 200 && get?.data !== undefined) {
      setAudios(get?.data);
      setCurrentAudios(get?.data[0])
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Vous avez aucune musique dans la bibliothéque'
      });
    }
  }, [handleOpenStack, user]);

  const handleDelete = async () => {
    setLoading(true);
    const deleted = await DeleteFile(currentAudios?.id, 'audios')
    setLoading(false);
    if (deleted?.status === 200) {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `L'audios est supprimer du système!`
      });
      await findAudios()
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
  }


  useEffect(() => {
    let isMount = true;
    if (isMount) {
      findAudios();
    }
    return () => {
      isMount = false
    }
  }, [])

  return <Layout>
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={5}>
            <Card sx={{ background: colors.blueApp, pt: 3, pb: 3 }}>
              <Grid container>
                <Grid item md={2}></Grid>
                <Grid item md={6}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <img src={currentAudios?.photo ? `${fileUrl}${currentAudios?.photo}` : './ecclessia.png'}
                      alt={currentAudios?.photo + currentAudios?.id}
                      style={{
                        width: 150,
                        height: 150,
                        borderRadius: '100px'
                      }} />
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                      <CommentIcon htmlColor={colors.white} /> <Typography color={colors.white} fontWeight={"bold"} mr={2}> {currentAudios?.commentaire?.length}</Typography>
                      <FavoriteIcon htmlColor={colors.redApp} /> <Typography color={colors.white} fontWeight={"bold"}> {currentAudios?.favoris?.length}</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                      <Typography variant="h6" color={colors.white} fontWeight={"bold"}>{currentAudios?.auteur}</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{ background: `rgba(0, 0, 0, 0.4)`, width: "100%" }}>
                      <Typography
                        variant="h6"
                        textAlign={"center"}
                        color={colors.white}
                        fontWeight={"bold"}
                      >{currentAudios?.titre}</Typography>
                    </Box>
                    {currentAudios?.id ?
                      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Box><Btn action={() => {
                          setTitre(currentAudios?.titre);
                          setAuteur(currentAudios?.auteur);
                          setOpenModalUpdateFile(true)
                        }} width={"auto"} margin={"5px"} color={colors.griseApp2}>Modifier</Btn></Box>
                        <Btn action={() => { handleOpenAlert() }} width={"auto"} color={colors.redApp}>Supprimer</Btn>
                      </Box>
                      : null}
                  </Box>
                </Grid>
                <Grid item md={4}></Grid>
                <Grid item md={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CustomizeAudiosPlayer allAudios={audios} audios={currentAudios?.id} />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item md={7} display={{xs:"none", md: "grid"}}>
            <CommentList commentaires={currentAudios?.commentaire ? currentAudios?.commentaire : []} />
          </Grid>
          <Grid item md={12}>
            <Btn action={() => toggleModal()} color={colors.blueApp}>Ajouter un audio</Btn>
          </Grid>
        </Grid>
        <Box mt={1}>
          <Typography variant="h6" textAlign={"center"}><b>Audios</b></Typography>
          <Grid container spacing={2}>
            {audios.length > 0 ? audios?.map((item: any, i: number) => {
              return (
                <Grid key={i} item md={3} xs={6}>
                  <CardDisk action={() => { setCurrentAudios(item) }} webp={item?.photo ? `${fileUrl}${item?.photo}` : "./ecclessia.png"} titre={item?.titre} auteur={item?.auteur} />
                </Grid>
              )
            }) : <Grid item md={12} xs={12}>
              <Typography variant="h6" textAlign={"center"} color={colors.griseApp}>Vous n'avez aucun audios dans la bibliothéque</Typography>
            </Grid>
            }
          </Grid>
        </Box>
        <CreateFileModal open={openModal} handleClose={toggleModal} handlCallbackReload={findAudios} />
        <UpdateFileModal open={openModalUpdateFIle} handlCallbackReload={findAudios} handleClose={() => { setOpenModalUpdateFile(false) }}
          data={{
            photo, setPhoto,
            file, setFile,
            dateLive,
            setDateLive,
            titre, setTitre,
            auteur, setAuteur,
            iframe: "",
            setIframe: () => { },
            acceptFile: "audio/*",
            idFile: currentAudios?.id
          }}
        />
        <Loading open={loading} />
        <SnackbarAlert
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          type={snackState.type}
          message={snackState.message}
        />
        <AlertDialogSlide
          open={alertState.open}
          Message={() => alertState.msg}
          handleClose={handleCloseAlert}
          handleConfirmAction={() => handleConfirmAction()}
        />
      </Box>
    </>
  </Layout >
}

export default AudiosScreenView
