import React, { useState } from 'react';
import { Box, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { colors, getSize, PrivilegesEnum } from '../../config';
import { Btn } from '../../components/button';
import SnackbarAlert, { PropsSnack } from '../../components/snackbarAlert';
import Loading from '../../components/loading';
import { InputApp } from '../../components/input';
import { useNavigate } from 'react-router-dom';
import { AuthLogin, AuthLoginToPay } from '../../services/api';
import { useAppDispatch, useAppSelector } from '../../services/store/hooks';
import { loginUser, selectAuth } from '../../services/store/auth/auth.slice';
import jwtDecode from 'jwt-decode';
import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';

const AuthConnexionView = () => {
  const screen = getSize();
  const [telephone, setTel] = useState<string>("");
  const [password, setPwd] = useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth)
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  // const user: any = jwtDecode(auth?.access_token);

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000)
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  }

  const handleSubmit = async () => {
    if (telephone !== "" && password !== "") {
      setLoading(true);
      const create = await AuthLogin({
        telephone,
        password,
      });

      console.log(create);
      if (create?.status === 201 && create?.statusText === "Created") {
        dispatch(loginUser({
          access_token: create.data?.access_token,
          refresh_token: create.data?.refresh_token,
          isAuthenticated: true
        }));
        setLoading(false);
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: `la connexion se bien passé!`
        });
        const access_token: any = jwtDecode(create.data?.access_token);
        console.log(access_token);

        if (access_token?.privilege_user === PrivilegesEnum.ADMIN) {
          navigation('/EgliseView')
        } else if (access_token?.privilege_user === PrivilegesEnum.ADMIN_EGLISE) {
          if (access_token?.eglise !== null) {
            navigation('/HomeDashBoardView')
          } else {
            handleOpenStack({
              open: true,
              vertical: 'top',
              horizontal: 'center',
              type: 'warning',
              message: `Vous n'avez pas d'église; Contactez EcclesiaBook!`
            });
          }
        }
      } else {
        if (create?.data.message === `Vous n'avez pas encore payer votre abonnement!`) {
          const login = await AuthLoginToPay({ telephone });
          setLoading(false);
          console.log(login);
          if (login?.data) {
            dispatch(loginUser({
              access_token: login?.data?.tokens?.access_token,
              refresh_token: login?.data?.tokens?.refresh_token,
              isAuthenticated: true
            }));
            navigation("/AuthPayementView");
          } else {
            handleOpenStack({
              open: true,
              vertical: 'top',
              horizontal: 'center',
              type: 'error',
              message: login?.data.message
            })
          }
        } else {
          setLoading(false);
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'error',
            message: create?.data.message
          });
        }
      }
    } else {
      setLoading(false);
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: ' les champs sont obligatoires!'
      });
    }
  }

  return (
    <Grid container style={{ position: 'absolute', top: 0, bottom: 0, height: "100%", }} className='gradient_background'>
      <Grid item md={6} xs={12}>
        <Stack
          sx={{ height: "100%" }}
          alignItems={"center"}
          justifyContent={'center'}
          alignContent={'center'}
        >
          <Stack
            sx={{ display: { xs: "flex", md: 'none' } }}
            alignItems={"center"}
            justifyContent={'center'}
            alignContent={'center'}>
            <img
              src='./ecclessia.png'
              alt="ecclessia"
              width={80}
            />
            <Typography fontWeight={"bold"} color={colors.white}>EcclesiaBook</Typography>
          </Stack>
          <Typography fontWeight={"bold"} variant="h2" color={colors.white} mb={1}>Connexion</Typography>
          <Grid container rowSpacing={4} mb={2}>
            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp }
                }}
                fullWidth
                type="tel"
                id="tel"
                name="tel"
                label="Numéro de  téléphone"
                variant="outlined"
                value={telephone}
                onChange={(e) => setTel(e.target.value)}
                placeholder="ex: +243......"
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>

            <Grid item md={3} xs={2}></Grid>
            <Grid item md={6} xs={8}>
              <InputApp
                sx={{
                  input: {
                    color: colors.white,
                    '&::placeholder': {
                      opacity: 1,
                    }
                  },
                  label: { color: colors.griseApp }
                }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff htmlColor='#ffffff' /> : <Visibility htmlColor='#ffffff' />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                id="pwsd"
                name="pwsd"
                label="Mot de passe"
                variant="outlined"
                value={password}
                onChange={(e) => setPwd(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={2}></Grid>
          </Grid>

          <Btn action={handleSubmit} color={colors.redApp}>
            <Typography color={colors.white}>
              Connectez-vous
            </Typography>
          </Btn>
          <Btn action={() => { navigation('/AuthCreateAdminView') }} color={colors.white} margin={'20px'}>
            <Typography color={colors.blueApp}>
              Inscription
            </Typography>
          </Btn>
          {/* <Typography sx={{ m: 3, textAlign: "center", display: { xs: "flex", md: 'none' } }} color={colors.white}>
            Plateforme d'évangelisation permettant la diffusion de la parole de Dieu sur l'étendue de la terre et une très bonne structure des églises.
          </Typography> */}

          <Box display={"flex"} position={"absolute"} bottom={0}>
            <Grid container item md={12}>
              <Grid item md={12} xs={12}>
                <Typography style={{ color: colors.griseApp, textTransform: "none", textAlign: "center" }}>
                  <a style={{ color: colors.griseApp, textDecoration: 'none', textTransform: "none", textAlign: "center" }}
                    href='#' onClick={() => { navigation('/HelpView') }}> Mentions légales | Politique de confidentialité | Termes et Conditions d'utilisation. </a></Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography sx={{ textAlign: "center" }} color={colors.white}>Copyright &#169; 2023 Tous droits réservés.</Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>
      <Grid item md={6} display={{ xs: 'none', md: "flex" }}>
        <Stack
          sx={{ height: "100%" }}
          alignItems={"center"}
          justifyContent={'center'}
          alignContent={'center'}>
          <img
            src='./ecclessia.png'
            alt="ecclessia"
            width={200}
          />
          <Typography fontWeight={"bold"} color={colors.white}>EcclesiaBook</Typography>
          <Typography fontWeight={"100"} sx={{ m: 3, textAlign: "center", color: colors.white }}>
            Plateforme d'évangelisation permettant la diffusion de la parole de Dieu sur l'étendue de la terre et une très bonne structure des églises.
          </Typography>
        </Stack>
      </Grid>
      <Loading open={loading} />
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </Grid>
  )
}

export default AuthConnexionView;
