import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Grid, TextField, Radio, Autocomplete, createFilterOptions } from '@mui/material';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import { colors, PrivilegesEnum } from '../../config';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { Moment } from 'moment';
import 'moment/locale/fr';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Btn } from '../button';
import Loading from '../loading';
import SnackbarAlert, { PropsSnack } from '../snackbarAlert';
import { createFile } from '../../services/api';
import { CreateWebp, UpdateFile } from '../../services/api/fileApi';
import { useAppSelector } from '../../services/store/hooks';
import jwtDecode from 'jwt-decode';
import { selectAuth } from '../../services/store/auth/auth.slice';
import { CreateAnnonce, createCommuniqueApi, CreateDayForAppointment, CreateMembres, CreateProgramme, createRendezvous, CreateSousProgramme, DeleteAppointment, PostponeAppointment, UpdateSousProgramme } from '../../services/api/churcheApi';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers';
import CommentList from '../comment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export const BootstrapDialog: any = styled(Dialog)(({ theme }: any) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export interface Props {
  open: boolean;
  handleClose: () => void;
  handlCallbackReload?: () => void
  data?: any
};

export function CreateFileModal(props: Props) {
  const { open, handleClose, handlCallbackReload } = props
  const [photo, setPhoto] = React.useState<any>("");
  const [file, setFile] = React.useState<any>("");
  const [interne, setInterne] = React.useState<string>("1");
  const [acceptFile, setAcceptFile] = React.useState<string>("audio/*");
  const [dateLive, setDateLive] = React.useState<Moment | null>(null);
  const [titre, setTitre] = React.useState<string>("");
  const [auteur, setAuteur] = React.useState<string>("");
  const [iframe, setIframe] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleChange = (event: React.ChangeEvent, file = '') => {
    const { files } = event.target as HTMLInputElement;
    if (files && files.length !== 0) {
      if (file === "file") {
        console.log(files);
        
        setFile(files[0])
      } else {
        console.log(files[0]);
        setPhoto(files[0])
      }
    }
  };

  const handleChangeTypeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptFile(event.target.value);
  };

  const controlProps = (item: string) => ({
    checked: acceptFile === item,
    onChange: handleChangeTypeFile,
    value: item,
    name: 'size-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  const sendFile = async (route: string, data: any) => {
    setLoading(true);
    const create = await createFile(route, data);
    setLoading(false);
    console.log(create);

    if (create?.status === 201 && create?.statusText === "Created") {
      const form = new FormData();
      form.append("image", photo)
      const createWebp = await CreateWebp(form, route, create?.data?.id);
      if (createWebp?.status === 200 && createWebp?.statusText === "OK") {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: 'Le contenu est publié correctement!'
        });
        handleClose()
        if (handlCallbackReload) {
          handlCallbackReload()
        }
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'warning',
          message: `Le contenu est publié correctement mais la miniaturisation a échouée!`
        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Les champs sont obligatoires.'
      });
    }
  }

  const handleSubmit = async () => {
    console.log(acceptFile);

    const formData = new FormData();
    if (acceptFile === "audio/*" || acceptFile === "video/*" || acceptFile === ".pdf") {
      if (titre !== "" && auteur !== "" && photo !== "") {
        formData.append("titre", titre);
        formData.append("auteur", auteur);
        formData.append("image", photo);
        if (acceptFile === "video/*") {
          if (interne && file) {
            formData.append("source", "1");
            formData.append("videos", file);
            await sendFile("videos", formData);
          } else if (iframe) {
            
            formData.append("source", "0");
            formData.append("iframe", iframe);
            // formData.append("videos", "");.

            await sendFile("videos", formData);
          } else {
            handleOpenStack({
              open: true,
              vertical: 'top',
              horizontal: 'center',
              type: 'error',
              message: `Le contenue de la vidéo est obligatoire!`
            });
          }
        } else {
          if (file) {
            formData.append(acceptFile === "audio/*" ? "audios" : "livres", file);
            await sendFile(acceptFile === "audio/*" ? "audios" : "livres", formData)
          } else {
            handleOpenStack({
              open: true,
              vertical: 'top',
              horizontal: 'center',
              type: 'error',
              message: 'Le fichier est obligatoire!'
            });
          }
        }
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: 'Les champs sont obligatoires.'
        });
      }
    } else if (acceptFile === "live") {
      if (titre !== "" && photo !== "" && iframe !== "") {
        formData.append("titre", titre);
        formData.append("date", `${dateLive !== null ? dateLive?.toString() : ""}`);
        formData.append("img", photo);
        formData.append("lien", iframe);
        await sendFile("live", formData)
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: 'Les champs sont obligatoires.'
        });
      }
    }
    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Mise en ligne du contenu
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom textAlign={"center"}><b>Type de contenu</b></Typography>
          <Grid container spacing={2}>
            <Grid item md={12} container>
              <Grid item md={3}>
                <Box display={'flex'} alignItems={'center'}>
                  <Radio
                    {...controlProps('audio/*')}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 40,
                      },
                    }}
                  />
                  <LibraryMusicIcon />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box display={'flex'} alignItems={'center'}>
                  <Radio
                    {...controlProps('video/*')}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 40,
                      },
                    }}
                  />
                  <VideoLibraryIcon />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box display={'flex'} alignItems={'center'}>
                  <Radio
                    {...controlProps('.pdf')}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 40,
                      },
                    }}
                  />
                  <LibraryBooksIcon />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box display={'flex'} alignItems={'center'}>
                  <Radio
                    {...controlProps('live')}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 40,
                      },
                    }}
                  />
                  <LiveTvIcon />
                </Box>
              </Grid>
            </Grid>
            <Grid item md={acceptFile !== "live" ? 6 : 12}>
              <TextField
                fullWidth
                id="titre"
                name="titre"
                label="TITRE"
                variant="outlined"
                value={titre}
                onChange={(e) => setTitre(e.target.value)}
              />
            </Grid>
            {acceptFile !== "live" ?
              <Grid item md={6}>
                <TextField
                  fullWidth
                  id="auteur"
                  name="auteur"
                  label="AUTEUR"
                  variant="outlined"
                  value={auteur}
                  onChange={(e) => setAuteur(e.target.value)}
                />
              </Grid> : null
            }
            <Grid item md={6}>
              <Typography gutterBottom><b>Image de garde</b></Typography>
              <IconButton style={{ color: colors.white, background: photo !== "" ? colors.blueApp : colors.griseApp2, marginRight: 15 }} aria-label="upload picture" component="label">
                <input hidden accept="image/*" type="file" onChange={(e) => handleChange(e)} />
                <InstallDesktopIcon />
              </IconButton>
            </Grid>
            <Grid item md={6}>
              {(acceptFile !== "live" && acceptFile !== "video/*") ? <>
                <Typography gutterBottom><b>Import le fichier</b></Typography>
                <IconButton style={{ color: colors.white, background: file !== "" ? colors.blueApp : colors.griseApp2, marginRight: 15 }} aria-label="upload picture" component="label">
                  <input hidden accept={acceptFile} type="file" onChange={(e) => handleChange(e, 'file')} />
                  <InstallDesktopIcon />
                </IconButton></>
                : acceptFile == "video/*" ? <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Source</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={interne}
                    label="Source"
                    onChange={(e) => { setInterne(e.target.value) }}
                  >
                    <MenuItem value={1}>Importer la vidéo</MenuItem>
                    <MenuItem value={0}>Iframe</MenuItem>
                  </Select>
                </FormControl> :
                  <TextField
                    fullWidth
                    id="ifram"
                    name="ifram"
                    label="IFRAME CODE"
                    variant="outlined"
                    value={iframe}
                    onChange={(e) => setIframe(e.target.value)}
                  />
              }
            </Grid>
            {(acceptFile == "video/*" && interne) ? <Grid item md={12}>
              <Typography gutterBottom><b>Import le fichier</b></Typography>
              <IconButton style={{ color: colors.white, background: file !== "" ? colors.blueApp : colors.griseApp2, marginRight: 15 }} aria-label="upload picture" component="label">
                <input hidden accept={acceptFile} type="file" onChange={(e) => handleChange(e, 'file')} />
                <InstallDesktopIcon />
              </IconButton>
            </Grid> : acceptFile == "video/*" ? <TextField
              fullWidth
              id="ifram"
              name="ifram"
              label="IFRAME CODE"
              variant="outlined"
              value={iframe}
              onChange={(e) => setIframe(e.target.value)}
            /> : null

            }

            {acceptFile === "live" ? <Grid item md={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="DATE DU LIVE"
                  value={dateLive}
                  onChange={(newValue) => {
                    setDateLive(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid> : null}
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Btn action={() => { handleSubmit() }} color={colors.blueApp}>Enregistrer le contenu</Btn>
            </Grid>
            <Loading open={loading} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>

      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );
}

export function UpdateFileModal(props: Props) {
  const { open, handleClose, handlCallbackReload, data } = props;
  const { acceptFile, idFile } = data
  const { photo, setPhoto } = data
  const { file, setFile } = data
  const { dateLive, setDateLive } = data;
  const { titre, setTitre } = data
  const { auteur, setAuteur } = data
  const { iframe, setIframe } = data

  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleChange = (event: React.ChangeEvent, file = '') => {
    const { files } = event.target as HTMLInputElement;
    if (files && files.length !== 0) {
      if (file === "file") {
        setFile(files[0])
      } else {
        setPhoto(files[0])
      }
    }
  };

  // const handleChangeTypeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setAcceptFile(event.target.value);
  // };

  const controlProps = (item: string) => ({
    checked: acceptFile === item,
    // onChange: handleChangeTypeFile,
    value: item,
    name: 'size-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  const updateFile = async (route: string, data: any) => {
    setLoading(true);
    console.log(data);

    const create = await UpdateFile(route, idFile, data);
    setLoading(false);
    console.log(create);

    if (create?.status === 200 && create?.statusText === "OK") {
      if (photo) {
        const form = new FormData();
        form.append("image", photo)
        const createWebp = await CreateWebp(form, route === "files/audios" ? "audios" : route === "files/videos" ? "videos" : route === "live" ? "live" : "livres", idFile);
        if (createWebp?.status === 200 && createWebp?.statusText === "OK") {
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'success',
            message: 'Le contenu est modifier correctement!'
          });

        } else {
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'warning',
            message: `Le contenu est publié correctement mais la miniaturisation a échouée!`
          });
        }
      }
      if (handlCallbackReload) handlCallbackReload()
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Une erreur est survenue lors du traitement'
      });
    }
  }

  const handleSubmit = async () => {
    const formData = new FormData();
    if (acceptFile === "audio/*" || acceptFile === "video/*" || acceptFile === ".pdf") {
      if (titre !== "" && auteur !== "") {
        formData.append("titre", titre);
        formData.append("auteur", auteur);
        if (photo !== "") {
          formData.append("stream", photo);
        }
        if (acceptFile === "video/*") {
          if (iframe !== "") {
            formData.append("fileContent", iframe);
            await updateFile("files/videos", formData)
          } else {
            handleOpenStack({
              open: true,
              vertical: 'top',
              horizontal: 'center',
              type: 'error',
              message: `Le conde d'integration de la vidéo est obligatoire!`
            });
          }
        } else {
          if (file !== "") {
            formData.append("stream", file);
          }
          await updateFile(acceptFile === "audio/*" ? "files/audios" : acceptFile === "video/*" ? "files/videos" : "files/livres", formData)
          // } else {
          //   handleOpenStack({
          //     open: true,
          //     vertical: 'top',
          //     horizontal: 'center',
          //     type: 'error',
          //     message: 'Le fichier est obligatoire!'
          //   });
          // }
        }
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: 'Les champs sont obligatoires.'
        });
      }
    } else if (acceptFile === "live") {
      if (titre !== "" && iframe !== "") {
        formData.append("titre", titre);
        formData.append("date", `${dateLive !== null ? dateLive?.toString() : ""}`);
        if (photo !== "") {
          formData.append("img", photo);
        }

        formData.append("lien", iframe);
        console.log(formData);

        await updateFile("live", formData)
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: 'Les champs sont obligatoires.'
        });
      }
    }
    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Modification du contenu
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom textAlign={"center"}><b>Type de contenu</b></Typography>
          <Grid container spacing={2}>
            <Grid item md={12} container>
              <Grid item md={3}>
                <Box display={'flex'} alignItems={'center'}>
                  <Radio
                    {...controlProps('audio/*')}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 40,
                      },
                    }}
                  />
                  <LibraryMusicIcon />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box display={'flex'} alignItems={'center'}>
                  <Radio
                    {...controlProps('video/*')}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 40,
                      },
                    }}
                  />
                  <VideoLibraryIcon />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box display={'flex'} alignItems={'center'}>
                  <Radio
                    {...controlProps('.pdf')}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 40,
                      },
                    }}
                  />
                  <LibraryBooksIcon />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box display={'flex'} alignItems={'center'}>
                  <Radio
                    {...controlProps('live')}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 40,
                      },
                    }}
                  />
                  <LiveTvIcon />
                </Box>
              </Grid>
            </Grid>
            <Grid item md={acceptFile !== "live" ? 6 : 12}>
              <TextField
                fullWidth
                id="titre"
                name="titre"
                label="TITRE"
                variant="outlined"
                value={titre}
                onChange={(e) => setTitre(e.target.value)}
              />
            </Grid>
            {(acceptFile !== "live") ?
              <Grid item md={6}>
                <TextField
                  fullWidth
                  id="auteur"
                  name="auteur"
                  label="AUTEUR"
                  variant="outlined"
                  value={auteur}
                  onChange={(e) => setAuteur(e.target.value)}
                />
              </Grid> : null
            }
            <Grid item md={6}>
              <Typography gutterBottom><b>Image de garde</b></Typography>
              <IconButton style={{ color: colors.white, background: photo !== "" ? colors.blueApp : colors.griseApp2, marginRight: 15 }} aria-label="upload picture" component="label">
                <input hidden accept="image/*" type="file" onChange={(e) => handleChange(e)} />
                <InstallDesktopIcon />
              </IconButton>
            </Grid>
            <Grid item md={6}>
              {(acceptFile !== "live" && acceptFile !== "video/*") ? <>
                <Typography gutterBottom><b>Import le fichier</b></Typography>
                <IconButton style={{ color: colors.white, background: file !== "" ? colors.blueApp : colors.griseApp2, marginRight: 15 }} aria-label="upload picture" component="label">
                  <input hidden accept={acceptFile} type="file" onChange={(e) => handleChange(e, 'file')} />
                  <InstallDesktopIcon />
                </IconButton></>
                : <TextField
                  fullWidth
                  id="ifram"
                  name="ifram"
                  label="IFRAME CODE"
                  variant="outlined"
                  value={iframe}
                  onChange={(e) => setIframe(e.target.value)}
                />}
            </Grid>
            {acceptFile === "live" ? <Grid item md={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="DATE DU LIVE"
                  value={dateLive}
                  onChange={(newValue) => {
                    setDateLive(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid> : null}
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Btn action={() => { handleSubmit() }} color={colors.blueApp}>Enregistrer le contenu</Btn>
            </Grid>
            <Loading open={loading} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>

      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );
}

export const CreateUserModale = (props: Props) => {
  const { open, handleClose, handlCallbackReload } = props
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [nom, setNom] = React.useState<any>("");
  const [prenom, setPrenom] = React.useState<any>("");
  const [email, setEmail] = React.useState<any>("");
  const [telephone, setTel] = React.useState<any>("");
  const [adresse, setAdresse] = React.useState<any>("");
  const [ville, setVille] = React.useState<any>("");
  const [pays, setPays] = React.useState<any>("");
  const auth = useAppSelector(selectAuth);
  const user: any = jwtDecode(auth.access_token);

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleSubmit = async () => {
    if (nom !== "" && prenom !== "" && telephone !== "") {
      setLoading(true);
      const create = await CreateMembres({ nom, prenom, email, telephone, ville, adresse, pays, fk_eglise: user?.eglise?.id_eglise });
      setLoading(false);
      if (create?.status === 200) {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: "Membre Correctement enregistré."
        });
        if (handlCallbackReload) handlCallbackReload()
        handleClose();
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: create?.data?.message
        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Les champs sont obligatoires.'
      });
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Enregistre le membre
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField
                fullWidth
                id="nom"
                name="nom"
                label="Nom"
                variant="outlined"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                id="prenom"
                name="prenom"
                label="Prenom"
                variant="outlined"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                id="telephone"
                name="telephone"
                label="téléphone"
                variant="outlined"
                value={telephone}
                onChange={(e) => setTel(e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                id="adresse"
                name="adresse"
                label="Adresse"
                variant="outlined"
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                id="ville"
                name="ville"
                label="Ville"
                variant="outlined"
                value={ville}
                onChange={(e) => setVille(e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                id="pays"
                name="pays"
                label="Pays"
                variant="outlined"
                value={pays}
                onChange={(e) => setPays(e.target.value)}
              />
            </Grid>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Btn action={() => { handleSubmit() }} color={colors.blueApp}>Enregistrer le membres</Btn>
            </Grid>
            <Loading open={loading} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );
}

export const CreateAnnonceModale = (props: Props) => {
  const { open, handleClose, handlCallbackReload, data } = props;
  const filter = createFilterOptions<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [file, setFile] = React.useState<any>("");
  // const [Alleglise, setAllEglise] = React.useState([]);
  const auth = useAppSelector(selectAuth);
  const [eglise, setEglise] = React.useState<any>("");
  const [user, setUser] = React.useState<any>(jwtDecode(auth?.access_token))

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleChange = (event: React.ChangeEvent) => {
    const { files } = event.target as HTMLInputElement;
    if (files && files.length !== 0) {
      setFile(files[0])
    }
  };

  const handleSubmit = async () => {
    if (file !== "") {
      const formData = new FormData();
      formData.append("contente", file);
      setLoading(true);
      const create = await CreateAnnonce(formData, user?.privilege_user === PrivilegesEnum.ADMIN ? eglise?.id : user?.eglise?.id_eglise);
      setLoading(false);
      if (create?.status === 201 && create?.statusText === "Created") {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: "Votre annonce a été publier!"
        });
        if (handlCallbackReload) handlCallbackReload()
        handleClose();
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: "Une erreur est survenue lors du traitement de la demande!"
        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: "Un fichier est obligatior!"
      });
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Annonces
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>

            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Typography gutterBottom textAlign={"center"} ><b>Contenu de l'annonce</b></Typography>
            </Grid>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton style={{ color: colors.white, background: file !== "" ? colors.blueApp : colors.griseApp2, marginRight: 15 }} aria-label="upload picture" component="label">
                <input hidden accept="video/*, image/*" type="file" onChange={(e) => handleChange(e)} />
                <InstallDesktopIcon />
              </IconButton>
            </Grid>
            {user?.privilege_user === PrivilegesEnum.ADMIN ?
              <Grid item md={12}>
                <Autocomplete
                  value={eglise}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {

                      if (typeof newValue === 'string') {
                        console.log('if', newValue);
                        setEglise({
                          nom_eglise: newValue
                        });
                      } else if (newValue && newValue.inputValue) {
                        console.log('else if', newValue.id_eglise);
                        setEglise({
                          nom_eglise: newValue.nom_eglise,
                          id: newValue.id_eglise
                        });
                      } else {
                        console.log('else', newValue);
                        setEglise({
                          nom_eglise: newValue.nom_eglise,
                          id: newValue.id_eglise
                        });
                      }
                      // console.log('onchange', newValue);
                      // console.log(eglise)
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option.nom_eglise);
                    return filtered;
                  }}
                  getOptionLabel={(option) => {

                    if (typeof option === 'string') {
                      console.log("if", option);

                      return option;
                    }
                    if (option.inputValue) {
                      console.log("ifif", option);
                      return option.inputValue;
                    }
                    return option.nom_eglise;
                  }}
                  id="entrepriseState"
                  renderOption={(props, option) => <li {...props}>{option.nom_eglise}</li>}
                  options={data}
                  fullWidth
                  renderInput={(params) => <TextField {...params} name="Eglise" label="Eglise *" />}
                />
              </Grid> : null
            }


            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Btn action={() => { handleSubmit() }} color={colors.blueApp}>Enregistrer</Btn>
            </Grid>
          </Grid>
          <Loading open={loading} />
        </DialogContent>
      </BootstrapDialog>
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );

}

export const CreateProgrammeModale = (props: Props) => {
  const { open, handleClose, handlCallbackReload } = props
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [titre, setTitre] = React.useState<string>("");

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleSubmit = async () => {
    if (titre !== "") {
      setLoading(true);
      const create = await CreateProgramme({ titre });
      setLoading(false);
      if (create?.status === 201 && create?.statusText === "Created") {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: "Votre programme a été publié."
        });
        if (handlCallbackReload) handlCallbackReload()
        handleClose();
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: create?.data?.message
        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: " les champs sont obligatoires!"
      });
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Programme
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Typography gutterBottom textAlign={"center"} ><b>Contenu du programme</b></Typography>
            </Grid>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                fullWidth
                select
                id={"titre"}
                name={"titre"}
                label={"Jour de la semaine"}
                variant={"outlined"}
                value={titre}
                onChange={(e) => setTitre(e.target.value)}
              >
                <MenuItem value={""}></MenuItem>
                <MenuItem value={"dimanche"}>Dimanche</MenuItem>
                <MenuItem value={"lundi"}>Lundi</MenuItem>
                <MenuItem value={"mardi"}>Mardi</MenuItem>
                <MenuItem value={"mercredi"}>Mercredi</MenuItem>
                <MenuItem value={"jeudi"}>Jeudi</MenuItem>
                <MenuItem value={"vendredi"}>Vendredi</MenuItem>
                <MenuItem value={"samedi"}>samedi</MenuItem>
              </TextField>
            </Grid>

            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Btn action={() => { handleSubmit() }} color={colors.blueApp}>Enregistrer le Programme</Btn>
            </Grid>
            <Loading open={loading} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>

      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );
}

export const CreateSousProgrammeModale = (props: Props) => {
  const { open, handleClose, handlCallbackReload, data } = props;

  const [libele, setLibele] = React.useState<string>("");
  const [startTime, setStartTime] = React.useState<Moment | null>(null);
  const [endTime, setEndTime] = React.useState<Moment | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleSubmit = async () => {
    if (libele !== "" && startTime !== null && endTime !== null) {
      setLoading(true);
      const create = await CreateSousProgramme({
        "debut": startTime.toString(),
        "fin": endTime.toString(),
        "libelle": libele
      }, data);
      setLoading(false);
      if (create?.status === 201 && create?.statusText === "Created") {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: "Votre programme a été publié."
        });
        if (handlCallbackReload) {
          handlCallbackReload()
        }
        handleClose();
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: create?.data?.message
        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: "Les champs sont obligatoires."
      });
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Horaire
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Typography gutterBottom textAlign={"center"} ><b>contenu de l'horaire</b></Typography>
            </Grid>
            <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  ampmInClock
                  views={['hours', 'minutes', 'seconds']}
                  inputFormat="H:mm:ss"
                  mask="__:__:__"
                  label="heure du début"
                  value={startTime}
                  onChange={(newValue) => {
                    setStartTime(newValue);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  ampmInClock
                  views={['hours', 'minutes', 'seconds']}
                  inputFormat="H:mm:ss"
                  mask="__:__:__"
                  label="heure de fin"
                  value={endTime}
                  onChange={(newValue) => {
                    setEndTime(newValue);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                fullWidth
                id='libelle'
                name='libelle'
                label='Évenement'
                variant='outlined'
                value={libele}
                onChange={(e) => setLibele(e.target.value)}
              />
            </Grid>

            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Btn action={() => { handleSubmit() }} color={colors.blueApp}>Enregistrer l'horaire</Btn>
            </Grid>
          </Grid>
          <Loading open={loading} />
        </DialogContent>
      </BootstrapDialog>

      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );
}

export const AddDayAppointmentModale = (props: Props) => {
  const { open, handleClose, handlCallbackReload, data } = props;

  const [day, setDay] = React.useState<string>("");
  const [startTime, setStartTime] = React.useState<Moment | null>(null);
  const [endTime, setEndTime] = React.useState<Moment | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [limite, setLimite] = React.useState<number>(0)
  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleSubmit = async () => {
    if (day !== "" && startTime !== null && endTime !== null && limite > 0) {
      setLoading(true);
      const create = await CreateDayForAppointment({
        startTime: startTime.toString(),
        endTime: endTime.toString(),
        jour: day,
        limite: limite,
      });
      setLoading(false);

      if (create?.status === 201 && create?.statusText === "Created") {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: "Le jour du rendez-vous a été publié."
        });
        if (handlCallbackReload) {
          handlCallbackReload()
        }
        handleClose();
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: create?.data?.message

        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: " les champs sont obligatoires!"
      });
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Rendez-vous
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  ampmInClock
                  views={['hours', 'minutes', 'seconds']}
                  inputFormat="H:mm:ss"
                  mask="__:__:__"
                  label="heure du début"
                  value={startTime}
                  onChange={(newValue) => {
                    setStartTime(newValue);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  ampmInClock
                  views={['hours', 'minutes', 'seconds']}
                  inputFormat="H:mm:ss"
                  mask="__:__:__"
                  label="heure de fin"
                  value={endTime}
                  onChange={(newValue) => {
                    setEndTime(newValue);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                fullWidth
                select
                id='Jour'
                name='Jour'
                label='Jours'
                variant='outlined'
                value={day}
                onChange={(e) => setDay(e.target.value)}
              >
                <MenuItem value={""}></MenuItem>
                <MenuItem value={"dimanche"}>Dimanche</MenuItem>
                <MenuItem value={"lundi"}>Lundi</MenuItem>
                <MenuItem value={"mardi"}>Mardi</MenuItem>
                <MenuItem value={"mercredi"}>Mercredi</MenuItem>
                <MenuItem value={"jeudi"}>Jeudi</MenuItem>
                <MenuItem value={"vendredi"}>Vendredi</MenuItem>
                <MenuItem value={"samedi"}>samedi</MenuItem>
              </TextField>

            </Grid>
            <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                fullWidth
                type={'number'}
                id='Jour'
                name='Jour'
                label='Limite'
                variant='outlined'
                value={limite}
                onChange={(e) => setLimite(+e.target.value)}
              />
            </Grid>

            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Btn action={() => { handleSubmit() }} color={colors.blueApp}>Enregistrer</Btn>
            </Grid>
            <Loading open={loading} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );
}

export const AddCommuquerModale = (props: Props) => {
  const { open, handleClose, handlCallbackReload, data } = props;

  const [communique, setCommunique] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleSubmit = async () => {
    if (communique !== "") {
      setLoading(true);
      const create = await createCommuniqueApi({ communique });
      setLoading(false);

      if (create?.status === 201 && create?.statusText === "Created") {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: "Votre communique a été publié."
        });
        if (handlCallbackReload) {
          handlCallbackReload()
        }
        handleClose();
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: create?.data?.message

        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: " les champs sont obligatoires!"
      });
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Communique
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Communique"
                multiline
                rows={4}
                defaultValue="Communique"
                value={communique}
                onChange={(e) => setCommunique(e.target.value)}
              />
            </Grid>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Btn action={() => { handleSubmit() }} color={colors.blueApp}>Enregistrer</Btn>
            </Grid>
            <Loading open={loading} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );
}


export const UpdateSousProgrammeModale = (props: Props) => {
  const { open, handleClose, handlCallbackReload, data } = props;
  const { currentSousProgramme, libele, startTime, endTime, setStartTime, setEndTime, setLibele } = data
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleSubmit = async () => {
    if (libele !== "" && startTime !== null && endTime !== null) {
      setLoading(true);
      const create = await UpdateSousProgramme({
        "debut": startTime.toString(),
        "fin": endTime.toString(),
        "libelle": libele
      }, currentSousProgramme?.id);
      setLoading(false);
      if (create?.status === 200 && create?.statusText === "OK") {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: "Votre programme a été publié."
        });
        if (handlCallbackReload) {
          handlCallbackReload()
        }
        handleClose();
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: create?.data?.message
        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: " les champs sont obligatoires!"
      });
    }
  }

  React.useEffect(() => {
    let isMount = true;
    if (isMount) { }
    return () => {
      isMount = false;
    }
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Horaire
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Typography gutterBottom textAlign={"center"} ><b>Modifier le programme de la journée.</b></Typography>
            </Grid>
            <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  ampmInClock
                  views={['hours', 'minutes', 'seconds']}

                  inputFormat="H:mm:ss"
                  mask="__:__:__"
                  label="heure du début"
                  value={startTime}
                  onChange={(newValue) => {
                    setStartTime(newValue);
                  }}
                  renderInput={(params) => <TextField defaultValue={data?.debut} fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  ampmInClock
                  views={['hours', 'minutes', 'seconds']}
                  inputFormat="H:mm:ss"
                  mask="__:__:__"
                  label="heure de fin"
                  value={endTime}
                  onChange={(newValue) => {
                    setEndTime(newValue);
                  }}
                  renderInput={(params) => <TextField defaultValue={data?.fin} fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                fullWidth
                id='libelle'
                name='libelle'
                label='Évenement'
                variant='outlined'
                defaultValue={data?.libelle}
                value={libele}
                onChange={(e) => setLibele(e.target.value)}
              />
            </Grid>

            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Btn action={() => { handleSubmit() }} color={colors.blueApp}>Enregistrer le Programme</Btn>
            </Grid>
            <Loading open={loading} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );
}

export const UpdateOrDeleteAppointment = (props: Props) => {
  const { open, handleClose, handlCallbackReload, data } = props;
  const { currentItemAppointment } = data
  const [requestDate, setRequestDate] = React.useState<Moment | null>(null);
  const [motif, setMotif] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleDelete = async () => {
    if (motif !== "") {
      setLoading(true);
      const create = await DeleteAppointment(currentItemAppointment?.id, motif);
      setLoading(false);
      if (create?.status === 200 && create?.statusText === "OK") {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: "Le rendez-vous est annuller!"
        });
        if (handlCallbackReload) {
          handlCallbackReload()
        }
        handleClose();
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: "Une erreur est survenue lors du traitement de la demande!"
        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: " les champs sont obligatoires!"
      });
    }
  }

  const handlePostponeAppointment = async () => {
    if (motif !== "" && requestDate !== null) {
      setLoading(true);
      const create = await PostponeAppointment(currentItemAppointment?.id, {
        motif: motif,
        postponeDate: requestDate.toString()
      });
      setLoading(false);
      if (create?.status === 201 && create?.statusText === "Created") {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: "Le rendez-vous à été reporter!"
        });
        if (handlCallbackReload) {
          handlCallbackReload()
        }
        handleClose();
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: "Une erreur est survenue lors du traitement de la demande!"
        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: " les champs sont obligatoires!"
      });
    }
  }

  React.useEffect(() => {
    let isMount = true;
    if (isMount) {
      console.log(currentItemAppointment?.id);
    }
    return () => {
      isMount = false;
    }
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Rendez-vous
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Typography gutterBottom textAlign={"center"} ><b>Reporter ou Annuller le rendez-vous</b></Typography>
            </Grid>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="DATE"
                  value={requestDate}
                  onChange={(newValue) => {
                    setRequestDate(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                fullWidth
                id='motif'
                name='motif'
                label='MOTIF'
                variant='outlined'
                value={motif}
                onChange={(e) => setMotif(e.target.value)}
              />
            </Grid>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Btn action={() => { handleDelete() }} color={colors.redApp}>Annuler le rendez-vous</Btn>
              <Btn action={() => { handlePostponeAppointment() }} color={colors.blueApp}>Reporter le rendez-vous </Btn>
            </Grid>
            <Loading open={loading} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );
}


export const GetCommentModal = (props: Props) => {
  const { open, handleClose, handlCallbackReload, data } = props;

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Rendez-vous
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <CommentList commentaires={data?.commentaire ? data?.commentaire : []} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>

    </div>
  );
}


export const TestModale = (props: Props) => {
  const { open, handleClose, handlCallbackReload, data } = props;
  const [startTime, setStartTime] = React.useState<Moment | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });


  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const create = await createRendezvous({ date: startTime });
    setLoading(false);
    console.log(create);

    if (create?.status === 201 && create?.statusText === "Created") {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: "Votre programme a été publié."
      });
      if (handlCallbackReload) {
        handlCallbackReload()
      }
      handleClose();
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: create?.data?.message

      });
    }
  }
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Rendez-vous
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} p={2}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              renderInput={(props) => <TextField fullWidth {...props} />}
              label="DATE DU LIVE"
              value={startTime}
              onChange={(newValue) => {
                setStartTime(newValue);
              }}
            />
          </LocalizationProvider>
          <Btn color={colors.griseApp2} action={handleSubmit}>Submit</Btn>
        </Grid>
      </DialogContent>
    </BootstrapDialog>
  )


}
