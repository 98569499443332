import { Box, Card, CardActionArea, CardContent, Grid, Skeleton, Typography } from "@mui/material"
import { colors } from "../../config"
import { useNavigate } from "react-router-dom";
import { fileUrl } from "../../services/api";

export type CardHomeType = {
  path: string,
  Icon: any
  label: string,
  file: any[]
};

export const CardHome = ({ path, Icon, label, file }: CardHomeType) => {
  const navigation = useNavigate();

  return (
    <Card sx={{
      background: colors.blackOpacity,
      opacity: 0.5,
      border: '1px solid #000',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      display: "flex",
      width: "auto",
      height: "auto",
      padding: "10px 5px",
      flexDirection: "column",
      justifycontent: "center",
      alignItems: "center",
      gap: "10px",
      flexShrink: 0,
    }}>
      <CardActionArea sx={{ p: 0 }} onClick={() => navigation(path)}>
        <CardContent sx={{ p: 0 }}>
          <Grid container spacing={2}>

            {[1, 2, 3, 4, 5, 6].map((item, i) => {

              if (file[i]) {
                if (path != "/Membres" && path != "/Programmes") {
                  return <Grid key={i.toString()} item md={4}>
                  <img
                    src={`${fileUrl}${file[i].photo}`}
                    width={"75px"}
                    height={"75px"}
                    style={{
                      borderRadius: 5
                    }}
                  />
                </Grid>
                }else{
                  return <Grid key={i.toString()} item md={4}>
                  <Skeleton variant="rounded" width={"75px"} height={"75px"} sx={{ background: colors.black }} />
                </Grid>
                }
              } else {
                return <Grid key={i.toString()} item md={4}>
                  <Skeleton variant="rounded" width={"75px"} height={"75px"} sx={{ background: colors.black }} />
                </Grid>
              }
            }
            )}
          </Grid>
          <Box sx={{
            position: 'absolute',
            top: 70,
            right: 90,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Typography color={colors.white} gutterBottom variant="h5" component="div" textAlign={"center"}>
              <Icon htmlColor={colors.white} /> {label}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}