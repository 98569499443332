import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import Layout from "../../components/layout";
import { Btn } from "../../components/button";
import { colors } from "../../config";
import DataTable from "../../components/table/data.table";
import { AddDayAppointmentModale, TestModale, UpdateOrDeleteAppointment } from "../../components/modal";
import { useAppSelector } from "../../services/store/hooks";
import jwtDecode from "jwt-decode";
import { selectAuth } from "../../services/store/auth/auth.slice";
import { DeleteAnnonce, DeleteDayAppointment, findDayForAppointment } from "../../services/api/churcheApi";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import Loading from "../../components/loading";
import moment from "moment";
import AlertDialogSlide from "../../components/AlertDialogSlide";
import { url } from "../../services/api";

const columns = [
  {
    name: "n",
    label: "#",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "jour",
    label: "Jour",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "startTime",
    label: "Heure du début",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "endTime",
    label: "Heure de fin",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "limite",
    label: "Limite",
    options: {
      filter: true,
      sort: true,
    }
  },
];

const AppointmentScreenView = () => {
  const [appointments, setAppointments] = useState<any[]>([]);
  const [currentAppointment, setCurrentAppointment] = useState<any>({});
  const [currentItemAppointment, setCurrentItemAppointment] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [opensp, setOpensp] = useState<boolean>(false);
  const [openup, setOpenup] = useState<boolean>(false);
  const auth = useAppSelector(selectAuth);
  const user: any = jwtDecode(auth.access_token)
  const [loading, setLoading] = useState<boolean>(false);
  const [alertState, setAlertState] = useState({ open: false, msg: <></> });
  const [snackState, setSnackState] = useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });

  const toggleModal = () => {
    setOpen(!open)
  }

  const toggleModalSp = () => {
    setOpensp(!opensp);
  }

  const handleOpenAlert = () => {
    setAlertState({
      open: true,
      msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer la journée reserver pour le rendez-vous?</Typography>
    });
  }

  const handleCloseAlert = () => {
    setAlertState({
      open: false,
      msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer l'annonce?</Typography>
    });
  }

  const handleOpenStack = useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleConfirmAction = () => {
    handleDelete();
  }

  const handleDelete = async () => {
    setLoading(true);
    const deleteAppointment = await DeleteDayAppointment(currentAppointment?.id);
    setLoading(false);
    if (deleteAppointment?.status === 200) {
      handleCloseAlert()
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `Le journée est supprimer correctement!`
      });
      await getAppointments();
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur est survenue lors de la suppresion de la journée!`
      });
    }
  }

  const getAppointments = useCallback(async () => {
    setLoading(true);
    const get = await findDayForAppointment();
    setLoading(false);
    if (get?.data !== undefined && get?.status === 200) {
      let newData: any[] = [];
      get?.data?.map((item: any, i: number) => {
        newData.push({
          id: item?.id,
          n: i + 1,
          jour: item?.jour,
          startTime: moment(item?.startTime).format('LT'),
          endTime: moment(item?.endTime).format('LT'),
          limite: item?.limite,
          eglise: item?.eglise,
          rendezvousencharge: item?.rendezvousencharge
        });
      })
      setAppointments(newData);
      setCurrentAppointment(newData[0])
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Une erreur est survenue lors de la récupération des membres'
      });
    }
  }, []);

  const reloadGetAppointments = useCallback(async () => {
    setLoading(true);
    const get = await findDayForAppointment();
    setLoading(false);
    if (get?.data !== undefined && get?.status === 200) {
      let newData: any[] = [];
      get?.data?.map((item: any, i: number) => {
        newData.push({
          id: item?.id,
          n: i + 1,
          jour: item?.jour,
          startTime: moment(item?.startTime).format('LT'),
          endTime: moment(item?.endTime).format('LT'),
          limite: item?.limite,
          eglise: item?.eglise,
          rendezvousencharge: item?.rendezvousencharge
        });
      });
      setAppointments(newData);
      const curent = newData.find((item: any) => item?.id === currentAppointment?.id)
      setCurrentAppointment({})
      if (curent)
        setCurrentAppointment(curent)
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Une erreur est survenue'
      });
    }
  }, []);

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      getAppointments();
    }
    return () => {
      isMount = false;
    }
  }, []);

  return (
    <Layout>
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Btn color={colors.white} action={() => { toggleModal() }}><Typography color={colors.blueApp} >Ajouter un jour</Typography></Btn>
        </Grid>
        <Grid item md={8}>
          <DataTable handleClick={setCurrentAppointment} title={"Rendez-vous"} data={appointments} columns={columns} />
        </Grid>
        <Grid item md={4}>
          <Card sx={{ maxWidth: 345 }}>
            <CardHeader title={<Typography variant="h5" ><b>{currentAppointment?.jour}</b></Typography>} />
            <CardContent>
              {currentAppointment?.rendezvousencharge?.length > 0 ? currentAppointment?.rendezvousencharge?.map((item: any, i: number) => {
                return (
                  <ListItemButton key={i} onClick={() => {
                    setCurrentItemAppointment(item)
                    setOpenup(true);

                  }}>
                    <ListItemAvatar>
                      <img src={`/7.png`}
                        alt={item?.webp}
                        style={{
                          width: 70,
                          height: 70,
                          boxShadow: `5px 5px rgba(0, 0, 0, 0.2)`
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${item?.user?.nom} ${item?.user?.prenom}`}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        letterSpacing: 0,
                        marginLeft: 2
                      }}
                      secondaryTypographyProps={{
                        marginLeft: 2,
                        fontWeight: 'bold',
                      }}
                      secondary={`${moment(item?.requestDate).calendar()}`} />
                  </ListItemButton>
                );
              }): <Typography variant="body2" color="text.secondary">Aucun rendez-vous caler pour cette journée</Typography> }
            </CardContent>
            {currentAppointment?.rendezvousencharge?.length > 0 ?
            <CardActions>
            <Button onClick={handleOpenAlert} size="small">supprimer</Button>
          </CardActions> : null  
          }
          
          </Card>
        </Grid>
        <TestModale open={opensp} handleClose={toggleModalSp} />
        <AddDayAppointmentModale open={open} handleClose={toggleModal} handlCallbackReload={reloadGetAppointments} data={currentAppointment?.id} />
        <UpdateOrDeleteAppointment open={openup} handleClose={() => {setOpenup(false)}} handlCallbackReload={reloadGetAppointments} data={{
          currentItemAppointment: currentItemAppointment
        }} />
        <Loading open={loading} />
        <SnackbarAlert
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          type={snackState.type}
          message={snackState.message}
        />
        <AlertDialogSlide
          open={alertState.open}
          Message={() => alertState.msg}
          handleClose={handleCloseAlert}
          handleConfirmAction={() => handleConfirmAction()}
        />
      </Grid>
    </Layout>
  )
}

export default AppointmentScreenView;