import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Paper, Grid, Card, CardActionArea, CardContent, Divider } from "@mui/material";
import Layout from '../../components/layout';
import { useAppDispatch, useAppSelector } from '../../services/store/hooks';
import { loginUser, selectAuth } from '../../services/store/auth/auth.slice';
import jwtDecode from 'jwt-decode';
import { useLocation, useNavigate } from "react-router-dom";
import { ReloadSession, url } from "../../services/api";
import { colors, PrivilegesEnum } from "../../config";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import Loading from "../../components/loading"; 
import { UpdateChurcheModal } from "../../components/modal/adminModal";
import { Btn } from "../../components/button";
import { getFile } from "../../services/api/fileApi";
import { CardDisk, CardDiskAdmin } from "../../components/card/card.disk";

const ProfilChurcheView = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const [user, setUser] = useState<any>(jwtDecode(auth?.access_token))
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [statistique, setStatistique] = useState({
    audios: 0,
    videos: 0,
    livres: 0,
    lives: 0,
    members: 0,
    pragrammes: 0,
    annonces: 0
  });
  const [snackState, setSnackState] = useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });

  const Location = useLocation();

  const [idEglise, setIdEglise] = useState<number>(0)
  const [nomEglise, setNomEglise] = useState<string>("");
  const [sigle, setSigle] = useState<string>("");
  const [photo, setPhoto] = useState<any>("");
  const [image, setImage] = useState<string>("./ecclessia.png");
  const [photoCouverture, setPhotoCouverture] = useState<string>("");
  const [couverture, setCouverture] = useState<string>("./ecclessia.png");
  const [adresse, setAdresse] = useState<string>("");
  const [ville, setVille] = useState<string>("");
  const [pays, setPays] = useState<string>("");

  const [idAdmin, setIdAdmin] = useState<number>(0);
  const [nomAdmin, setNomAdmin] = useState<string>("");
  const [prenom, setPrenom] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telephone, setTel] = useState<string>("");
  const [villeAdmin, setVilleAdmin] = useState<string>("");
  const [adresseAdmin, setAdresseAdmin] = useState<string>("");
  const [paysAdmin, setPaysAdmin] = useState<string>("");
  const [password, setPwd] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [currentAudios, setCurrentAudios] = React.useState<any>({});
  const [audios, setAudios] = React.useState([]);
  const [videos, setVideos] = React.useState([])
  const [currentVideos, setCurrentVideos] = React.useState<any>({});
  const [livres, setLivres] = React.useState([])
  const [currentLivres, setCurrentLivres] = React.useState<any>({});
 
  const reloadSession = async () => {
    setLoading(true)
    const log = await ReloadSession({ telephone });
    setLoading(false);
    console.log(log);
    
    if (log?.status === 201) {
      dispatch(loginUser({
        access_token: log?.data?.tokens?.access_token,
        refresh_token: log.data?.tokens?.refresh_token,
        isAuthenticated: true
      }));
      setUser(jwtDecode(log?.data?.tokens?.access_token))
    }
  }

  const handleOpenStack = React.useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  }

  const findFiles = useCallback(async (id_eglise: number, typeFile: string) => {
    setLoading(true);
    const get = await getFile(id_eglise, typeFile);
    setLoading(false);
    if (get?.status === 200 && get?.data !== undefined) {
      switch (typeFile) {
        case "audios":
          setAudios(get?.data);
          setCurrentAudios(get?.data[0])
          break;
        case "videos":
          setVideos(get?.data);
          setCurrentVideos(get?.data[0])
          break;
        case "Livres":
          setLivres(get?.data);
          setCurrentLivres(get?.data[0])
          break;
        default:
          break;
      }


    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Vous avez aucune musique dans la bibliothéque'
      });
    }
  }, [handleOpenStack, user]);

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      let token: any = jwtDecode(auth?.access_token);
      console.log(token);

      if (token?.privilege_user === PrivilegesEnum.ADMIN_EGLISE) {
        setIdEglise(token?.eglise?.id_eglise)
        setNomEglise(token?.eglise?.nom_eglise);
        setSigle(token?.eglise?.sigle_eglise);
        setPhoto("");
        { token?.eglise?.photo_eglise ? setImage(`${url}files/photo/${token?.eglise?.photo_eglise}`) : setImage(`./ecclessia.png`) }
        { token?.eglise?.couverture_eglise ? setCouverture(`${url}files/photo/${token?.eglise?.couverture_eglise}`) : setCouverture(`./ecclessia.png`) }
        setPhotoCouverture("");
        setUser(token);
        setAdresse(token?.eglise?.adresse_eglise);
        setVille(token?.eglise?.ville_eglise);
        setPays(token?.eglise?.pays_eglise);

        setIdAdmin(token?.sub);
        setNomAdmin(token?.nom);
        setPrenom(token?.prenom);
        setEmail(token?.email);
        setTel(token?.telephone);
        setVilleAdmin(token?.ville);
        setAdresseAdmin(token?.adresse);
        setPaysAdmin(token?.pays);

      } else if (token?.privilege_user === PrivilegesEnum.ADMIN) {
        const eglise = Location.state.eglise;
        if (eglise !== null) {
          setIdEglise(eglise?.id_eglise)
          setNomEglise(eglise?.nom_eglise);
          setSigle(eglise?.sigle_eglise);
          setPhoto("");
          { eglise?.photo_eglise ? setImage(`${url}files/photo/${eglise?.photo_eglise}`) : setImage(`./ecclessia.png`) }
          { eglise?.couverture_eglise ? setCouverture(`${url}files/photo/${eglise?.couverture_eglise}`) : setCouverture(`./ecclessia.png`) }
          setPhotoCouverture("");
          setAdresse(eglise?.adresse_eglise);
          setVille(eglise?.ville_eglise);
          setPays(eglise?.pays_eglise);
          setIdAdmin(eglise?.admin?.id);
          setNomAdmin(eglise?.admin);
          setPrenom(eglise?.admin?.prenom);
          setEmail(eglise?.admin?.email);
          setTel(eglise?.admin?.telephone);
          setVilleAdmin(eglise?.admin?.ville);
          setAdresseAdmin(eglise?.admin?.adresse);
          setPaysAdmin(eglise?.admin?.pays);
          setUser({ ...eglise.admin, eglise });

          findFiles(eglise?.id_eglise, 'audios');
          findFiles(eglise?.id_eglise, 'videos');
          findFiles(eglise?.id_eglise, 'livres');
          // findFiles(eglise?.id_eglise, 'livres');
        }
      }
    }
    return () => {
      isMount = false;
    }
  }, [])

  return <Layout>
    <>
      <Box>
        <Paper style={{
          height: 400,
          background: `url(${url}files/photo/${user?.eglise?.couverture_eglise})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          maxWidth: "100%"
        }}>
          <Box
            display={"flex"}
            position={"absolute"}
            alignItems={"bottom"}
            top={420}
            sx={{ pl: 5 }}
          >
            <img
              src={user?.eglise?.photo_eglise ? `${url}files/photo/${user?.eglise?.photo_eglise}` : './ecclessia.png'}
              alt="logo ecclessiabook"
              style={{
                width: 150,
                height: 150,
                borderRadius: '60px',
                objectFit: "contain"
              }}

            />
          </Box>
        </Paper>
        <Box mt={11}>
          <Grid container spacing={2}>

            <Grid item md={6}>
              <Grid item md={12}>
                <Typography variant="h6" fontWeight={"bold"} textAlign={"center"}>Administrateur</Typography>
              </Grid>
              <Grid item md={12}><Divider /></Grid>
              <Grid item md={12}>
                <Typography m={2} variant="inherit"><b>Nom: </b> {user?.nom} </Typography>
                <Typography m={2} variant="inherit"><b>Prénom: </b> {user?.prenom} </Typography>
                <Typography m={2} variant="inherit"><b>Téléphone: </b> {user?.telephone} </Typography>
                <Typography m={2} variant="inherit"><b>Email: </b> {user?.email} </Typography>
                <Typography m={2} variant="inherit"><b>Adresse: </b> {user?.adresse} </Typography>
                <Typography m={2} variant="inherit"><b>Ville: </b> {user?.ville} </Typography>
                <Typography m={2} variant="inherit"><b>Pays: </b> {user?.pays} </Typography>
              </Grid>
            </Grid>

            <Grid item md={6}>
              <Grid item md={12}>
                <Typography variant="h6" fontWeight={"bold"} textAlign={"center"}>Église</Typography>
              </Grid>
              <Grid item md={12}><Divider /></Grid>
              <Grid item md={12}>
                <Typography m={2} variant="inherit"><b>Nom: </b> {user?.eglise?.nom_eglise} </Typography>
                <Typography m={2} variant="inherit"><b>Sigle: </b> {user?.eglise?.sigle_eglise} </Typography>
                <Typography m={2} variant="inherit"><b>Adresse: </b> {user?.eglise?.adresse_eglise} </Typography>
                <Typography m={2} variant="inherit"><b>Ville: </b> {user?.eglise?.ville_eglise} </Typography>
                <Typography m={2} variant="inherit"><b>Pays: </b> {user?.eglise?.pays_eglise} </Typography>
              </Grid>
            </Grid>

            {user?.privilege_user === PrivilegesEnum.ADMIN_EGLISE ? <>
              <Grid item md={3}></Grid>
              <Grid item md={6} justifyContent={"center"} display={"flex"}> <Btn action={() => setOpenModal(true)} color={colors.blueApp}>Modifier</Btn> </Grid>
              <Grid item md={3}></Grid></> : null}
            {/* <Grid item md={12}>
              <Typography variant="h6" fontWeight={"bold"} textAlign={"center"}>Vidéos</Typography>
            </Grid>
            <Grid item md={12}><Divider /></Grid>
            <Grid container spacing={2}>
              {videos.length > 0 ? videos?.map((item: any, i: number) => {
                return (
                  <Grid key={`${i}${item?.auteur} ${item?.titre}`} item md={3} xs={6}>
                    <CardDiskAdmin data={{
                      videos: [item],
                      currentVideos: item
                    }
                    } action={() => { setCurrentVideos(item) }} webp={item?.webp ? `${url}files/webp/${item?.webp}` : "./ecclessia.png"} titre={item?.titre} auteur={item?.auteur} />
                  </Grid>
                )
              }) : <Grid item md={12} xs={12}>
                <Typography variant="h6" textAlign={"center"} color={colors.griseApp}>L'église n'a aucune vidéo dans la bibliothéque</Typography>
              </Grid>
              }
            </Grid> */}

          </Grid>
        </Box>
        <UpdateChurcheModal
          open={openModal}
          handleClose={() => { setOpenModal(false) }}
          handlCallbackReload={reloadSession}
          data={{
            idEglise, setIdEglise,
            nomEglise, setNomEglise,
            sigle, setSigle,
            photo, setPhoto,
            image, setImage,
            photoCouverture, setPhotoCouverture,
            couverture, setCouverture,
            adresse, setAdresse,
            ville, setVille,
            pays, setPays,


            idAdmin, setIdAdmin,
            nomAdmin, setNomAdmin,
            prenom, setPrenom,
            email, setEmail,
            telephone, setTel,
            villeAdmin, setVilleAdmin,
            adresseAdmin, setAdresseAdmin,
            paysAdmin, setPaysAdmin,
            password, setPwd,
            confirmPassword, setConfirmPassword
          }}
        />
        <Loading open={loading} />
        <SnackbarAlert
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          type={snackState.type}
          message={snackState.message}
        />
      </Box>
    </>
  </Layout>
}

export default ProfilChurcheView