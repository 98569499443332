import React from "react";
import parse from 'html-react-parser';
import { Box } from "@mui/material";

interface Props {
  iframe: string
}

export const IframeLive = ({iframe}: Props) => {
  const handleIframe = () => {
    if (iframe) {
      return parse(iframe);
    } else {
      return <></>
    }
  }
  return (
    <Box sx={{width: iframe ?'100%': undefined}}>
    {handleIframe()}
    </Box>
  )
}
