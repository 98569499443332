import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import Layout from '../../components/layout';
import { CardDisk } from "../../components/card/card.disk";
import { VideoPlayer } from "../../components/videosPlayer";
import { colors } from "../../config";
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { selectAuth } from "../../services/store/auth/auth.slice";
import { useAppSelector } from "../../services/store/hooks";
import jwtDecode from "jwt-decode";
import { DeleteFile, getFile } from "../../services/api/fileApi";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import Loading from "../../components/loading";
import { fileUrl, url } from "../../services/api";
import { Btn } from "../../components/button";
import { CreateFileModal, GetCommentModal, UpdateFileModal } from "../../components/modal";
import AlertDialogSlide from "../../components/AlertDialogSlide";
import { Moment } from "moment";
import { IframeLive } from "../../components/live";

const VideoScreenView = () => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalUpdateFIle, setOpenModalUpdateFile] = React.useState<boolean>(false);
  const [openModalComment, setOpenModalComment] = React.useState<boolean>(false);
  const [videos, setVideos] = React.useState([])
  const auth = useAppSelector(selectAuth);
  const [currentVideos, setCurrentVideos] = React.useState<any>({});
  const user: any = jwtDecode(auth.access_token)
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [alertState, setAlertState] = useState({ open: false, msg: <></> });

  const [dateLive, setDateLive] = React.useState<Moment | null>(null);
  // const [iframe, setIframe] = React.useState<string>("");
  const [titre, setTitre] = React.useState<string>("");
  const [auteur, setAuteur] = React.useState<string>("");
  const [photo, setPhoto] = React.useState<any>("");
  const [file, setFile] = React.useState<any>("");
  const [iframe, setIframe] = React.useState<string>("");

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const handleOpenStack = React.useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  }

  const handleOpenAlert = () => {
    setAlertState({
      open: true,
      msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment supprimer la vidéo?</Typography>
    })
  };

  const handleCloseAlert = () => {
    setAlertState({
      open: false,
      msg: <></>
    });
  };

  const handleConfirmAction = () => {
    handleDelete()
    handleCloseAlert()
  }

  const findVideos = React.useCallback(async () => {
    setLoading(true);
    const get = await getFile(user?.eglise?.id_eglise, 'videos');
    setLoading(false);
    if (get?.status === 200 && get?.data !== undefined) {
      setVideos(get?.data);
      setCurrentVideos(get?.data[0])
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Vous avez aucune vidéo dans la bibliothéque'
      });
    }
  }, [handleOpenStack, user]);

  const handleDelete = async () => {
    setLoading(true);
    const deleted = await DeleteFile(currentVideos?.id, 'videos')
    setLoading(false);
    if (deleted?.status === 200) {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `La vidéo est supprimer du système!`
      });
      await findVideos()
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Une erreur survenue lors traitement!`
      });
    }
  }

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      findVideos();
    }
    return () => {
      isMount = false
    }
  }, [])

  return <Layout>
    <>
      <Box>
        <Grid container>
          <Grid item md={12}>
          {(currentVideos.interne && currentVideos ) ?
          <VideoPlayer allVideos={videos} videos={currentVideos.id} />
            : 
            <IframeLive iframe={currentVideos?.lien} /> 

           }
          </Grid>
          <Grid item md={12} sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Typography variant="h6" color={colors.griseApp} fontWeight={"bold"} mr={2}>{currentVideos?.auteur}</Typography>
            </Box>
            {currentVideos?.commentaire ? <IconButton onClick={() => { setOpenModalComment(true) }}>
              <CommentIcon htmlColor={colors.griseApp} />
              <Typography color={colors.griseApp} fontWeight={"bold"} mr={2}> {currentVideos?.commentaire?.length}</Typography>
            </IconButton> : null}
            {currentVideos?.favoris ?
              <IconButton>
                <FavoriteIcon htmlColor={colors.redApp} />
                <Typography color={colors.griseApp} fontWeight={"bold"}> {currentVideos?.favoris?.length}</Typography>
              </IconButton> : null}
            {currentVideos?.id ? <Box>
              <Btn action={() => {
                setTitre(currentVideos?.titre);
                setAuteur(currentVideos?.auteur);
                setIframe(currentVideos?.lien)
                setOpenModalUpdateFile(true);
              }} color={colors.white} margin={"2px"} width={"auto"}><Typography color={colors.blueApp}>Modifier</Typography></Btn>
              <Btn action={() => { handleOpenAlert() }} margin={"2px"} color={colors.redApp} width={"auto"}><Typography color={colors.white}>Supprimer</Typography></Btn>
            </Box> : null}
          </Grid>
          <Grid item md={12}>
            <Typography variant="subtitle1" color={colors.griseApp} fontWeight={"bold"} mr={2}>{currentVideos?.titre}</Typography>
          </Grid>
        </Grid>
        <Box mt={1}>
          <Btn action={() => { toggleModal() }} color={colors.white} width={"auto"}><Typography color={colors.blueApp}>AJouter une vidéo</Typography></Btn>
          <Typography variant="h6" color={colors.white} textAlign={"center"}><b>Vidéos</b></Typography>
          <Grid container spacing={2}>
            {videos.length > 0 ? videos?.map((item: any, i: number) => {
              return (
                <Grid key={`${i}${item?.auteur} ${item?.titre}`} item md={3} xs={6}>
                  <CardDisk action={() => { setCurrentVideos(item) }} webp={item?.photo ? `${fileUrl}${item?.photo}` : "./ecclessia.png"} titre={item?.titre} auteur={item?.auteur} />
                </Grid>
              )
            }) : <Grid item md={12} xs={12}>
              <Typography variant="h6" textAlign={"center"} color={colors.griseApp}>Vous n'avez aucune vidéo dans la bibliothèque</Typography>
            </Grid>
            }
          </Grid>
        </Box>
        <CreateFileModal open={openModal} handleClose={toggleModal} handlCallbackReload={findVideos} />
        <GetCommentModal open={openModalComment} handleClose={() => { setOpenModalComment(false) }} handlCallbackReload={findVideos} data={{
          commentaire: currentVideos?.commentaire
        }} />
        <UpdateFileModal open={openModalUpdateFIle} handlCallbackReload={findVideos} handleClose={() => { setOpenModalUpdateFile(false) }}
          data={{
            photo, setPhoto,
            file: "",
            setFile: () => {},
            dateLive,
            setDateLive,
            titre, setTitre,
            auteur, setAuteur,
            iframe,
            setIframe,
            acceptFile: "video/*",
            idFile: currentVideos?.id
          }}
        />
        <Loading open={loading} />
        <SnackbarAlert
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          type={snackState.type}
          message={snackState.message}
        />
        <AlertDialogSlide
          open={alertState.open}
          Message={() => alertState.msg}
          handleClose={handleCloseAlert}
          handleConfirmAction={() => handleConfirmAction()}
        />
      </Box>
    </>
  </Layout>
}

export default VideoScreenView;
