import React, { useEffect } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { colors, getSize } from '../../config';
import { Btn } from '../../components/button';
import SnackbarAlert, { PropsSnack } from '../../components/snackbarAlert';
import Loading from '../../components/loading';
import { useNavigate } from 'react-router-dom';
import { AuthPayementApi } from '../../services/api';
import { useAppDispatch, useAppSelector } from '../../services/store/hooks';
import { selectAuth } from '../../services/store/auth/auth.slice';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import Notice from '../../components/notice';
import AlertDialogSlide from '../../components/AlertDialogSlide';
import AlertDialogFormAbonnementSlide from '../../components/AlertDialogSlide/index.form';

const AuthPayementView = () => {
  const screen = getSize();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth)
  const user: any = jwtDecode(auth?.access_token);
  const [alertState, setAlertState] = React.useState({ open: false, msg: <></> });

  const handleSubmit = async () => {
    setLoading(true);
    const payement = await AuthPayementApi();
    setLoading(false);
    console.log(payement);
  }

  useEffect(() => {
    let isMount = true
    if (isMount) {
      console.log(user);

      if (!auth.isAuthenticated) navigation('/AuthConnexionView')
    }
    return () => {
      isMount = false;
    }
  }, []);

  const handleOpenApprouved = () => {
    setAlertState({
      open: true,
      msg: <Notice />,
    });
  };


  const handleClose = () => {
    setAlertState({
      open: false,
      msg: <></>
    })
  };
  const handleConfirmAction = async () => {
    handleClose();
    await handleSubmit();
  }

  return (
    <Grid container style={{ position: 'absolute', top: 0, bottom: 0, height: "100%"}} className='gradient_background'>
      <Grid item md={6} xs={12}>
        <Stack
          sx={{ height: "100%"}}
          alignItems={"center"}
          justifyContent={'center'}
          alignContent={'center'}>
          <Stack
            sx={{ display: { xs: "flex", md: 'none' } }}
            alignItems={"center"}
            justifyContent={'center'}
            alignContent={'center'}>
            <img
              src='./ecclessia.png'
              alt="ecclessia"
              width={80}
            />
            <Typography fontWeight={"bold"} color={colors.white}>EcclesiaBook</Typography>
          </Stack>
          <Typography variant='h4' textAlign={"center"} color={colors.white} mb={2}>Payement de votre abonnement annuelle</Typography>
          <Typography variant='h4' textAlign={"center"} color={colors.white}>34.99$/an</Typography>
          <Btn type="button" action={() => { handleOpenApprouved() }} color={colors.redApp}>
            <Typography variant='h5' fontWeight={"bold"}>
              Abonnez-vous
            </Typography>
          </Btn>
          <br />

          <Typography sx={{ m: 3, mt: 10, textAlign: "center", display: { xs: "flex", md: 'none' } }} color={colors.white}>
            Plateforme d'évangelisation permettant la diffusion de la parole de Dieu sur l'étendue de la terre et une très bonne structure des églises.
          </Typography>
          <Box display={"flex"} position={"absolute"} bottom={0}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Typography style={{ color: colors.white, textTransform: "none", textAlign: "center" }}> <a style={{ color: colors.white, textDecoration: 'none', textTransform: "none", textAlign: "center" }} onClick={() => { navigation('/HelpView') }}> Mentions légales | Politique de confidentialité | Termes et Conditions d'utilisation. </a></Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography sx={{ textAlign: "center" }} color={colors.white}>Copyright &#169; 2023 Tous droits réservés.</Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>
      <Grid item md={6} display={{ xs: 'none', md: "flex" }}>
        <Stack
          sx={{ height: "100%" }}
          alignItems={"center"}
          justifyContent={'center'}
          alignContent={'center'}>
          <img
            src='./ecclessia.png'
            alt="ecclessia"
            width={200}
          />
          <Typography fontWeight={"bold"} color={colors.white}>EcclesiaBook</Typography>
          <Typography fontWeight={"100"} sx={{ m: 3, textAlign: "center", color: colors.white }}>
            Plateforme d'évangelisation permettant la diffusion de la parole de Dieu sur l'étendue de la terre et une très bonne structure des églises.
          </Typography>
        </Stack>
      </Grid>
      <Loading open={loading} />
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
      <AlertDialogFormAbonnementSlide
        open={alertState.open}
        handleClose={handleClose}
        Message={() => alertState.msg}
        handleConfirmAction={handleConfirmAction}
        user={user}
      />
    </Grid>
  )
}

export default AuthPayementView;
