import react from "react";
import { url } from "../services/api";

const SdkInit = () => {

  window.fbAsyncInit = function () {
    window.FB.init({
      appId: '1924578844551349',
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v16.0'
    });
  };

  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
}

export const SendFileInFacebook = (annonce) => {
  SdkInit()
  window.FB.ui({
    display: 'popup',
    method: 'share',
    href: `${url}files/annonce/${annonce}`,
  }, function (response) {
    console.log(response)
  });

}