import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { colors, getSize, PrivilegesEnum } from '../../config';
import { Btn } from '../../components/button';
import SnackbarAlert, { PropsSnack } from '../../components/snackbarAlert';
import Loading from '../../components/loading';
import { InputApp } from '../../components/input';
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../services/store/hooks';
import { logoutUser, selectAuth } from '../../services/store/auth/auth.slice';
import jwtDecode from 'jwt-decode';
import { Abonnement_access } from '../../services/api';

const AuthPayementSuccessView = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const navigation = useNavigate();
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const user: any = jwtDecode(auth?.access_token);

  const handleAbonnement = async () => {
    if (params.get("Method") !== null && params.get("reference") !== null && params.get("status") === "success") {
      const abonnement = await Abonnement_access({
        montant_abonnement: "24.99",
        method_abonnement: params.get("Method"),
        reference_abonnement: params.get("reference"),
        // type_abonnement_id: 1,
      });
      console.log(abonnement);
      if (abonnement?.status === 201 && abonnement?.statusText === "Created") {
        navigation('/HomeDashBoardView');
      } else {
        dispatch(logoutUser());
        navigation('/AuthConnexionView');
      }
    } else {
      dispatch(logoutUser());
      navigation('/AuthConnexionView');
    }
  }

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      if (!auth.isAuthenticated) navigation('/AuthConnexionView')
      if (params.get("status") !== "success") {
        dispatch(logoutUser());
        navigation('/AuthConnexionView');
      } else {
        handleAbonnement();
      }
    }
    return () => {
      isMount = false;
    }
  }, []);

  return <Grid container style={{ position: 'absolute', top: 0, bottom: 0, height: "100%" }} className='gradient_background'>
    <Grid item md={6} xs={12}>
      <Stack
        sx={{ height: "100%" }}
        alignItems={"center"}
        justifyContent={'center'}
        alignContent={'center'}>
        <Stack
          sx={{ display: { xs: "flex", md: 'none' } }}
          alignItems={"center"}
          justifyContent={'center'}
          alignContent={'center'}>
          <img
            src='./ecclessia.png'
            alt="ecclessia"
            width={80}
          />
          <Typography fontWeight={"bold"} color={colors.white}>EcclesiaBook</Typography>
        </Stack>
        <Typography color={colors.white}>Payement</Typography>

        <form>
          <input type="image" name="ap_image" src="https://congocloud.net/client/assets/img/visa.png" />
        </form>
        <br />

        <Typography sx={{ m: 3, mt: 10, textAlign: "center", display: { xs: "flex", md: 'none' } }} color={colors.white}>
          Plateforme d'évangelisation permettant la diffusion de la parole de Dieu sur l'étendue de la terre et une très bonne structure des églises.
        </Typography>
        <Box display={"flex"} position={"absolute"} bottom={0}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Typography style={{ color: colors.griseApp2, textTransform: "none", textAlign: "center" }}> <a style={{ color: colors.griseApp2, textDecoration: 'none', textTransform: "none", textAlign: "center" }} href='#' onClick={() => { navigation('/HelpView') }}> Mentions légales | Politique de confidentialité | Termes et Conditions d'utilisation. </a></Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography sx={{ textAlign: "center" }} color={colors.griseApp2}>Copyright &#169; 2023 Tous droits réservés.</Typography>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Grid>
    <Grid item md={6} display={{ xs: 'none', md: "flex" }}>
        <Stack
          sx={{ height: "100%" }}
          alignItems={"center"}
          justifyContent={'center'}
          alignContent={'center'}>
          <img
            src='./ecclessia.png'
            alt="ecclessia"
            width={200}
          />
          <Typography fontWeight={"bold"} color={colors.white}>EcclesiaBook</Typography>
          <Typography fontWeight={"100"} sx={{ m: 3, textAlign: "center", color: colors.white }}>
            Plateforme d'évangelisation permettant la diffusion de la parole de Dieu sur l'étendue de la terre et une très bonne structure des églises.
          </Typography>
        </Stack>
      </Grid>
    <Loading open={loading} />
  </Grid>
}

export default AuthPayementSuccessView;
