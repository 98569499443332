import { Box, DialogContent, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BootstrapDialog, BootstrapDialogTitle, Props } from ".";
import { colors, PrivilegesEnum } from "../../config";
import { createAdmin, updateAdmin } from "../../services/api";
import { CreateChurche } from "../../services/api/churcheApi";
import { selectAuth } from "../../services/store/auth/auth.slice";
import { useAppDispatch, useAppSelector } from "../../services/store/hooks";
import SnackbarAlert, { PropsSnack } from "../snackbarAlert";
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import Loading from "../loading";
import { Btn } from "../button";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { UpdateChurche } from "../../services/api/adminApi";
import { VideoPlayer } from "../videosPlayer";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export function CreateChurchesModal(props: Props) {
  const { open, handleClose, handlCallbackReload } = props
  const [nomEglise, setNomEglise] = useState<string>("");
  const [sigle, setSigle] = useState<string>("");
  const [photo, setPhoto] = useState<any>("");
  const [image, setImage] = useState<string>("./ecclessia.png");
  const [adresse, setAdresse] = useState<string>("");
  const [ville, setVille] = useState<string>("");
  const [pays, setPays] = useState<string>("");

  const [nomAdmin, setNomAdmin] = useState<string>("");
  const [prenom, setPrenom] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telephone, setTel] = useState<string>("");
  const [password, setPwd] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const navigation = useNavigate();
  const auth = useAppSelector(selectAuth);
  const [step, setStep] = useState<number>(0);

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleChange = (event: React.ChangeEvent) => {
    const reader = new FileReader()
    const { files } = event.target as HTMLInputElement;
    if (files && files.length !== 0) {
      reader.onload = () => {
        setImage(reader.result as string)
      }
      reader.readAsDataURL(files[0]);
      setPhoto(files[0])
      console.log(files);
      console.log(files[0]);
    }
  };

  const handleSubmit = async () => {
    if (photo !== "" && nomEglise !== "" && sigle !== "" && nomAdmin !== "" && prenom !== "" && email !== "" && telephone !== "" && password !== "") {
      if (password === confirmPassword) {
        const formData = new FormData();
        formData.append('nom_eglise', nomEglise);
        formData.append('photo', photo);
        formData.append('sigle_eglise', sigle);
        formData.append('adresse_eglise', adresse);
        formData.append('ville_eglise', ville);
        formData.append('pays_eglise', pays);
        setLoading(true);
        const createChurche = await CreateChurche(formData);
        if (createChurche?.status === 201 && createChurche?.statusText === "Created") {
          const create = await createAdmin({
            nom: nomAdmin,
            prenom,
            telephone,
            email,
            password,
            privilege: "administrateur_eglise",
            fk_eglise: createChurche.data.id_eglise
          });
          if (create?.status === 200 && create?.statusText === "OK") {
            handleOpenStack({
              open: true,
              vertical: 'top',
              horizontal: 'center',
              type: 'success',
              message: `L'église vient être enregistre correctement!`
            });
          } else {
            handleOpenStack({
              open: true,
              vertical: 'top',
              horizontal: 'center',
              type: 'warning',
              message: `Une erreur est survenue lors de la lieson avec l'eglise mais l'église a étè créee! `
            });
          }
          if (handlCallbackReload) {
            handleClose();
            handlCallbackReload();
          }
        } else {
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'error',
            message: `Une erreur est survenue lors de la création de l'église `
          });
        }
        setLoading(false);
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: 'Le deux mot de passe doivent ếtre indetique!'
        })
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Les champs sont obligatoires.'
      });
    }
  }

  const handleChangeStep = () => {
    if (nomEglise !== "" && sigle !== "" && photo !== "") {
      setStep(1);
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Les champs sont obligatoires.'
      });
    }
  }

  useEffect(() => {
    let isMount = true
    if (isMount) {
      if (!auth.isAuthenticated) navigation('/AuthConnexionView')
    }
    return () => {
      isMount = false;
    }
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Ajouter une église
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom textAlign={"center"}><b>{step === 0 ? "Information de l'église" : "Information de l'administrateur"}</b></Typography>
          <Grid container>
            {step === 0 ?
              <Grid item container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="nom"
                    name="nom"
                    label="Nom de l'église"
                    variant="outlined"
                    value={nomEglise}
                    onChange={(e) => setNomEglise(e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="sigle"
                    name="sigle"
                    label="Sigle de l'église"
                    variant="outlined"
                    value={sigle}
                    onChange={(e) => setSigle(e.target.value)}
                  />
                </Grid>

                <Grid item md={6} container>
                  <Typography color={colors.griseApp2}>Créer votre église</Typography>
                  <Box display={"flex"} justifyContent={"space-betwen"} alignItems={"center"}>
                    <img src={image} alt="churche Image" width={100} height={100} />
                    <IconButton style={{ color: colors.white, background: photo !== "" ? colors.blueApp : colors.griseApp2, marginRight: 15 }} aria-label="upload picture" component="label">
                      <input hidden accept="image/*" type="file" onChange={(e) => handleChange(e)} />
                      <InstallDesktopIcon />
                    </IconButton>
                  </Box>

                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="adresse"
                    name="adresse"
                    label="Adresse de l'église"
                    variant="outlined"
                    value={adresse}
                    onChange={(e) => setAdresse(e.target.value)}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="ville"
                    name="ville"
                    label="Ville de l'église"
                    variant="outlined"
                    value={ville}
                    onChange={(e) => setVille(e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="pays"
                    name="pays"
                    label="pays de l'église"
                    variant="outlined"
                    value={pays}
                    onChange={(e) => setPays(e.target.value)}
                  />
                </Grid>
              </Grid>
              : <Grid item container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="nom"
                    name="nom"
                    label="Nom"
                    variant="outlined"
                    value={nomAdmin}
                    onChange={(e) => setNomAdmin(e.target.value)}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="prenom"
                    name="prenom"
                    label="Prénom"
                    variant="outlined"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="email"
                    id="email"
                    name="email"
                    label="email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="tel"
                    id="tel"
                    name="tel"
                    label="Téléphone"
                    variant="outlined"
                    value={telephone}
                    onChange={(e) => setTel(e.target.value)}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="password"
                    id="pwsd"
                    name="pwsd"
                    label="Mot de passe"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPwd(e.target.value)}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="password"
                    id="confpwsd"
                    name="confpwsd"
                    label="Confirmation du Mot de passe"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
            }
          </Grid>
          <Grid item md={12} sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            {step === 1 ? <Btn action={() => { setStep(0) }} margin={"2px"} color={colors.griseApp2}>Retour</Btn> : null}
            <Btn action={() => { step === 0 ? handleChangeStep() : handleSubmit() }} margin={"2px"} color={colors.blueApp}>{step === 0 ? "Suivant" : "Enregistrer"}</Btn>
          </Grid>
          <Loading open={loading} />
        </DialogContent>
      </BootstrapDialog>

      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );
}

export function UpdateChurcheModal(props: Props) {
  const { open, handleClose, handlCallbackReload, data } = props;

  const { idEglise, idAdmin } = data;
  const { nomEglise, setNomEglise } = data;
  const { sigle, setSigle } = data;
  const { photo, setPhoto } = data;
  const { image, setImage } = data;
  const { photoCouverture, setPhotoCouverture,
    couverture, setCouverture } = data
  const { villeAdmin, setVilleAdmin,
    adresseAdmin, setAdresseAdmin,
    paysAdmin, setPaysAdmin } = data;
  const { adresse, setAdresse } = data;
  const { ville, setVille } = data;
  const { pays, setPays } = data;

  const { nomAdmin, setNomAdmin } = data
  const { prenom, setPrenom } = data
  const { email, setEmail } = data
  const { telephone, setTel } = data
  const { password, setPwd } = data
  const { confirmPassword, setConfirmPassword } = data;

  const [loading, setLoading] = React.useState<boolean>(false);

  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const navigation = useNavigate();
  const auth = useAppSelector(selectAuth);
  const [step, setStep] = useState<number>(0);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfPassword, setShowConfPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfPassword = () => setShowConfPassword((value) => !value);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    // setTimeout(() => {
    //   handleCloseStack();
    // }, 3000);
  };

  const handleCloseStack = () => {
    setSnackState({ type: snackState.type, open: false, message: snackState.message, vertical: "top", horizontal: "center" });
  };

  const handleChange = (event: React.ChangeEvent, type = "") => {
    const reader = new FileReader()
    const { files } = event.target as HTMLInputElement;
    if (files && files.length !== 0) {
      reader.onload = () => {
        if (type === "couverture") {
          setCouverture(reader.result as string)
        } else {
          setImage(reader.result as string)
        }
      }
      reader.readAsDataURL(files[0]);
      if (type === "couverture") {
        setPhotoCouverture(files[0])
      } else {
        setPhoto(files[0])
      }

    }
  };

  const handleSubmit = async () => {
    console.log(idEglise);
    if (step === 0) {
      const formData = new FormData();
      formData.append('nom_eglise', nomEglise);
      if (photo !== "") formData.append('photo', photo);
      if (photoCouverture !== "") formData.append('couverture', photoCouverture);
      formData.append('sigle_eglise', sigle);
      formData.append('adresse_eglise', adresse);
      formData.append('ville_eglise', ville);
      formData.append('pays_eglise', pays);
      setLoading(true);
      if (idEglise !== 0) {
        const createChurche = await UpdateChurche(formData, idEglise);
        if (createChurche?.status === 200 && createChurche?.statusText === "OK") {
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'success',
            message: `L'église vient être modifier correctement!`
          });
          if (handlCallbackReload) {
            handleClose()
            handlCallbackReload()
          }
        } else {
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'error',
            message: `Une erreur est survenue lors de la création de l'église `
          });
        }
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: `Une erreur est survenue lors de la création de l'église `
        });
      }
      setLoading(false);
    } else {
      if (idAdmin !== 0) {
        setLoading(true);
        const create = await updateAdmin({
          nom: nomAdmin,
          prenom,
          telephone,
          email,
          ville: villeAdmin,
          adresse: adresseAdmin,
          pays: paysAdmin,
          password,
          privilege: "administrateur_eglise",
        }, idAdmin);
        setLoading(false);
        if (create?.status === 200 && create?.statusText === "OK") {
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'success',
            message: `L'administrateur vient être modifier correctement!`
          });
          if (handlCallbackReload) {
            handleClose()
            handlCallbackReload()
          }
        } else {
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'warning',
            message: `Une erreur est survenue lors de la modification de l'administrateur!`
          });
        }
      } else {
        setLoading(true);
        const create = await createAdmin({
          nom: nomAdmin,
          prenom,
          telephone,
          email,
          password,
          privilege: "administrateur_eglise",
          fk_eglise: idEglise
        });
        setLoading(false);
        if (create?.status === 200 && create?.statusText === "OK") {
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'success',
            message: `L'administrateur vient être modifier correctement!`
          });
          if (handlCallbackReload) {
            handleClose()
            handlCallbackReload()
          }
        } else {
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'warning',
            message: `Une erreur est survenue lors de la modification de l'administrateur!`
          });
        }
      }
    }

  }

  const handleChangeStep = () => {
    if (nomEglise !== "" && sigle !== "" && photo !== "") {
      setStep(1);
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Les champs sont obligatoires.'
      });
    }
  }

  useEffect(() => {
    let isMount = true
    if (isMount) {
      if (!auth.isAuthenticated) navigation('/AuthConnexionView')
    }
    return () => {
      isMount = false;
    }
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Modifier
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom textAlign={"center"}><b>{step === 0 ? "Information de l'église" : "Information de l'administrateur"}</b></Typography>
          <Box display={'flex'} justifyContent={"center"} m={2}>
            <ToggleButtonGroup
              color="primary"
              value={`${step}`}
              exclusive
              onChange={(e, value) => setStep(+value)}
              aria-label="Platform"

            >
              <ToggleButton value="0">Eglise</ToggleButton>
              <ToggleButton value="1">Administrateur</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Grid container>
            {step === 0 ?
              <Grid item container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="nom"
                    name="nom"
                    label="Nom de l'église"
                    variant="outlined"
                    value={nomEglise}
                    onChange={(e) => setNomEglise(e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="sigle"
                    name="sigle"
                    label="Sigle de l'église"
                    variant="outlined"
                    value={sigle}
                    onChange={(e) => setSigle(e.target.value)}
                  />
                </Grid>
                <Grid item md={6} container>
                  <Box width={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                    <Typography color={colors.griseApp2} textAlign={'center'}>Logo de l'église</Typography>
                    <Box width={'100%'} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                      <img src={image} alt="churche Image" width={100} height={100} style={{ borderRadius: 25 }} />
                      <IconButton style={{ color: colors.white, background: photo !== "" ? colors.blueApp : colors.griseApp2, marginRight: 15 }} aria-label="upload picture" component="label">
                        <input hidden accept="image/*" type="file" onChange={(e) => handleChange(e)} />
                        <InstallDesktopIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6} container>
                  <Box width={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                    <Typography color={colors.griseApp2} textAlign={'center'}>Photo de couverture de l'église</Typography>
                    <Box width={'100%'} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                      <img src={couverture} alt="churche Image" width={100} height={100} style={{ borderRadius: 25 }} />
                      <IconButton style={{ color: colors.white, background: photoCouverture !== "" ? colors.blueApp : colors.griseApp2, marginRight: 15 }} aria-label="upload picture" component="label">
                        <input hidden accept="image/*" type="file" onChange={(e) => handleChange(e, "couverture")} />
                        <InstallDesktopIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="adresse"
                    name="adresse"
                    label="Adresse de l'église"
                    variant="outlined"
                    value={adresse}
                    onChange={(e) => setAdresse(e.target.value)}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="ville"
                    name="ville"
                    label="Ville de l'église"
                    variant="outlined"
                    value={ville}
                    onChange={(e) => setVille(e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="pays"
                    name="pays"
                    label="pays de l'église"
                    variant="outlined"
                    value={pays}
                    onChange={(e) => setPays(e.target.value)}
                  />
                </Grid>
              </Grid>
              : <Grid item container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="nom"
                    name="nom"
                    label="Nom"
                    variant="outlined"
                    value={nomAdmin}
                    onChange={(e) => setNomAdmin(e.target.value)}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="prenom"
                    name="prenom"
                    label="Prénom"
                    variant="outlined"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="email"
                    id="email"
                    name="email"
                    label="email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="tel"
                    id="tel"
                    name="tel"
                    label="Téléphone"
                    variant="outlined"
                    value={telephone}
                    onChange={(e) => setTel(e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="villeAdmin"
                    name="villeAdmin"
                    label="Ville"
                    variant="outlined"
                    value={villeAdmin}
                    onChange={(e) => setVilleAdmin(e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="paysAdmin"
                    name="paysAdmin"
                    label="Pays"
                    variant="outlined"
                    value={paysAdmin}
                    onChange={(e) => setPaysAdmin(e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    id="adresseAdmin"
                    name="adresseAdmin"
                    label="Adresse"
                    variant="outlined"
                    value={adresseAdmin}
                    onChange={(e) => setAdresseAdmin(e.target.value)}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="pwsd"
                    name="pwsd"
                    label="Mot de passe"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPwd(e.target.value)}
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    fullWidth
                    type={showConfPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="confpwsd"
                    name="confpwsd"
                    label="Confirmation du Mot de passe"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
            }
          </Grid>
          <Grid item md={12} sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Btn action={() => { handleSubmit() }} margin={"2px"} color={colors.blueApp}>{step === 0 ? "Modifier l'église" : "Modifier l'Administrateur"}</Btn>
          </Grid>
          <Loading open={loading} />
        </DialogContent>
      </BootstrapDialog>
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
        handleClose={handleCloseStack}
      />
    </div>
  );
}

export function CreateAdminModal(props: Props) {
  const { open, handleClose, handlCallbackReload, data } = props;

  const [nomAdmin, setNomAdmin] = useState<string>("");
  const [prenom, setPrenom] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telephone, setTel] = useState<string>("");
  const [password, setPwd] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleSubmit = async () => {
    if (nomAdmin !== "" && prenom !== "" && email !== "" && telephone !== "" && password !== "") {
      if (password === confirmPassword) {
        setLoading(true)
        const create = await createAdmin({
          nom: nomAdmin,
          prenom,
          telephone,
          email,
          password,
          privilege: PrivilegesEnum.ADMIN,
        });
        setLoading(false);

        if (create?.status === 200 && create?.statusText === "OK") {
          if (handlCallbackReload) {
            handleClose()
            handlCallbackReload()
          }
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'success',
            message: `L'église vient être enregistre correctement!`
          });
        } else {
          handleOpenStack({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            type: 'warning',
            message: `Une erreur est survenue lors la lieson avec l'eglise mais l'église a étè créee! `
          });
        }
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: 'Le deux mot de passe doivent ếtre indetique!'
        })
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: 'Les champs sont obligatoires.'
      });
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Ajouter une Administrateeur
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom textAlign={"center"}><b>Information de l'administrateur</b></Typography>
          <Grid container>
            <Grid item container spacing={2}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="nom"
                  name="nom"
                  label="Nom"
                  variant="outlined"
                  value={nomAdmin}
                  onChange={(e) => setNomAdmin(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="prenom"
                  name="prenom"
                  label="Prénom"
                  variant="outlined"
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="email"
                  id="email"
                  name="email"
                  label="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="tel"
                  id="tel"
                  name="tel"
                  label="Téléphone"
                  variant="outlined"
                  value={telephone}
                  onChange={(e) => setTel(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="password"
                  id="pwsd"
                  name="pwsd"
                  label="Mot de passe"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPwd(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="password"
                  id="confpwsd"
                  name="confpwsd"
                  label="Confirmation du Mot de passe"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Btn action={() => { handleSubmit() }} margin={"2px"} color={colors.blueApp}>Enregistrer</Btn>
          </Grid>
          <Loading open={loading} />
        </DialogContent>
      </BootstrapDialog>

      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );


}

export function UpdateAdminModal(props: Props) {
  const { open, handleClose, handlCallbackReload, data } = props;

  const { idAdmin, nomAdmin, setNomAdmin } = data
  const { prenom, setPrenom } = data
  const { email, setEmail } = data
  const { telephone, setTel } = data
  const { password, setPwd } = data
  const { confirmPassword, setConfirmPassword } = data;

  const [loading, setLoading] = React.useState<boolean>(false);

  const [snackState, setSnackState] = React.useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const navigation = useNavigate();
  const auth = useAppSelector(selectAuth);

  const handleOpenStack = (newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  };

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const create = await updateAdmin({
      nom: nomAdmin,
      prenom,
      telephone,
      email,
      password,
      privilege: PrivilegesEnum.ADMIN
    }, idAdmin);
    setLoading(false);
    if (create?.status === 200 && create?.statusText === "OK") {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'success',
        message: `L'administrateur vient être modifier correctement!`
      });
      if (handlCallbackReload) {
        handleClose()
        handlCallbackReload()
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'warning',
        message: `Une erreur est survenue lors de la modification de l'administrateur!`
      });
    }
  }


  useEffect(() => {
    let isMount = true
    if (isMount) {
      if (!auth.isAuthenticated) navigation('/AuthConnexionView')
    }
    return () => {
      isMount = false;
    }
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Modifier
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom textAlign={"center"}><b>Information de l'administrateur</b></Typography>
          <Grid container>
            <Grid item container spacing={2}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="nom"
                  name="nom"
                  label="Nom"
                  variant="outlined"
                  value={nomAdmin}
                  onChange={(e) => setNomAdmin(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="prenom"
                  name="prenom"
                  label="Prénom"
                  variant="outlined"
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="email"
                  id="email"
                  name="email"
                  label="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="tel"
                  id="tel"
                  name="tel"
                  label="Téléphone"
                  variant="outlined"
                  value={telephone}
                  onChange={(e) => setTel(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="password"
                  id="pwsd"
                  name="pwsd"
                  label="Mot de passe"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPwd(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="password"
                  id="confpwsd"
                  name="confpwsd"
                  label="Confirmation du Mot de passe"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Btn action={() => { handleSubmit() }} margin={"2px"} color={colors.blueApp}>Modifier l'Administrateur</Btn>
          </Grid>
          <Loading open={loading} />
        </DialogContent>
      </BootstrapDialog>
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
    </div>
  );
}

export function VideoPlayerModal(props: Props) {
  const { open, handleClose, handlCallbackReload, data } = props;

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {data?.currentVideos?.titre}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid item container spacing={2}>
            <Grid item md={12}>
              <VideoPlayer allVideos={data?.videos} videos={data?.currentVideos?.id} />
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );

}