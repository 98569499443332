import React, { useCallback, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import Layout from "../../components/layout";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-classic-plus";
import { url } from "../../services/api";
import { Btn } from "../../components/button";
import { colors } from "../../config";
import SnackbarAlert, { PropsSnack } from "../../components/snackbarAlert";
import Loading from "../../components/loading";
import AlertDialogSlide from "../../components/AlertDialogSlide";
import { SendMail } from "../../services/api/adminApi";

const AdminEmailView = () => {
  const [article, setArticle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [snackState, setSnackState] = useState<PropsSnack>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    type: 'success',
    message: ''
  });
  const [alertState, setAlertState] = useState({ open: false, msg: <></> });
  const [to, setTo] = useState<string>("");
  const [subject, setSubject] = useState<string>("")

  const handleOpenStack = useCallback((newState: PropsSnack) => {
    setSnackState(newState);
    setTimeout(() => {
      handleCloseStack(newState);
    }, 3000);
  }, []);

  const handleCloseStack = (newState: PropsSnack) => {
    setSnackState({ type: newState.type, open: false, message: newState.message, vertical: "top", horizontal: "center" });
  };

  const handleOpenAlert = () => {
    setAlertState({
      open: true,
      msg: <Typography variant='h5' textAlign={'center'}>Voulez-vous vraiment envoiyer ce mail?</Typography>
    });

  };

  const handleCloseAlert = () => {
    setAlertState({
      open: false,
      msg: <></>
    });
  };

  const handleConfirmAction = () => {
    handleCloseAlert()
    handleSendEmail()
  }

  const handleSendEmail = async () => {
    if (to !== "" && subject !== "" && article !== "") {
      setLoading(true);
      const sendEmail = await SendMail({
        to, subject, body: article
      });
      setLoading(false);
      if (sendEmail?.status === 201) {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'success',
          message: 'Le mail a étè envoyer correctement'
        });
      } else {
        handleOpenStack({
          open: true,
          vertical: 'top',
          horizontal: 'center',
          type: 'error',
          message: `Une erreur est survenue lors de l'envoie du mail`
        });
      }
    } else {
      handleOpenStack({
        open: true,
        vertical: 'top',
        horizontal: 'center',
        type: 'error',
        message: `Tout Les champs sont obligatoir!`
      });
    }
  }

  return <Layout>
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Typography color={colors.blueApp} variant={"h2"}>Envoyer de Email</Typography>
        <Grid item md={6}>
          <TextField
            fullWidth
            id="to"
            name="to"
            variant="outlined"
            label="Destinateur"
            value={to}
            onChange={(e) => { setTo(e.target.value) }}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            id="subject"
            name="subject"
            variant="outlined"
            label="Sujet"
            value={subject}
            onChange={(e) => { setSubject(e.target.value) }}
          />
        </Grid>
      </Grid>
      <Grid item md={12}>
        <CKEditor
          editor={ClassicEditor}
          data={article}
          onReady={editor => {
            console.log('Editor1 is ready to use!', editor);
          }}
          config={{
            simpleUpload: {
              uploadUrl: `${url}mail/adapteur`,
            }
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setArticle(data);
            console.log(data);
            
          }}
        />
      </Grid>
      <Grid item md={12}>
        <Btn action={() => { handleOpenAlert() }} color={colors.blueApp}>Envoye l'email</Btn>
      </Grid>
      <Loading open={loading} />
      <SnackbarAlert
        open={snackState.open}
        vertical={snackState.vertical}
        horizontal={snackState.horizontal}
        type={snackState.type}
        message={snackState.message}
      />
      <AlertDialogSlide
        open={alertState.open}
        Message={() => alertState.msg}
        handleClose={handleCloseAlert}
        handleConfirmAction={() => handleConfirmAction()}
      />
    </Grid>
  </Layout>
}


export default AdminEmailView;